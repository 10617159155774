.policy-container {
  margin-top: 110px;
  display: flex;
  gap: 40px;
}
.policy-sidebar {
  margin-top: 50px;
  padding: 15px 0px;
  min-width: 250px;
  height: fit-content;
  background-color: #f6f6f6;
  border-radius: 5px;
}
.policy-sidebar-heading {
  font-size: 16px;
  font-weight: 600;
  padding: 10px 25px;
  border-left: 3px solid transparent;
}
.policy-sidebar-list-item {
  padding: 7px 25px;
  font-weight: 400;
  color: #6b6b6b;
  cursor: pointer;
  border-left: 3px solid transparent;
}
.policy-sidebar-list-item:hover {
  background-color: #f0f0f0;
}
.active-policy {
  border-left: 3px solid #ea5e3d;
  background-color: #eaeaea;
}
.policy-content {
  width: 100%;
  padding: 42px 0px;
}
.policy-content-heading {
  font-size: 28px;
  margin-bottom: 35px;
  transition: top 0.3s ease-in-out;
}
.policy-content h2 {
  font-size: 22px;
  margin-bottom: 35px;
}
.policy-content p {
  color: #6b6b6b;
  margin: 25px 0;
  font-size: 18px;
  font-weight: 500;
}
.policy-content p span {
  margin-top: 5px;
  display: block;
  font-size: 16px;
  font-weight: 400;
}
.policy-content ul {
  margin: 15px 0;
  list-style: inside;
}
.policy-content ol {
  list-style-type: decimal;
}
.policy-content li {
  padding: 3px 0;
  color: #6b6b6b;
}
.policy-content a {
  text-decoration: none;
}
.policy-content a:hover {
  text-decoration: underline;
}
.policy-container-new {
  margin-top: 110px;
  display: flex;
  gap: 40px;
}
.policy-content-new {
  width: 100%;
  padding: 42px 0px;
}
.policy-content-new p {
  margin-top: 0;
  margin-bottom: 0rem;
}
@media only screen and (max-width: 1024px) {
  .policy-sidebar {
    min-width: 200px;
  }
  .policy-container {
    gap: 30px;
  }
}
@media only screen and (max-width: 768px) {
  .policy-container {
    gap: 20px;
  }
  .policy-sidebar {
    margin-top: 40px;
  }
  .policy-content-heading {
    font-size: 20px;
    margin-bottom: 30px;
  }
  .policy-content {
    padding: 25px 0px;
  }
}
@media only screen and (max-width: 600px) {
  .policy-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  .policy-sidebar {
    margin-top: 5px;
  }
  .policy-content {
    padding: 0px;
  }
  .sticky-heading {
    top: 90px;
    padding: 10px 0px;
    position: sticky !important;
    background-color: white;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
  }
}
