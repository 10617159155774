.cart-sidebar-container {
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 5;
  left: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
}
.cart-sidebar-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 300px;
  height: 100%;
  background-color: white;
  transition: transform 0.1s ease-in-out 0s;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 3px 6px;
}
.sidebar-out-of-stock {
  margin: 0;
  padding: 5px 10px;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  color: #f4aba4;
}
.cart-sidebar-header {
  width: 100%;
  position: absolute;
  top: 0;
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  cursor: default;
  padding: 10px;
  background-color: white;
  box-shadow: rgb(214, 214, 214) 0px 3px 6px;
  z-index: 2;
}
.cart-sidebar-body {
  padding: 55px 0px 175px 0px;
  height: 100%;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  background-color: white;
}
.cart-sidebar-body::-webkit-scrollbar {
  width: 3px;
}
.cart-sidebar-body::-webkit-scrollbar-thumb {
  background-color: #585858;
}
.cart-sidebar-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.12) 0px -3px 6px;
}
.sidebar-loading {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  color: #ea5e3d;
  background-color: #ffffff;
  padding: 10px;
}
.sidebar-heading {
  font-size: 18px;
  color: #ea5e3d;
  font-weight: 500;
}
.sidebar-close-icon {
  padding: 5px 10px;
  color: #adadad;
}
.sidebar-close-icon:hover {
  cursor: pointer;
  color: #8d8d8d;
}
.cart-sidebar-summary-heading {
  margin: 0;
  padding: 10px;
  font-size: 14px;
  color: #666666;
  font-weight: 500;
}
.cart-sidebar-product {
  padding: 5px 10px;
  display: flex;
  gap: 10px;
}
.cart-sidebar-product:last-child {
  margin-bottom: 0;
}
.cart-sidebar-product:hover {
  cursor: pointer;
  background-color: #f2f2f2;
}
.cart-sidebar-product-divider {
  margin: 5px 0px 5px 0;
}
.cart-sidebar-product-divider:last-child {
  display: none;
}
.cart-sidebar-product-details {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: space-between;
}
.cart-sidebar-image {
  height: 85px;
  width: 70px;
  border-radius: 5px;
}
.cart-sidebar-product-name {
  margin: 0;
  width: 200px;
  font-size: 14px;
  color: #676767;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.sidebar-price-line {
  display: flex;
  align-items: center;
  gap: 10px;
}
.sidebar-product-price {
  font-size: 12px;
  color: #afafaf;
  margin: 0;
}
.sidebar-product-price span {
  font-size: 16px;
  font-weight: 500;
  color: #202020;
}
.sidebar-product-discount {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  color: #379d75;
}
.sidebar-product-mrp {
  margin: 0;
  font-size: 12px;
  color: #868686;
}
.sidebar-product-mrp span {
  text-decoration: line-through;
  text-decoration-color: #686666;
}
.sidebar-total {
  padding: 10px;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  color: #666666;
}
.sidebar-total p {
  margin: 0;
}
.sidebar-total-amount {
  font-size: 18px;
}
.cart-sidebar-buttons {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}
.sidebar-cart-btn {
  width: 100%;
  height: 45px;
  color: #ea5e3d;
  font-size: 18px;
  font-weight: 500;
  border-radius: 2px;
  background-color: #ffffff;
  border: 2px solid #ea5e3d;
  transition: background-color 0.3s, transform 0.1s;
}
.sidebar-cart-btn:hover {
  transform: scale(1.05);
  background-color: #ea5e3d;
  color: white;
}
.sidebar-checkout-btn {
  width: 100%;
  height: 45px;
  background-color: #ea5e3d;
  color: white;
  border: none;
  border-radius: 2px;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.1s;
}
.sidebar-checkout-btn:hover {
  background-color: #ea5e3d;
  transform: scale(1.05);
}
