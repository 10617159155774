.verified-ribbon {
  font-size: 16px;
  position: absolute;
  color: white;
  background-color: #ea5e3d;
  z-index: 1;
  --f: 8px;
  --r: 15px;
  --t: 10px;
  inset: var(--t) calc(-1 * var(--f)) auto auto;
  padding: 6px 15px 15px calc(15px + var(--r));
  clip-path: polygon(
    0 0,
    100% 0,
    100% calc(100% - var(--f)),
    calc(100% - var(--f)) 100%,
    calc(100% - var(--f)) calc(100% - var(--f)),
    0 calc(100% - var(--f)),
    var(--r) calc(50% - var(--f) / 2)
  );
  box-shadow: 0 calc(-1 * var(--f)) 0 inset #0005;
}
.imagesSliderImageContainer{
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  position: relative;
  height: 350px;
  display: flex;
  justify-content: center;
  padding: 10px;
}
.shareIconForUserContainer {
  position: relative;
  top: 0;
  right: 100px;
}
.shareIconDiv {
  padding-bottom: 5px;
}
.playVedioImageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 50%;
}
.visitStoreBtn {
  position: absolute;
  bottom: 30px;
  display: flex;
  justify-content: center;
  width: 100%;
}
.productDetailsSellerDetails_ModalContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.08);
  z-index: 999;
}
.productDetailsSellerDetails_ModalContent {
  position: relative;
  display: flex;
  width: 450px;
  height: 450px;

  border-radius: 10px;
  background: #fff;
}
.close_productDetailsSellerDetails {
  display: flex;
  justify-content: end;
  width: 100%;
}

.sellerDetails_Container {
  margin: 20px 0px;
}
.generalSellerDetails p {
  margin: 0;
  cursor: pointer;
  font-size: 14px;
  width: fit-content;
}
.productDetailsSellerDetails {
  width: 100% !important;
  /* border: 1px solid; */
  gap: 10px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
}
.productDetailsSellerDetails p {
  margin: 0;
  font-weight: 500 !important;
  font-size: 16px;
  line-height: 16px;
}
.productDetailsSellerShopName {
  font-weight: 600 !important;
  color: #ea5e3d;
  font-size: 20px;
}
.PlayVedioSpan {
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
}
.skeleton-loading-forProductDetails {
  display: flex;
  flex-direction: row;
  width: 62%;
  margin: auto;
  height: 100%;
  margin-left: 22%;
}
.skeleton-loading-forMobileImage {
  display: none;
}
.skeleton-loading-forProductDetailsOfImage {
  display: flex;
  justify-content: space-between;
  height: 100%;
  width: 55%;
  gap: 10px;
}
.skeleton-loading-forSmallImages {
  display: flex;
  flex-direction: column;
  align-items: end;

  height: 100%;
  width: 20%;
}
.skeleton-loading-forLargeImages {
  height: 566px !important;
  width: 80%;
  background-color: #f5f5f5;
}
.skeleton-loading-forSmallImages1 {
  height: 78px;
  width: 75%;
  background-color: #f5f5f5f5;
}
.skeleton-loading-forProductDetailsOfDetails {
  width: 45%;
  /* padding-left: 30px; */
}
.skeleton-loading-forProductDetailsOfDetails1 {
  width: 99%;
  height: 17px;
  background-color: #f5f5f5;
  margin-top: 17px;
}
.skeleton-loading-forProductDetailsOfDetails2 {
  width: 75%;
  height: 20px;
  background-color: #f5f5f5;
  margin-top: 17px;
}
.skeleton-loading-forProductDetailsOfDetails3 {
  width: 30%;
  height: 18px;
  background-color: #f5f5f5;
  margin-top: 4px;
}
.skeleton-loading-forProductDetailsOfDetails4 {
  width: 30%;
  height: 28px;
  background-color: #f5f5f5;
  margin-top: 32px;
}
.skeleton-loading-forProductDetailsOfDetails5 {
  width: 38%;
  height: 16px;
  background-color: #f5f5f5;
  margin-top: 4px;
}
.skeleton-loading-forProductDetailsOfDetails6 {
  width: 58%;
  height: 16px;
  background-color: #f5f5f5;
  margin-top: 32px;
}
.skeleton-loading-forProductDetailsOfDetails7 {
  display: flex;
  flex-direction: row;
  width: 80%;
  gap: 20px;
  margin-top: 60px;
}
.skeleton-loading-Button1 {
  height: 45px;
  width: 100%;
  background-color: #f5f5f5;
}
.skeleton-loading-Button2 {
  height: 45px;
  width: 100%;
  background-color: #f5f5f5;
}
.skeleton-loading-forProductDetailsOfDetails8 {
  height: 25px;
  width: 50%;
  margin-top: 65px;
  background-color: #f5f5f5;
}
.skeleton-prdouctpage22 {
  width: 80%;
  margin: auto;
  margin-top: 65px;
}
.onlyVisible-inMobileView-div {
  display: none;
}
.bg-white {
  background-color: white;
}
.disabledBtn {
  opacity: 0.5;
  cursor: not-allowed !important;
}
.shareIconContainer {
  height: 20px;
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
}
.productContainer {
  display: flex;
  margin: auto;
  padding-top: 10px;
  height: 100%;
  /* width: 65%; */
  gap: 2%;
  justify-content: center;
}
.currentPriceSpanTag {
  font-weight: 600;
  font-size: 14px;
  color: #69b597;
  text-decoration: none;
  margin-left: 10px;
  margin-top: 5px;
}
.react-player {
  width: 100%;
  height: auto;
  position: relative;
}
.product-slug-url {
  align-items: center;
  font-size: 14px;
  color: #989898;
  margin-bottom: 10px;
}
.product-slug-url span {
  text-decoration: none;
}
.product-slug-url span:hover {
  cursor: pointer;
  text-decoration: underline;
}
.productDetails {
  width: 50%;
}
.ProductDetailsInnerContainer {
  /* margin-left: 50px; */
}
.productImgContainer {
  display: flex;
  position: relative;
  width: 50%;
  height: max-content;
  padding: 20px 0;
  justify-content: flex-end;
  position: sticky !important;
  top: 110px;
  gap: 10px;
}
.prodImageVariantHolder{
  width: 100%;
}
.buyerProductDetails-imgDiv {
  width: 80%;
  height: 100%;
  position: relative;
}
.buyerProductDetails-imgDiv img {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}
.buyerProductDetails-imgList {
  width: 20%;
  height: 100%;
  gap: 17px;
  display: flex;
  flex-direction: column;
}
.joyrejoyWishlistIcon {
  z-index: 1;
  position: absolute;
  top: 10px;
  height: auto;
  left: 85%;
  width: max-content;
  cursor: pointer;
}
.buyerProductDetails-imgListOptions {
  width: 100%;
  height: 80px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  /* margin: 5px 0px; */
  display: flex;
  justify-content: end;
}
.buyerProductDetails-imgListOptions div {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.upsideDownArrowButton {
  margin-left: 84%;
  cursor: pointer;
  margin-top: -59px;
  z-index: 1;
  width: 70px;
  display: none;
}

.buyerProductDetails-imgListOptions img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  object-fit: contain;
}
#taxIncludedMsg {
  font-weight: 400;
  font-size: 12px;
  /* margin-top: -25px; */
  list-style: none;
  color: #868686;
}
.OriginalPriceSpanTag {
  text-decoration: line-through;
}
.productDetailsPriceName {
  display: flex;
  align-items: center;
}
.highlightedImage {
  border: 2px solid #ea5e3d;
  cursor: pointer;
}
.productDetailsPageImgContainer {
  height: 566px !important;
}
.productDetailsPageImg {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.ProductBrandName {
  font-size: 15px;
  margin-top: -1.2%;
  color: #959595;
  font-weight: 500;
}
.magnito {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  padding: 10px;
}
.magnito img[src$=".jpg"],
.magnito img[src$=".png"],
.magnito img[src$=".jpeg"] {
  height: 570px !important;
  object-fit: contain;
}
.shareIconForUserContainer {
  width: 0;
}
.reactVideoPlayer {
  width: 100% !important;
  height: 100% !important;
}
.OptionsForShare {
  cursor: pointer;
}
.shareIconForUser {
  width: max-content;
  height: max-content;
  padding: 5px;
  z-index: 2;
  position: relative;
  right: 50px;
  top: 25px;
  display: flex;
  background-color: #ffffff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border: 1px solid #bbbfbf;
  border-radius: 5px;
}
#SocialMediaButtonDiv {
  display: flex;
  flex-direction: row;
}
.shareIconText {
  font-size: 11px;
  padding: 5px;
  font-weight: 650;
}
.shareIconTextForTwitter {
  font-size: 11px;
  padding: 5px;
  margin-top: -16px;
  font-weight: 650;
}
.shareIconTextForextra {
  font-size: 11px;
  padding: 5px;
  margin-top: -14px;
  font-weight: 650;
}
.shareIconTextForInsta {
  font-size: 11px;
  padding: 5px;
  margin-top: -12px;
  font-weight: 650;
}
.shareIconTextForLink {
  font-size: 11px;
  padding: 5px;
  margin-top: -15px;
  font-weight: 650;
}
.IconButtonInlineWithText {
  display: flex;
}
.hrLine {
  width: 90px;
  margin-top: -16px;
}
.hrLineForWhatsapp {
  width: 90px;
  margin-top: -13px;
}
.hrLineForemail {
  width: 90px;
  margin-top: -15px;
}
.facebookIcon {
  color: #1877f2;
  height: 18px;
  padding: 5px;
}
.twitterIcon {
  color: black;
  height: 18px;
  padding: 5px;
}
.IconContainerButton {
  display: flex;
  border: none;
  background-color: #ffffff;
}

.whatsappIcon {
  color: #25d366;
  height: 20px;
  padding: 5px;
}
.instagramIcon {
  color: #c32aa3;
  height: 20px;
  padding: 5px;
}
.GmailIcon {
  color: #d14836;
  height: 19px;
  padding: 5px;
}
.copyIcon {
  color: #202020;
  padding: 5px;
}
.insideLinkButton {
  background-color: none;
  border: none;
}
.productNameHeading {
  color: #000000;
  font-size: 20px;
  font-weight: 500;
}
.sellingPrice {
  margin: 0;
  font-size: 28px;
  font-weight: 500;
  color: #000000;
}
#sellingPriceHeading3 {
  color: #202020;
  font-size: 14px;
  font-weight: 600;
}
.ageGroupDiv {
  margin-top: 4px;
}
.ageText {
  font-size: 13px;
  color: #585858;
}
.shippingParaHeadingTag {
  margin-top: -30px;
}
.ProductNameDescription01 {
  font-size: 14px;
  width: 450px;
  margin-top: 10px;
}
.ProductDescriptionHeading {
  font-size: 21px;
  font-weight: 500;
  margin-bottom: 1px;
}
.ButtonDivProductDetails {
  display: flex;
  justify-content: space-around;
  width: 100%;
  gap: 20px;
  margin-top: 20px;
}
.wislistButtonSpanTag {
  width: 100%;
}
.buyNowButtonSpanTag {
  width: 100%;
  display: flex;
  justify-content: end;
}
.addToWishListButton {
  width: 90%;
  background-color: #ffffff;
  color: #ea5e3d;
  border: 2px solid #ea5e3d;
  text-decoration-color: #ea5e3d;
  font-size: 18px;
  height: 45px;
  font-weight: 500;
  border-radius: 2px;
  transition: background-color 0.3s, transform 0.1s;
}
.addToWishListButton:hover {
  transform: scale(1.05);
  background-color: #ea5e3d;
  color: white;
}
.addToCartButton {
  background-color: #ea5e3d;
  color: white;
  width: 90%;
  font-size: 18px;
  height: 45px;
  font-weight: 500;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.1s;
}
.addToCartButton:hover {
  background-color: #ea5e3d;
  transform: scale(1.05);
}
.joyrejoyLogoDiv {
  display: flex;
  height: 75px;
  margin-top: 11px;
  gap: 20px;
}
.approveLogoImage {
  height: 35px;
  width: 35px;
}
.joyrejoyLogoParagraph {
  font-size: 11px;
  font-weight: 650;
  color: #979797;
  margin: 0;
}
.productDescriptionDiv {
  margin-top: 20px;
}
.playVideoButton {
  padding: 10px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: none;
  border-radius: 5px;
  font-size: 1.7vh;
  height: 50px;
  font-weight: 600;
}
.product-price {
  margin-top: -5px;
  color: #000000;
  font-weight: 500;
}
.product-name {
  font-size: 14px;
  font-weight: 500;
  margin-top: -11px;
}
.mobileViewImages {
  display: none;
}
.currentPriceForSimilarProducts {
  display: none;
}
.prodImagesHolder {
  display: flex;
  gap: 15px;
}

/* prod variants css */

#selectedVariant {
  border: 2px solid #ea5e3d;
  font-weight: 500;
}
.prodVariantsList {
  /* border: 1px solid red; */
  height: max-content;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 8px 0px;
}

.variantTitle {
  margin: 0;
  font-weight: 500;
  font-size: 16px;
}
.variantValuesContainer {
  display: flex;
  gap: 16px;
  padding: 0px 20px;
  margin: 10px;
}
.variantValueTxt {
  margin: 0;
  font-size: 12px;
  border-radius: 8px;
  text-align: center;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.variantValueImgContainer {
  /* border: 1px solid;
  height: 50px; */
}

.variantValueImg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.variantValueImgTxtContainer {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
  height: fit-content;
  border-radius: 8px;
  width: max-content;
  padding: 5px 10px;
}

/*  */
/* MEDIA QUERIES FOR SMALL SCREEN (TAB VIEW) */
@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .productImgContainer {
    height: 100%;
    padding: 11px 0;
    gap: 18px;
  }
  .buyerProductDetails-imgDiv {
    width: 88%;
    height: 100%;
  }
  .magnito img[src$=".jpg"],
  .magnito img[src$=".png"],
  .magnito img[src$=".jpeg"] {
    height: 550px !important;
  }
  .ProductNameDescription01 {
    width: 350px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .productContainer {
    /* width: 80%; */
  }
  .productImgContainer {
    height: 100%;
    padding: 11px 0;
    gap: 18px;
  }
  .buyerProductDetails-imgDiv {
    width: 88%;
    height: 100%;
  }
  .joyrejoyWishlistIcon {
    left: 87%;
  }
  .productDetails {
    width: 50%;
  }
  .magnito img[src$=".jpg"],
  .magnito img[src$=".png"],
  .magnito img[src$=".jpeg"] {
    height: 550px !important;
  }
  .addToWishListButton {
    font-size: 15px;
    font-weight: 500;
  }
  .addToCartButton {
    font-size: 15px;
    font-weight: 500;
  }
  .ProductNameDescription01 {
    width: 280px;
  }
  .joyrejoyLogoDiv {
    gap: 10px;
  }
}
@media only screen and (min-width: 1000px) and (max-width: 1300px) {
  .productContainer {
    /* width: 85%; */
  }
  .buyerProductDetails-imgList {
    width: 20%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1000px) {
  .skeleton-loading-forProductDetailsOfImage {
    width: 100%;
  }

  .skeleton-loading-forProductDetailsOfDetails {
    width: 90%;
    margin: auto;
  }
  .skeleton-loading-forProductDetailsOfDetails6,
  .skeleton-loading-forProductDetailsOfDetails7 {
    margin-top: 20px;
  }

  .buyerProductDetails-imgList {
    width: 10%;
  }
  .buyerProductDetails-imgDiv {
    width: 90%;
  }
  .ProductDetailsInnerContainer {
    margin: 0px;
  }
  .productContainer {
    flex-direction: column;
    /* width: 80%; */
  }
  .productDetailsPageImgContainer {
    height: 500px !important;
    object-fit: contain;
  }
  .buyerProductDetails-imgListOptions {
    height: 70px;
  }
  .productImgContainer {
    justify-content: flex-start;
    position: relative !important;
    padding: 10px 0px;
    top: 0;
    width: 100%;
  }
  .productDetails {
    width: 100%;
  }
}
@media only screen and (max-width: 600px) {
  .playVedioImageContainer {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .reactVideoPlayer {
    height: 350px !important;
  }
  .imageSliderContainer {
    position: relative;
    height: 350px;
  }
  .product-slug-url {
    font-size: 12px;
    line-height: 14px;
  }
  .shareIconForUserForMobileContainer {
    width: 90%;
    display: flex;
    justify-content: end;
    position: absolute;
    top: 35px;
    right: 15px;
  }
  .skeleton-prdouctpage22 {
    display: none;
  }
  .onlyVisible-inMobileView-div {
    display: grid;
    grid-template-columns: (2, 1fr);
    grid-template-rows: auto;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 50px;
  }
  .skeleton-loading-forProductDetails {
    flex-direction: column;
    width: 100%;
    margin: 0;
  }
  .skeleton-loading-forProductDetailsOfDetails {
    width: 100%;
    padding-left: 10px;
  }
  .skeleton-loading-forProductDetailsOfImage {
    display: none;
  }
  .skeleton-loading-forMobileImage {
    display: block;
    height: 350px;
    width: 70%;
    background-color: #f5f5f5;
    margin: auto;
    margin-top: 15px;
  }
  .productContainer {
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100%;
  }
  .productImgContainer {
    display: none;
  }
  .mobileViewImages {
    display: inline-block;
    position: relative;
    padding: 10px;
  }
  .imageSliderWithTopMargin {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .imageSliderWithoutTopMargin {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .WishlistAndShareIconDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    top: 30px;
    width: 95%;
    padding: 0px 10px;
  }
  .joyrejoyWishlistIcon {
    align-items: center;
    left: 0;
    top: 0;
    height: auto;
    display: flex;
    justify-content: center;
    width: 40px;
    height: 100%;
    background-color: #ffffff;
    border-radius: 50%;
  }
  .shareIconDiv {
    display: none;
  }
  .shareIconDivMobile {
    align-items: center;
    position: absolute;
    right: 0;
    z-index: 1;
    top: -20px;
    height: 40px;
    display: flex;
    justify-content: center;
    width: 40px;
    background-color: #ffffff;
    border-radius: 50%;
  }
  .OptionsForShare {
    margin-left: 0%;
  }
  .dotContainer {
    display: flex;
    justify-content: center;
    margin-top: 11px;
  }
  .dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #ffffff;
    margin: 0 5px;
    cursor: pointer;
  }
  .activeDot {
    background-color: #ea5e3d;
  }
  .shareIconForUserForMobile {
    width: max-content;
    margin-left: 64%;
    height: max-content;
    z-index: 2;
    position: relative;
    display: flex;
    background-color: #ffffff;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border: 1px solid #bbbfbf;
    border-radius: 5px;
  }
  .shareIconForUser {
    display: none;
  }
  .productDetails {
    width: 100%;
    margin: auto;
  }
  .ProductDetailsInnerContainer {
    width: 100%;
    margin: auto;
    padding: 10px;
  }
  .allProductsDescriptionDiv {
    margin-top: 7%;
  }
  .ProductDescriptionHeading {
    font-size: 18px;
    font-weight: 600;
    color: #202020;
  }
  .ButtonDivProductDetails {
    flex-direction: column;
  }
  .productNameHeading {
    font-size: 18px;
  }
  .ProductBrandName {
    font-size: 16px;
    width: 100%;
    height: 100%;
    font-weight: 600;
  }
  .priceDivForSimilarProducts {
    display: flex;
  }
  .currentPriceSpanTag {
    padding-top: 4px;
  }
  .currentPriceForSimilarProducts {
    font-weight: 600;
    color: #69b597;
    font-size: 11px;
    margin-top: 4px;
    margin-left: 7px;
    display: inline;
  }
  .ageText {
    font-size: 14px;
    font-weight: 500;
  }
  #sellingPriceHeading3 {
    font-size: 14px;
    font-weight: 500;
  }
  .addToWishListButton {
    width: 100%;
    font-size: 20px;
  }
  .addToWishListButton:hover {
    transform: scale(1);
  }
  .addToCartButton {
    width: 100%;
    padding: 12px 20px;
  }
  .addToCartButton:hover {
    background-color: #ff4c29;
    transform: scale(1);
  }
  .joyrejoyLogoDiv {
    width: 100%;
    height: 69px;
    gap: 20px;
  }
  .joyrejoyLogoParagraph {
    font-size: 10px;
    font-weight: 400;
    color: #979797;
  }
  .productDescriptionDiv {
    margin-top: 40px;
  }
  .ProductNameDescription01 {
    font-size: 12px;
    width: 100%;
  }
  .PlayVedioSpan {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 99999;
  }
  #SocialMediaButtonDiv {
    margin-left: 0;
    text-align: center;
  }
  .facebookIcon {
    color: #1877f2;
    height: 20px;
  }
  .twitterIcon {
    color: #1da1f2;
    height: 20px;
  }
  .IconContainerButton {
    border: none;
    background-color: #ffffff;
  }
  .GmailIcon {
    height: 17px;
  }
  .product-name {
    font-size: 12px;
    margin-top: -7px;
  }
  .product-price {
    font-size: 15px;
    margin: 0;
    font-weight: 600;
  }
}
