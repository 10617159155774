.skeletonElement {
  background-color: #f5f5f5;
}
#orderPageOuterDivSkelelton {
  position: absolute;
  right: 20px;
  top: 10px;
  width: 50px;
  height: 20px;
  background-color: #f5f5f5;
}
#orderPageOuterDivSkelelton div {
  border-radius: 2px;
}
#orderSkeletonProductName {
  display: block;
}
.orderSkeletonImageContainer {
  height: 100px;
  width: 80px;
  margin-left: 10px;
  background-color: #f5f5f5;
}
.SkeletonOrderNumberandDeliveryDiv {
  width: 80%;
  height: 80%;
}
.orderSkeletonPrice {
  height: 30px;
  width: 50px;
}
.orderSkeletonBuyerMainDivForOrderSectionPTag {
  width: 80%;
  height: 30px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.skeletonOrderNameAndCategoryDiv {
  padding: 10px;
}
.orderSkeletonOrderStatus {
  display: flex;
  gap: 5px;
  flex-direction: column;
}
.orderSkeletonOrderStatusPrimary {
  height: 15px;
}
.orderSkeletonOrderStatusSecondary {
  height: 15px;
}
.orderSkeletonMakePaymentBtn {
  width: 80px;
  padding: 5px;
}
