.container {
  position: relative;
  margin: 0;
  padding: 0;
  line-height: 11px;
}
.container label {
  display: block;
}

.container .mymainInputTag {
  border: 1px solid #dddddd;
  padding: 8px 8px;
  font-size: 0.75rem;
  width: 100%;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border-radius: 5px !important;
  margin-bottom: 0.5rem;
  height: 2.6rem;
  margin-bottom: 10px;
  min-width: 96%;
}
.container .mymainInputTag:focus {
  color: #495057;
  background-color: #fff;
  border-color: #cdcdcd;
  outline: 0;
}
.container p {
  font-weight: bold;
  font-size: 0.7rem;
  color: gray;
  top: -5px;
  margin: 0;
  padding: 0;
}
.container .mymainInputTag[type="password"] {
  padding-right: 2.5rem;
}
.characterCounter {
  font-size: 14px;
  display: flex;
  justify-content: space-between;
}
.container button {
  position: absolute;
  top: 50%;
  right: 0.5rem;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  margin-top: 3%;
}
.container button svg {
  width: 1.2rem;
  height: 1.2rem;
  /* color: #5ac9cf; */
}
.container button:hover svg {
  /* color: #6bcacf; */
}
.inputboxforerrormessage {
  font-size: 0.75rem;
  width: 100%;
  background-color: transparent;
  font-weight: 550;
  position: relative;
  font-weight: bold;
  font-size: 0.7rem;
  color: #ff3300 !important;
  top: -10px;
  margin: 0;
  padding: 0;
  -webkit-text-fill-color: #ff3300 !important;
  -webkit-fill-color: #ff3300 !important;
  border: none;
}
.lableTag {
  position: relative;
}
.lableTag .SpanlableTag {
  position: absolute;
  left: var(--size-bezel);
  top: var(--size-bezel);
  padding: calc(var(--size-bezel) * 0.75) calc(var(--size-bezel) * 0.5);
  background: var(--color-background);
  white-space: nowrap;
  transform: translate(0, 0);
  transform-origin: 0 0;
  transition: transform 120ms ease-in;
  font-size: 14px;
  color: #595959;
  background-color: white;
}
.lableTag .mymainInputTag {
  box-sizing: border-box;
  border: 1px solid #cdcdcd;
  display: block;
  width: 100%;
  padding: calc(var(--size-bezel) * 1.5) var(--size-bezel);
  color: currentColor;
  background: transparent;
  font-size: 14px;
  border-radius: var(--size-radius);
  &:focus,
  &:not(:placeholder-shown) {
    & + .SpanlableTag {
      transform: translate(0.3rem, -80%) scale(0.84);
      color: #7c7c7c;
      font-weight: 400;
    }
  }
}
input[type="date"],
input[type="time"] {
  -webkit-appearance: none;
}
input::-webkit-date-and-time-value {
  margin-top: 0px;
  text-align: left;
  width: 100%;
}
.InputDataType {
  display: flex;
  justify-content: left;
  align-items: center;
}
@media only screen and (min-width: 601px) and (max-width: 1100px) {
  .container {
    position: relative;
    margin: 0;
    padding: 0;
    line-height: 25px;
  }
  .container label {
    display: block;
    font-size: 18px;
    color: #313131;
    font-weight: 400;
  }
  .container .mymainInputTag {
    padding: 8px 8px;
    font-size: 0.85rem;
    border-radius: 4px;
    pointer-events: visible;
  }
  .inputboxforerrormessage {
    background-color: #fff;
    font-weight: 700;
    font-size: 0.75rem;
    top: -5px;
  }
}
@media screen and (-webkit-min-device-pixel-ratio: 2) {
  .lableTag .mymainInputTag {
    padding: calc(var(--size-bezel) * 1.2) var(--size-bezel);
  }

  .lableTag .SpanlableTag {
    padding: calc(var(--size-bezel) * 0.5) var(--size-bezel);
    font-size: 14px;
  }

  .lableTag .mymainInputTag:focus + .SpanlableTag,
  .lableTag .mymainInputTag:not(:placeholder-shown) + .SpanlableTag {
    transform: translate(0.25rem, -80%) scale(0.83);
    font-size: 14px;
  }
}
