.razpaydiv {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.RazorpayPayButton {
  background-color: #ea5e3d;
  color: #fff;  
  font-size: 15px;
  padding: 10px;
  width: 100%;
  transition: 100ms;
  cursor: pointer;
  font-weight: 450;
  text-transform: capitalize;
}
.RazorpayPayButton:hover {
  color: white;
  background-color: #ea5e3d;
}
@media screen and (max-width: 600px) {
  .RazorpayPayButton {
    outline: none;
    border: none;
    background-color: #ea5e3d;
    color: #fff;
    border-radius: 5px;
    font-size: 15px;
    transition: 100ms;
    cursor: pointer;
    font-weight: 450;
  }
}
