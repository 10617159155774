.contact-us {
  /* margin-top: 150px; */
  min-height: 50vh;
}
.contact-heading {
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 700;
}
.heading-description {
  margin: -10px 0 25px 0;
}
.contact-description {
  font-size: 16px;
  font-weight: 500;
}
.contact-description p {
  margin: 25px 0;
}
.contact-info {
  font-size: 16px;
  font-weight: 400;
  margin: 0px 0px 15px 0px;
}
.contact-info a {
  text-decoration: none;
}
.contact-info a:hover {
  text-decoration: underline;
}
.contact-description span {
  margin-top: 5px;
  display: block;
  font-size: 16px;
  font-weight: 400;
}
@media only screen and (max-width: 600px) {
  .contact-us {
    /* margin-top: 120px; */
  }
  .heading-description {
    margin: 10px 0 25px 0;
  }
  .contact-heading {
    font-size: 20px;
    font-weight: 600;
  }
}
