.three-dot-menu {
  position: relative;
  display: inline-block;
}
.menu-toggle {
  cursor: pointer;
  padding: 5px 15px;
}
.Address_Page_Contact_Person_Name {
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}
.dot {
  display: block;
  width: 3px;
  height: 3px;
  margin: 3px 0;
  background-color: #a0a0a0;
  border-radius: 50%;
  border: 2px solid #a0a0a0;
}
.showisDefaultaddress {
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: center;
  color: #3366cc;
  padding-left: 6px;
}
.options {
  position: absolute;
  top: -10px;
  right: 15px;
  background-color: white;
  border: 1px solid #ccc;
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-align: center;
  box-shadow: 1px 1px 2px 1px #00000033;
  width: 86px;
  height: max-content;
}
.options li {
  padding: 3px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
}
.options li:hover {
  background-color: #f0f0f0;
}
.buyerMainAddressPage {
  font-size: 24px;
  padding: 25px 0px;
  max-width: 1450px;
  /* margin-top: 110px; */
  width: 90%;
}
.address-list {
  /* border-top: 0.5px solid #f0f0f0; */
  padding: 0;
  list-style: none;
}
.addressplussign {
  font-size: 18px;
}
.address {
  border-bottom: 1px solid #ccc;
}
.AddressHeadingtag {
  color: #656565;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  padding: 15px;
  padding-left: 20px;
  margin: 0;
}
.namefiled {
  top: 25px;
  padding: 5px;
  color: #4b4b4b;
  background-color: #d9d9d9;
  border-radius: 3px;
}
.address-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.delete-button {
  margin-left: 10px;
  border: none;
  background-color: #fff;
}
.delete-button-edit {
  border: none;
  background: none;
  font-size: 24px;
  color: #fff;
  border: none;
  cursor: pointer;
}
.add-address-buttonMain {
  width: 145px;
  border-radius: 4px;
  background-color: white;
  color: #379d75;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 15px;
  cursor: pointer;
  border: 1px solid #379d75;
}
.add-address-buttonMaindefault {
  width: 145px;
  border-radius: 4px;
  background-color: white;
  color: #379d75;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 15px;
  cursor: not-allowed !important;
  opacity: 0.5;
  border: 1px solid #379d75;
}
.buttondotsdiv {
  float: right;
}
.address-list::-webkit-scrollbar {
  width: 6px;
  height: 1px;
  color: #4dabb0;
}
.address-list::-webkit-scrollbar-thumb {
  background-color: #4dabb0;
  border-radius: 5px;
}
.address-list::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  color: #4dabb0;
}
.BuyerAddressListContainer p {
  margin: 0;
  font-size: 0.75rem;
}
.buyerMainDivForProfileSection {
  display: block;
}
.buyerMainDivForAddressSection {
  width: 75%;
  border-radius: 5px;
  background-color: white;
}
.HomePtag {
  padding-bottom: 10px;
}
.delete-confirmation-popup {
  position: absolute;
  text-align: center;
  justify-content: center;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 0px 10px;
  width: 350px;
  z-index: 1;
  overflow: hidden;
  padding: 20px;
  padding-top: 10px;
}
.delete-confirmation-popup p {
  margin-top: 25px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #555555;
}
.delete-confirmation-popup .button1 {
  background-color: #ea5e3d !important;
  width: 100px;
  height: 35px;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  margin-left: 20px;
  color: #ffffff !important;
  border: 1px solid #ea5e3d;
}
.delete-confirmation-popup .button2 {
  width: 100px;
  height: 35px;
  border-radius: 2px;
  border: 1px solid #ea5e3d;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  background-color: #ffffff;
  color: #ea5e3d;
}
.delete-confirmation-popup .button3 {
  color: #3366cc;
  border: none;
  width: fit-content;
  border-radius: 4px;
  padding: 2px 10px;
  margin-right: 10px;
  cursor: pointer;
  height: 25px;
  margin-top: 10px;
  font-size: 14px;
  font-weight: 600;
}
.headingmessageDeleteaddress {
  font-size: 16px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #565656;
}
.buttonDiv {
  display: flex;
  margin-top: 50px;
  justify-content: end;
}
.buyerMainDivForNavProfileInfoSection {
  display: flex;
  gap: 1%;
  font-size: 24px;
}
.addressHomeDeleteButtonflexBox {
  display: flex;
  justify-content: space-between;
}
.ButtonDivAddAddress {
  padding-left: 40px;
  margin-top: 12px;
  padding-right: 20px;
}
.containeritemforAddreeeList {
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
}
.ListContainer {
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 0px;
}
.noAddressDivContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100%; */
  padding: 20px;
}
.No_AddressFoundDiv {
  display: flex;
  width: 70%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.Address_Profile_crossbutton_Div {
  width: 100%;
  display: flex;
  justify-content: end;
}
.Address_update_confirmation_popup {
  position: absolute;
  text-align: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 0px 10px;
  width: 350px;
  z-index: 1;
  overflow: hidden;
  padding: 20px;
  height: 238px;
}
.Address_Update_Profile_Heading {
  font-size: 16px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #5c5c5c;
}
.Address_page_Update_Profile_Paragraph {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #545454;
  margin-top: 25px;
}
.Address_Page_Update_Profile_Button_Div {
  margin-top: 80px;
  display: flex;
  justify-content: end;
}
.Address_Page_UpdateProfile_button3 {
  border: 1px solid #379d75;
  color: #379d75;
  width: 110px;
  height: 35px;
  background-color: white;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
}
@media only screen and (max-width: 600px) {
  .MobileViewAddressListPage {
    padding-top: 20px;
  }
  .containeritemforAddreeeList {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .ListContainer {
    padding-left: 0px;
    padding-right: 0px;
    margin: 0;
  }
  .address-list {
    border-top: none;
    overflow-y: auto;
  }
  .divForMobileUI {
    height: 1px;
    background-color: #f5f5f5;
  }
  .MainMobileViewPage {
    display: flex;
    align-items: center;
  }
  .markDefaultMobileView {
    color: #3366cc;
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: center;
    align-items: center;
    margin-left: 10px;
    padding: 9px;
    cursor: pointer;
  }
  .AddressHeadingtag {
    padding-bottom: 10px;
  }
  .AddressListEditButtonMobile {
    border: 1px solid #379d75;
    width: 44px;
    height: 25px;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
    color: #379d75;
    background-color: transparent;
    border-radius: 2px;
  }
  .buyerMainAddressPage {
    margin: 0px;
    width: 100%;
    padding: 0;
    background-color: #fff;
    /* margin-top: 103px; */
  }
  .buyerUserSideNavbarAnchor.active {
    text-decoration: none;
    color: black;
    color: #4dabb0;
    height: 40px;
    width: 100%;
    font-size: 13px;
    border: none;
    font-weight: bold;
    background-color: white;
    border: 1.5px solid black;
  }
  .buyerMainDivForNavProfileInfoSection {
    display: inline;
    width: 100%;
  }
  .buyerProfileInfoMainDivForNavSection {
    display: none;
    width: 100%;
    text-align: center;
    height: 90px;
    margin: 0px;
  }
  .ButtonDivAddAddress {
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 0px;
  }
  .buyerMainDivForAddressSection {
    width: 100%;
    border-radius: 0px;
    padding: 0;
    height: max-content;
  }
  .address {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    align-items: center;
  }
  .address-info {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(1, 1fr);
  }

  .delete-confirmation-popup p {
    margin-bottom: 10px;
    font-weight: 500;
    margin: 0;
  }
  .delete-confirmation-popup .button1 {
    background-color: #ffffff;
    color: #3366cc;
    border: none;
    line-height: 15px;
    cursor: pointer;
  }

  .addresstypediv {
    width: 100%;
    margin-top: 15px;
  }
  .ModalContent {
    position: relative;
    display: flex;
    background-color: transparent;
    width: 100%;
    padding: 0px;
    border-radius: 10px;
    bottom: 0;
    height: 100%;
  }
  .Address_update_confirmation_popup {
    top: auto;
    left: 0;
    position: fixed;
    transform: translate(0%, 0%);
    width: 100%;
    height: 238px;
    bottom: 0%;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
  .delete-confirmation-popup {
    top: auto;
    left: 0;
    padding: 20px 15px;
    position: fixed;
    transform: translate(0%, 0%);
    width: 100%;
    height: 185px;
    bottom: 0%;
    border-radius: 0px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
  .buttonDiv {
    margin-top: 30px;
    justify-content: space-around;
  }
  .delete-confirmation-popup .button1 {
    width: 45%;
    font-size: 14px;
  }
  .delete-confirmation-popup .button2 {
    width: 45%;
  }
}
