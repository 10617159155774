.productsUnavailableContainer {
  position: absolute;
  text-align: center;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  border-radius: 10px;
  width: 280px;
  height: 200px;
  z-index: 1;
  padding: 10px 10px;
  display: flex;
  justify-content: space-around;
  /* align-items: center;  */
  flex-direction: column;
}
.productsUnavailableTxt {
  font-size: 16px;
  font-weight: 500;
  margin: 0;
}
.checkoutOutOfStockProductTxt {
  margin: 0;
  margin-top: -20px;
  color: #ea5e3d;
  font-size: 12px;
  font-weight: 500;
  width: 100%;
  text-align: center;
}
.productsUnavailableBtnContainer {
  display: flex;
  justify-content: space-around;
}
.productsUnavailableContinueBtn {
  background-color: #ea5e3d;
}
.productsUnavailableCloseBtn {
  background-color: #b0b0b0;
}
.productsUnavailableBtnContainer button {
  width: 100px;
  font-size: 17px;
  font-weight: 500;
  border: none;
  padding: 5px;
  color: #ffff;
}
.CheckoutPageRadioBtnOuterDiv {
  border-radius: 50%;
  height: 16px;
  width: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.CheckoutPageRadioBtnInnerDiv {
  background-color: #3366cc;
  border-radius: 50%;
  height: 8px;
  width: 8px;
}
.skeleton-loading-forCheckout {
  display: flex;
  flex-direction: row;
  margin: auto;
  width: 100%;
  justify-content: space-between;
  gap: 20px;
  margin-top: 45px;
  padding-bottom: 50px;
}
.skeleton-CheckoutRow1 {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 81%;
  height: 300px;
  background-color: #f5f5f5;
}
.skeleton-CheckoutRow1Column1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 53px;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 0px;
  background-color: #ffffff;
}
.skeleton-CheckoutRow1Column2 {
  display: flex;
  justify-content: space-between;
  height: 65px;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  background-color: #ffffff;
}
.skeleton-CheckoutRow1Column3 {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #ffffff;
  height: 200px;
  margin-top: -20px;
}
.skeleton-CheckoutRow2 {
  width: 24%;
  background-color: #ffffff;
  height: 385px;
  padding-left: 10px;
  padding-right: 10px;
}
.skeleton-CheckoutRowProductSummary {
  width: 200px;
  height: 26px;
  background-color: #f5f5f5;
}
.skeleton-CheckoutRowProductDropdown {
  width: 60px;
  height: 26px;
  background-color: #f5f5f5;
}
.skeleton-CheckoutRowProductSummary2 {
  width: 150px;
  height: 26px;
  background-color: #f5f5f5;
}
.skeleton-CheckoutRowEditButtonDiv {
  display: flex;
  justify-content: space-between;
}
.skeleton-CheckoutRow1Data1 {
  width: 60%;
  height: 20px;
  margin-top: 20px;
  background-color: #f5f5f5;
}
.skeleton-CheckoutRow1Data2 {
  height: 28px;
  width: 7%;
  margin-top: 20px;
  background-color: #f5f5f5;
}
.skeleton-CheckoutRow1Data3 {
  width: 63%;
  height: 20px;
  background-color: #f5f5f5;
  margin-top: 16px;
}
.skeleton-CheckoutRow1Data4 {
  width: 50%;
  height: 14px;
  margin-top: 4px;
  background-color: #f5f5f5;
}
.skeleton-CheckoutRow1Data5 {
  width: 20%;
  height: 30px;
  padding-bottom: 35px;
  background-color: #f5f5f5;
  margin-top: 22px;
}
.skeleton-CheckoutRow2Div1 {
  width: 46%;
  height: 17px;
  background-color: #f5f5f5;
  margin-top: 10px;
}
.skeleton-CheckoutRow2Div2 {
  display: flex;
  justify-content: space-between;
  margin-top: 35px;
}
.skeleton-CheckoutRow2Div21 {
  width: 51%;
  height: 20px;
  background-color: #f5f5f5;
}
.skeleton-CheckoutRow2Div22 {
  width: 20%;
  height: 20px;
  background-color: #f5f5f5;
}
.skeleton-CheckoutRow2Div3 {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}
.skeleton-checkoutHr {
  color: #e1e3e3;
}
.skeleton-CheckoutRow2Div6 {
  height: 40px;
  width: 100%;
  background-color: #f5f5f5;
  margin-top: 70px;
}
.checkoutPageOrderConfirmationMainDiv {
  margin: auto;
  display: flex;
  flex-flow: column;
  margin-top: 27px;
  width: 90%;
  height: 500px;
  padding-bottom: 100px;
  border: 1px solid #c7c7c7;
  background-color: #ffffff;
}
.defaultAddressDivCheckoutPage {
  margin-left: 10px;
  width: 90%;
  font-size: 14px;
  padding: 10px;
  padding-top: 0px;
}
.checkoutPageProductSummaryContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.checkoutPageOrderConfirmedDiv {
  color: #009359;
  text-align: center;
  font-weight: 500;
  margin-top: 40px;
}
.checkoutPageOrderConfirmationDiv {
  margin-left: 48%;
  margin-top: 5%;
}
.checkoutPageOrderConfirmationParaDiv {
  text-align: center;
  margin-top: 3%;
  color: #777777;
  font-size: 16px;
  font-weight: 450;
}
.checkoutPageOrderConfirmationParaForMobile {
  margin: 0px;
}
.checkoutPageOrderConfirmationButtonDiv {
  margin: auto;
  gap: 30px;
  display: flex;
  margin-top: 7%;
}
.continueShoppingButton {
  height: 45px;
  width: 240px;
  background-color: #ffffff;
  color: #ea5e3d;
  font-weight: 450;
  font-size: 17px;
  border: 1px solid #ea5e3d;
  cursor: pointer;
  border-radius: 2px;
}
.viewOrderButton {
  height: 45px;
  width: 240px;
  background-color: #ea5e3d;
  color: #ffff;
  font-weight: 450;
  font-size: 17px;
  border: 1px solid #ea5e3d;
  cursor: pointer;
  border-radius: 2px;
}
.disabledBtn-Loading {
  width: 25px;
  height: 25px;
}
.checkoutPageDiscountDiv {
  margin: auto;
  margin-top: 20px;
  background-color: #f2fcf9;
}
.checkoutPriceAndDiscount {
  display: flex;
  margin-top: 20px;
  gap: 10px;
}
.checkoutPageAddressPageAddressType {
  color: #898989;
  font-weight: 500;
  padding: 0px 10px;
  background-color: #ffffff;
  border: 1px solid #f5f5f5;
  border-radius: 20px;
  margin-left: 10px;
  font-size: 12px;
}
.checkoutPageYayText {
  font-size: 11px;
  color: #038d63;
  font-weight: 500;
  padding: 6px;
  text-align: center;
}
.checkoutNameAddressDetailsContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.checkoutTotalcharges {
  margin-top: 10px;
  border-top: 1px solid #f5f5f5;
}
.checkoutPage-cart-container {
  width: 65%;
  display: flex;
  justify-content: center;
  background-color: #f5f5f5;
}
.checkout-main-container-div {
  width: 100%;
  display: flex;
  justify-content: center;
  height: auto;
  /* margin-top: 110px; */
  background-color: #f5f5f5;
  min-height: 65vh;
}
.checkoutPageAddNewAddress {
  padding: 10px;
  padding-left: 19px;
}
.checkoutPageAddAddressBtn {
  border: 1.5px solid #379d75;
  color: #379d75;
  width: 130px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 500;
}
.checkout-cartContainerHolder {
  margin: auto;
  display: flex;
  /* justify-content: space-between; */
  margin: 25px 0px;
  width: 100%;
}
#checkout-AddressDivContainer {
  margin-top: 10px;
}
.checkout-image-and-name-div {
  display: flex;
  justify-content: flex-start;
  width: 450px;
  margin: 0;
}
.checkoutProductAddressDetails {
  margin-right: 20px;
  width: 77%;
}
.checkout-brand-p-tag {
  margin: 0;
  font-size: 12px;
  color: #909090;
  font-weight: 400;
  line-height: 12px;
}
.checkout-ShippingCharges {
  color: #128807;
  font-size: 14px;
  font-weight: 400;
}
.checkoutpage-currentPrice {
  color: #393939;
  margin: 0;
  font-weight: 500;
  font-size: 18px;
}
.checkoutpage-orignalPrice {
  color: #868686;
  font-size: 12px;
  margin: 0;
}
.checkout-brand-name-description {
  list-style: none;
  color: #000000;
  font-size: 15px;
  font-weight: 500;
  margin: 0;
}
.checkout-products-list-details {
  margin-left: 40px;
  width: 100%;
  display: flex;
  align-items: center;
}
.checkout-container-having-productdetails {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-top: 1px solid #f5f5f5;
  margin: 10px;
  padding-top: 20px;
  margin-top: 0;
}
.checkout-image-container {
  height: 140px;
  width: 110px;
  object-fit: contain;
  border-radius: 2px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.03);
}
.checkoutProductPriceSummaryDetails {
  width: 300px;
  background-color: #ffffff;
  height: 400px;
  padding: 10px;
}
.checkoutProductPriceSummaryDetailsWithoutAddress {
  width: 300px;
  height: 300px;
  background-color: #ffffff;
  padding: 10px;
}
.checkout-cart-item-heading {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  min-height: 40px;
  padding-left: 10px;
}
.checkout-cart-container-1 {
  width: 100%;
  height: auto;
  background-color: #ffffff;
}
.main-container-div {
  /* margin-top: 110px; */
  margin-bottom: 0px;
}
#NoAddressAvailableInCheckout {
  display: flex;
  align-items: center;
  min-width: 60vh;
  height: 350px;
  border: none;
  justify-content: center;
}
.checkout-heading {
  font-size: 2.7vh;
  font-weight: bold;
  margin-top: 2vh;
}
.changeDivStatus {
  width: 50px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.checkout-heading-txt {
  color: #656565;
  font-weight: 500;
  font-size: 16px;
  margin: 10px;
}
.price-paragraph-tag11 {
  margin: 0px;
  font-size: 14px;
  width: 100%;
  color: #545454;
  justify-content: space-between;
  display: flex;
  font-weight: 400;
}
.price-div-tag111 {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  align-items: end;
  width: 140px;
}
.checkoutPageAddress-block {
  padding: 0px 20px;
  margin: 0px;
}
#checkoutPageAddress-blockList {
  text-align: center;
  border: none;
}
.checkout-addressContainer {
  display: flex;
  text-align: initial;
  width: 100%;
  justify-content: space-between;
  padding: 0px 10px;
  font-size: 13px;
}
.checkout-addressContainer-selectedAddress {
  background-color: #fdefec;
}
.checkout-addressContainerComponent {
  display: flex;
  padding: 10px;
  padding-left: 0;
  align-items: center;
}
.checkoutNameAddressContainer {
  width: 480px;
  padding-left: 10px;
}
.checkoutPageAddressDetailsTxt {
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  color: #737373;
  line-height: 20px;
}
.checkoutAddressNameNumber {
  color: #000000;
  font-weight: 500;
  width: max-content;
}
.checkoutAddressEditBtn {
  display: flex;
  color: #3366cc;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  height: 27px;
  background-color: #ffffff;
  margin: auto;
  padding: 10px;
}
.checkoutProductPriceSummaryDetails-charges {
  display: flex;
  justify-content: space-between;
  margin: 10px 0px;
}
#checkoutProductPriceSummaryDetails-charges {
  margin-top: 0;
}
.checkoutPriceSummaryHeading {
  color: #252525;
  font-size: 12px;
  font-weight: 500;
}
#checkoutProductPriceSummaryDetails-chargesContainer {
  margin-top: 20px;
  border-bottom: 1px solid #f5f5f5;
}
.checkoutPageAddressDetailsTxt-infoTxt {
  color: #7e7e7e;
  font-size: 10px;
  font-weight: 400;
  width: 100%;
  line-height: 16px;
  /* margin-top: 20px;
  margin-bottom: 20px; */
}
.checkoutPagePlaceOrderErrorMsg {
  color: red;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 15px;
  width: 100%;
  line-height: 16px;
}
.checkoutPlaceOrderContainer {
  margin-top: 50px;
}
.checkoutPlaceOrderBtn {
  height: 40px;
  width: 100%;
  background-color: #ea5e3d;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  margin-top: 10px;
  margin-bottom: 5px;
  cursor: pointer;
}
.disabledCheckoutPlaceOrderBtn {
  height: 40px;
  width: 100%;
  background-color: #ea5e3d;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  margin-top: 10px;
  margin-bottom: 5px;
  cursor: pointer;
}
@media only screen and (min-width: 1350px) and (max-width: 1600px) {
  .checkoutPage-cart-container {
    width: 70%;
  }
}
@media only screen and (min-width: 1000px) and (max-width: 1350px) {
  .checkoutProductPriceSummaryDetailsWithoutAddress {
    height: max-content;
  }
  .checkout-brand-name-description {
    font-size: 12px;
  }
  .checkoutProductAddressDetails {
    width: 70%;
  }
  .checkoutPriceAndDiscount {
    margin-top: 10px;
  }
  .checkoutpage-currentPrice {
    font-size: 15px;
  }
  .checkout-container-having-productdetails {
    padding-top: 10px;
  }
  .checkoutPageAddressDetailsTxt-infoTxt {
    margin: 0px;
  }
  .checkoutPlaceOrderContainer {
    margin-top: 25px;
  }
  .checkoutProductPriceSummaryDetails {
    height: max-content;
  }
  .checkoutPage-cart-container {
    width: 85%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1000px) {
  .skeleton-CheckoutRow1 {
    width: 100%;
  }
  .skeleton-CheckoutRow2 {
    width: 100%;
  }
  .checkoutPage-cart-container {
    width: 90%;
  }
  .checkout-products-list-details {
    margin-left: 20px;
  }
  .checkout-container-having-productdetails {
    padding-top: 10px;
  }
  .checkoutProductAddressDetails {
    margin: 0;
    width: 100%;
  }
  .checkout-cartContainerHolder {
    flex-direction: column;
    gap: 10px;
  }
  .skeleton-loading-forCheckout {
    flex-direction: column;
    gap: 10px;
  }
  .checkoutProductPriceSummaryDetailsWithoutAddress {
    width: 100%;
    height: max-content;
  }
  .checkoutPageAddressDetailsTxt-infoTxt {
    margin: 10px 0px;
  }
  .checkoutPlaceOrderContainer {
    margin-top: 20px;
  }
  .checkoutProductPriceSummaryDetails {
    height: auto;
    width: 100%;
  }
  .checkoutNameAddressContainer {
    width: auto;
  }
  .checkoutPriceAndDiscount {
    margin-top: 10px;
  }
  .checkout-image-container {
    height: 100px;
    width: 90px;
  }
  .checkout-brand-name-description {
    font-size: 12px;
  }
  .checkoutpage-currentPrice {
    font-size: 12px;
  }
}
@media only screen and (max-width: 600px) {
  .mobileViewCheckoutShippingCost {
    justify-content: flex-start !important;
    gap: 20px !important;
  }
  .skeleton-loading-forCheckout {
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;
  }
  .skeleton-CheckoutRow1 {
    width: 100%;
  }
  .skeleton-CheckoutRow2 {
    width: 100%;
  }
  .defaultAddressDivCheckoutPage {
    margin-left: 0;
  }
  .checkout-heading-txt {
    margin-left: 0;
  }

  .checkoutPageAddressDetailsTxt-infoTxt {
    font-size: 12px;
    margin: 5px 0px;
  }
  .main-container-div {
    /* margin-top: 70px; */
    min-height: auto;
  }
  .checkoutAddressNameNumber {
    width: 100%;
  }
  .checkoutPageOrderConfirmationButtonDiv {
    gap: 20px;
    flex-direction: column;
    margin-top: 20%;
    width: 100%;
    padding: 10px;
    padding-bottom: 30px;
  }
  .continueShoppingButton {
    width: 100%;
  }
  .viewOrderButton {
    width: 100%;
  }
  .checkoutPageOrderConfirmationMainDiv {
    width: 95%;
    height: 100%;
    padding-bottom: 20px;
  }
  .checkoutPageOrderConfirmationDiv {
    margin-left: 44%;
  }
  .confirmationImageLogo {
    height: 35px;
    width: 35px;
  }
  .checkoutPageOrderConfirmationParaDiv {
    margin-top: 10%;
    font-size: 14px;
  }
  .checkoutPageOrderConfirmationPara {
    display: none;
  }

  .checkout-container-having-productdetails {
    padding-top: 17px;
  }
  .checkoutPageAddAddressBtn {
    width: 120px;
  }
  .checkout-image-and-name-div {
    width: auto;
  }
  .checkout-products-list-details {
    margin-left: 16px;
    display: block;
  }
  .checkoutpage-currentPrice {
    font-size: 16px;
    color: #000000;
  }
  .checkout-image-container {
    height: 80px;
    width: 70px;
  }
  #checkoutpageCurrentOff {
    margin-left: 10px;
    font-size: 12px;
    font-weight: 500;
  }
  .price-paragraph-tag11 {
    margin-top: 8px;
    font-size: 13px;
  }
  .checkoutPriceAndDiscount {
    display: flex;
    margin-top: 8px;
    gap: 0px;
  }
  .checkout-brand-name-description {
    font-size: 12px;
    color: #515151;
    width: 100%;
  }
  .checkout-cartContainerHolder {
    display: block;
    margin: 0px;
    margin-bottom: 10px;
    overflow: hidden;
  }
  .checkoutPageYayText {
    font-size: 13px;
  }
  .checkoutProductAddressDetails {
    width: auto;
    margin: 0;
    /* margin-top: 10px; */
  }
  .checkoutPage-cart-container {
    min-height: auto;
    width: 100%;
  }
  .checkout-addressContainer-selectedAddress {
    padding-bottom: 5px;
  }
  .checkoutNameAddressDetailsContainer {
    justify-content: flex-start;
    padding: 5px 0px;
  }
  .checkout-addressContainer {
    display: block;
    padding-top: 5px;
    color: #636363;
  }
  .checkoutPageAddress-block {
    padding: 0;
    font-size: 11px;
  }
  .checkoutProductPriceSummaryDetails {
    width: 100%;
    height: 405px;
    padding: 10px;
    height: auto;
    width: auto;
    margin-top: 10px;
  }
  .checkoutProductPriceSummaryDetailsWithoutAddress {
    width: 100%;
    margin-top: 10px;
    padding: 30px;
  }
  .checkoutAddressEditBtn {
    justify-content: center;
    align-items: center;
    width: 50px;
    margin: 10px;
    margin-left: 25px;
    margin-top: 5px;
    margin-bottom: 5px;
    height: 25px;
    border-radius: 2px;
    font-size: 12px;
    font-weight: 500;
  }
  .price-div-tag111 {
    display: none;
  }
  #NoAddressAvailableInCheckout {
    min-width: auto;
    max-height: auto;
    margin: 15px;
    border-radius: 2px;
    height: auto;
    align-items: center;
  }
  #checkoutPageAddress-blockList {
    min-width: auto;
    max-height: auto;
    height: auto;
    overflow-x: scroll;
    border: none;
    padding: 0px;
    font-size: 1.5vh;
  }
  .addressContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}
