* {
  margin: 0;
  padding: 0;
  font-family: Segoe UI, Roboto, Arial, sans-serif !important;
}
*::-webkit-scrollbar {
  width: 6px;
}
*::-webkit-scrollbar-track {
  background: #f1f1f1;
}
*::-webkit-scrollbar-thumb {
  background: #bbbbbb;
  border-radius: 5px;
}
*::-webkit-scrollbar-thumb:hover {
  background: #555;
}
* {
  scrollbar-width: thin;
  scrollbar-color: #bbbbbb #f1f1f1;
}
* {
  scrollbar-track-color: #f1f1f1;
}
* {
  scrollbar-face-color: #bbbbbb;
}
*:hover {
  scrollbar-face-color: #555;
}
