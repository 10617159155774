.skeleton-loading-forCart {
  display: flex;
  flex-direction: row;
  margin: auto;
  width: 100%;
  justify-content: space-between;
  gap: 20px;
}
.fA-cross-icons-button {
  background: none;
  border: none;
  position: relative;
  top: -20px;
  right: -10px;
}
.skeleton-Cartrow1 {
  display: flex;
  flex-direction: column;
  width: 75%;
  background-color: #ffffff;
  padding-left: 25px;
  padding-right: 25px;
}
.skeleton-CartColumn1 {
  width: 105px;
  height: 26px;
  background-color: #f5f5f5;
  margin-top: 16px;
}
.cart-delete-buttonTab-index {
  border: none;
  background: none;
}
.skeleton-Cartrow2 {
  width: 25%;
  height: 165px;
  background-color: #ffffff;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 10px;
}
.skeleton-CartColumn2InnerRow1 {
  height: 115px;
  width: 104px;
  background-color: #f5f5f5;
}
.skeleton-CartColumn2 {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-bottom: 17px;
  gap: 30px;
}
.skeleton-CartInnerColumn1 {
  width: 380px;
  height: 18px;
  background-color: #f5f5f5;
}
.skeleton-CartInnerColumn2 {
  width: 150px;
  height: 18px;
  background-color: #f5f5f5;
  margin-top: 28px;
}
.skeleton-CartInnerColumn3 {
  width: 200px;
  height: 18px;
  background-color: #f5f5f5;
  margin-top: 5px;
}
.skeleton-CartColumn2InnerRow3 {
  height: 32px;
  width: 38px;
  background-color: #f5f5f5;
  margin-left: 32%;
}
.inner-hrline-forCart {
  color: #e1e1e1;
  margin-top: 0px;
}
.skeleton-CartInnerColumn21 {
  height: 18px;
  width: 50%;
  background-color: #f5f5f5;
}
.skeleton-CartInnerColumn22 {
  height: 19px;
  width: 55%;
  background-color: #f5f5f5;
  margin-top: 24px;
}
.skeleton-CartInnerColumn23 {
  height: 12px;
  width: 87%;
  background-color: #f5f5f5;
  margin-top: 28px;
}
.skeleton-CartInnerColumn24 {
  height: 35px;
  width: 100%;
  background-color: #f5f5f5;
  margin-top: 5px;
}
.cartProductsContainer {
  padding: 10px 25px;
}
.outOfStock {
  opacity: 0.3;
}
.lineInDeleteBox {
  display: none;
}
.cartPageOutOfStockLabel {
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  color: #f4aba4;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
.NoItemsInCart {
  text-align: center;
  align-items: center;
  background-color: white;
  padding-top: 120px;
  padding-bottom: 170px;
  width: 100%;
}
.cart-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding-top: 120px;
  padding-bottom: 170px;
  width: 100%;
}
.main-container-div {
  width: 100%;
  height: 100%;
  /* background-color: #e3e6e6; */
  /* margin-top: 110px; */
  /* min-height: 50vh; */
  margin-bottom: 0px;
  justify-content: center;
  display: flex;
}
.cartPageProductPriceSymbol {
  color: #a0a0a0;
  font-weight: 500;
}
.cart-container {
  width: 65%;
  margin: 25px 0px;
  display: flex;
  justify-content: flex-start;
  /* background-color: #e3e6e6; */
}
.cartContainerInnerLargeBox {
  width: 74%;
  margin: 0px;
  background-color: white;
  margin-right: 20px;
  padding: 0px;
}
.cartContainerInnerSmallBox {
  width: 23%;
  height: max-content;
  background-color: #ffffff;
}
.trash-icon {
  height: 16px;
  width: auto;
  color: #c4c4c4;
}

.imageAndDetailsMainDiv {
  display: flex;
  gap: 25px;
  justify-content: space-between;
  margin: 15px 0px;
}
.image-container {
  height: 128px;
  width: 110px;
}
.imageAndDetailsMainDivContainer {
  display: flex;
  width: 90%;
  flex-direction: row;
}
.cartDetailsDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 30px;
}
.cartPageProductName {
  color: #303030;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  margin: 0;
}
.cartPageProductName.truncate {
  white-space: wrap;
  overflow: hidden;
  text-overflow: hidden;
}
.hrLineForCartPage {
  width: 100%;
  height: 2px;
  color: #6f6f6f;
}
.priceAndSubTotalDiv {
  display: flex;
  flex-direction: column;
}
.priceHeadingTag {
  padding-left: 15px;
  padding-top: 10px;
  color: #404040;
  font-size: 16px;
  font-weight: 500;
}
.cartPageSubTotalDiv {
  display: flex;
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 15px;
  color: #7c7c7c;
  font-size: 15px;
  font-weight: 500;
  margin-top: 15px;
}
.checkoutButtonDiv {
  display: flex;
  flex-direction: column;
  padding: 15px;
  margin-top: 0px;
}
.checkoutText {
  font-size: 10px;
  color: #aeaeae;
}
.disabledSidebarCheckoutButtonForCart {
  width: 100%;
  height: 45px;
  background-color: #ea5e3d;
  color: white;
  border: none;
  border-radius: 2px;
  font-size: 18px;
  font-weight: 400;
  cursor: default;
  margin-top: -5px;
}
.sidebarCheckoutButtonForCart {
  width: 100%;
  height: 45px;
  background-color: #ea5e3d;
  color: white;
  border: none;
  border-radius: 2px;
  font-size: 18px;
  font-weight: 400;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.1s;
}
.sidebarCheckoutButtonForCart:hover {
  transform: scale(1.05);
}
.cartCountHeading {
  font-weight: 500;
  color: #6f6f6f;
  font-size: 17px;
}
.cartCountHeading p {
  margin: 0;
}
.cartPageProductPrice {
  color: #000000;
  font-size: 20px;
  font-weight: 500;
  margin: 0;
}
.cartPageDiscountPercentage {
  font-weight: 500;
  font-size: 13px;
  color: #69b597;
  text-decoration: none;
  margin: 0;
  margin-top: 3px;
}
.estimatedPriceDiv {
  color: #868686;
  font-size: 12px;
  margin: 0;
  font-weight: 400;
}
.estPriceSpanTag {
  color: #4e4e4e;
  font-size: 13px;
  margin: 0;
  font-weight: 400;
  text-decoration: line-through;
}
.cartPageDeleteButton {
  height: fit-content;
  border: none;
  display: flex;
  justify-content: end;
  outline: none;
  cursor: pointer;
  background-color: white;
  transition: all 0.3s;
  padding: 5px;
  width: 10%;
}
.cartPagePriceDiv {
  display: flex;
  margin-top: 5px;
  line-height: 20px;
  gap: 10px;
  align-items: center;
}
.Checkoutbuttondiv {
  margin-right: 3vh;
}
.loadingCartPage {
  height: 30vh;
  width: 30vh;
}
.cartPageProductNameForMobile {
  display: none;
}
.headingMessageOfAlert {
  text-align: start;
  margin: 0;
  color: #595959;
  font-size: 16px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;
}
.CartPage_CrossMarkButton {
  color: #a3a3a3;
  width: "18px";
  height: "18px";
}
.CartPage_CrossMarkButton:hover {
  color: #5a5a5a;
  width: "18px";
  height: "18px";
}
.cartPageProductNameHeadingContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;
}
.cartPageProductNameHeading {
  padding: 0px;
  margin: 0px;
  color: #000000;
  font-size: 14px;
  text-align: left;
}
.cartPageProductNameHeadingSpanTag {
  font-weight: 600;
}
.alertModalButtonDiv {
  display: flex;
  gap: 20px;
  position: absolute;
  bottom: 15px;
  right: 20px;
}
.buttonForRemove {
  width: 100px;
  height: 35px;
  border: 1px solid #fc441b;
  background-color: #ffffff;
  color: #fc441b;
  font-weight: 500;
  border-radius: 4px;
}
.buttonForWishlisht {
  width: 150px;
  height: 35px;
  border: 1px solid #ea5e3d;
  background-color: #ea5e3d;
  color: #ffffff;
  font-weight: 450;
  border-radius: 2px;
}
.ModalContentForCart {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}
.modal-container {
  position: absolute;
  padding: 25px;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  border-radius: 4px;
  z-index: 1;
  width: 400px;
  min-height: 220px;
}
.warning-modal h1 {
  text-align: start;
  font-size: 16px;
  font-weight: 600;
}
.warning-modal .message {
  font-size: 14px;
  margin: 15px 0px;
}
.warning-modal .modal-buttons {
  margin-top: 85px;
}
.warning-rm-btn {
  float: right;
  font-size: 14px;
  padding: 8px 12px;
  color: white;
  background-color: #ea5e3d;
  border: none;
  outline: none;
  border-radius: 4px;
  transition: all 0.2s ease;
}
.warning-rm-btn:hover {
  cursor: pointer;
  background-color: #cf5236;
}
.imageAndDetailsMainDivForDeleteButton {
  display: none;
}
.crossbuttonForCart:hover {
  cursor: pointer;
}
.cartPageLoadingImage {
  display: flex;
  margin: auto;
  height: 60px;
  width: 60px;
}

@media only screen and (min-width: 1650px) and (max-width: 1800px) {
  .cart-container {
    width: 75%;
    height: 100%;
  }
  .cartContainerInnerLargeBox {
    width: 70%;
  }
  .cartContainerInnerSmallBox {
    width: 26%;
  }
  .cartDetailsDiv {
    padding-left: 33px;
    width: 100%;
  }
}
@media only screen and (min-width: 1551px) and (max-width: 1650px) {
  .cart-container {
    width: 75%;
    height: 100%;
  }
  .cartContainerInnerLargeBox {
    width: 70%;
  }
  .cartContainerInnerSmallBox {
    width: 26%;
  }
  .cartDetailsDiv {
    padding-left: 33px;
    width: 100%;
  }
}
@media only screen and (min-width: 1451px) and (max-width: 1550px) {
  .cart-container {
    width: 75%;
    height: 100%;
  }
  .cartContainerInnerLargeBox {
    width: 70%;
  }
  .cartContainerInnerSmallBox {
    width: 26%;
  }
  .cartDetailsDiv {
    padding-left: 33px;
    width: 100%;
  }
}
@media only screen and (min-width: 1351px) and (max-width: 1450px) {
  .cart-container {
    width: 75%;
    height: 100%;
  }
  .cartContainerInnerLargeBox {
    width: 70%;
  }
  .cartContainerInnerSmallBox {
    width: 26%;
  }
  .cartDetailsDiv {
    padding-left: 33px;
    width: 100%;
  }
}
@media only screen and (min-width: 1251px) and (max-width: 1350px) {
  .cartContainerInnerLargeBox {
    width: 66%;
  }
  .cartContainerInnerSmallBox {
    width: 28%;
  }
}
@media only screen and (min-width: 1151px) and (max-width: 1350px) {
  .cart-container {
    width: 90%;
    height: 100%;
  }
  .cartContainerInnerSmallBox {
    width: 30%;
  }
  .cartDetailsDiv {
    padding-left: 33px;
    width: 100%;
  }
  .cartPageOutOfStockLabel {
    margin-top: 17px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1000px) {
  .cart-container {
    width: 90%;
    gap: 20px;
    flex-direction: column;
  }
  .cartContainerInnerLargeBox {
    width: 100%;
  }
  .cartContainerInnerSmallBox {
    width: 100%;
  }
  .image-container {
    height: 100px;
    width: 80px;
  }
  .cartPageProductName {
    font-size: 12px;
  }
  .cartPageProductPrice {
    font-size: 15px;
  }
  .estimatedPriceDiv {
    font-size: 10px;
  }
  .imageAndDetailsMainDiv {
    margin: 8px 0px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1150px) {
  .cart-container {
    width: 90%;
  }
  .skeleton-loading-forCart {
    flex-direction: column;
  }
  .skeleton-Cartrow1 {
    width: 100%;
  }
  .skeleton-Cartrow2 {
    width: 100%;
  }
  .skeleton-Cartrow3 {
    width: 100%;
  }
}
@media only screen and (max-width: 600px) {
  .fA-cross-icons-button {
    right: 0;
    top: -10px;
  }
  .skeleton-loading-forCart {
    flex-direction: column;
  }
  .skeleton-Cartrow1 {
    width: 100%;
  }
  .skeleton-Cartrow2 {
    width: 100%;
  }
  .skeleton-CartColumn2 {
    gap: 10px;
  }
  .skeleton-CartInnerColumn3 {
    width: 75%;
  }
  .skeleton-CartColumn2InnerRow1 {
    height: 100px;
    width: 75px;
  }

  .skeleton-CartColumn2InnerRow3 {
    margin-left: 20%;
  }
  .skeleton-CartInnerColumn1 {
    width: 100%;
  }
  .skeleton-CartColumn2InnerRow1 {
    height: 89px;
    width: 85px;
  }
  .NoItemsInCart {
    padding-top: 35px;
    padding-bottom: 60px;
  }
  .crossbuttonForCart {
    display: block;
    margin-top: 3px;
    margin: 0;
  }
  .cart-container {
    width: 100%;
    flex-direction: column;
    height: 100%;
    margin: 0px;
    margin-bottom: 2px;
    gap: 2px;
    padding: 0px;
  }
  .cartContainerInnerLargeBox {
    width: 100%;
    margin-left: 0px;
  }
  .cartContainerInnerSmallBox {
    width: 100%;
    height: 100%;
  }

  .checkoutButtonDiv {
    margin-top: -11px;
  }
  .imageAndDetailsMainDiv {
    flex-direction: row;
    gap: 0px;
    margin-top: 0px;
    width: 100%;
  }
  .image-container {
    height: 100px;
    width: 80px;
  }
  .cartDetailsDiv {
    margin-left: 10px;
    width: 100%;
    padding-left: 0px;
    margin-top: 3px;
  }
  .deleteModalForMobileDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: -3px;
  }

  .cartPageProductName {
    display: none;
  }
  .cartPageProductNameForMobile {
    display: flex;
    color: #676767;
    width: 200px;
    flex-wrap: wrap;
    font-size: 12px;
    font-weight: 500;
    margin: 0;
  }
  .cartPageProductPrice {
    font-size: 15px;
  }
  .cartPageDiscountPercentage {
    font-size: 12px;
    margin-top: 2px;
  }
  .estimatedPriceDiv {
    font-weight: 450;
  }
  .cartPageOutOfStockLabel {
    margin-top: -9px;
    margin-left: 0;
  }
  .modal-container {
    position: fixed;
    top: 100%;
    width: 100%;
    height: 520px;
    padding: 20px 15px;
    border-radius: 10px 10px 0 0;
  }
  .headingMessageOfAlert {
    display: flex;
    font-size: 18px;
    margin: 0;
  }
  .cartPageProductNameHeading {
    display: none;
  }
  .imageAndDetailsMainDivForDeleteButton {
    display: flex;
    flex-direction: row;
    gap: 15px;
  }
  .alertModalButtonDiv {
    position: relative;
    top: 0;
    left: 0;
  }
  .imageContainerForRemove {
    height: 95px;
    width: 78px;
    height: 95px;
    width: 78px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
  }
  .cartPageProductNameForMobileDelete {
    display: flex;
    color: #676767;
    font-size: 14px;
    font-weight: 500;
    margin: 0;
  }
  .estimatedPriceDivForDeleteButton {
    color: #4e4e4e;
    font-size: 12px;
    text-align: start;
    font-weight: 400;
  }
  .lineInDeleteBox {
    display: flex;
    border: none;
    border-top: 1px solid #646464;
    color: #e1e3e1;
  }
  .buttonForRemove {
    width: 100%;
    height: 45px;
    font-weight: 500;
    border-radius: 2px;
  }
  .buttonForWishlisht {
    width: 100%;
    height: 45px;
    font-weight: 400;
  }
  .cartPageProductNameDivForMobile {
    width: 98%;
  }
}
