.pagination-bar {
  margin: 100px 0px 25px 0px;
  display: flex;
  justify-content: center;
}
.pagination {
  display: flex;
  flex-wrap: wrap;
}
.pagination li {
  margin: 0px 5px;
  border-radius: 4px;
}
.pagination li:last-child,
.pagination li:first-child {
  margin: 0px;
  z-index: 1;
}
.active > .page-link,
.page-link.active {
  z-index: 1;
}

@media only screen and (max-width: 1024px) {
  .pagination-bar {
    margin: 50px 0px 25px 0px;
  }
}
