.description h1 {
  margin: 35px 0px;
  font-size: 26px;
}
.description h2 {
  margin: 25px 0px;
  font-size: 22px;
}
.description h3 {
  margin: 25px 0px;
  font-size: 18px;
}
.description h4 {
  margin: 15px 0px;
  font-size: 16px;
}
.description h5 {
  margin: 15px 0px;
  font-size: 14px;
}
.description h6 {
  margin: 10px 0px;
  font-size: 12px;
}
.description p {
  margin: 25px 0px;
  font-size: 16px;
  color: #757575;
}
.description ul,
.description ol {
  margin-left: 20px;
}
.description li {
  color: #757575;
  font-size: 16px;
}
.description a {
  color: #0000ee;
  text-decoration: underline;
}
.description img {
  max-width: 100%;
  height: auto;
}
.description blockquote {
  padding: 10px;
  color: #757575;
  font-style: italic;
  background-color: #f5f5f5;
  border-left: 4px solid #ccc;
}
.description q {
  font-style: italic;
}
.description code {
  font-size: 14px;
  background-color: #f5f5f5;
  padding: 2px 4px;
  border-radius: 4px;
}
.description table {
  border-collapse: collapse;
  width: 100%;
}
.description th,
.description td {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}
.description tr:nth-child(even) {
  background-color: #f2f2f2;
}
.description input,
.description textarea,
.description select,
.description button {
  font-size: 16px;
}
