.about-us {
  min-height: 50vh;
}
.about-description {
  font-size: 16px;
  font-weight: 500;
}
.about-description p {
  margin: 25px 0;
}
.about-description ul {
  margin-top: -10px;
}
.about-description li {
  font-weight: 400;
}
.about-description span {
  margin-top: 5px;
  display: block;
  font-weight: 400;
}
