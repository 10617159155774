.FilterButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  padding: 5px 5px 10px 5px;
}
.filter-values-sort-item {
  display: none;
}
.SortButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  padding: 5px 5px 10px 5px;
}
.sort-filter-text {
  margin: 0;
  padding: 0;
  font-weight: 500;
}
.sort-filter-selected-text {
  margin: 0px;
  padding: 0;
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
}
.mobileUISortIcon {
  margin-right: 10px;
}
.mobileUIFilterIcon {
  margin-right: 10px;
}
.crossbuttondiv {
  background-color: #fff;
  display: flex;
  justify-content: end;
  width: 100%;
}
.fontawesomefilterbutton {
  padding: 10px;
  align-items: end;
}
.allFilterComponents {
  display: none;
}
.mainFilterDivElement {
  display: none;
}
.filterDrawer {
  display: none;
}
.brandList {
  padding-left: 200px;
}
.css-4t3x6l-MuiPaper-root-MuiDrawer-paper {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2),
    0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  overflow-y: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  width: 100%;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  z-index: 1200;
  -webkit-overflow-scrolling: touch;
  position: fixed;
  top: 0;
  outline: 0;
  left: 0;
  display: none;
}
@media only screen and (min-width: 600px) and (max-width: 1050px) {
  .filterDrawer {
    display: block;
    width: 100%;
  }
  .sortBtnsHeaderTxt {
    margin: 0px;
  }
  .sort-buttons {
    display: grid;
    margin-bottom: 25px;
  }
  .allFilterComponents {
    display: block;
  }
  .sortBtnsHeader {
    display: flex;
    justify-content: left;
    align-items: center;
    font-weight: 600;
    font-size: 25px;
    border-bottom: 1px solid #e5e4e4;
    height: 50px;
    padding: 10px;
    padding-left: 20px;
    margin-bottom: 10px;
  }
  .mainFilterDivElement {
    position: fixed;
    bottom: 0;
    cursor: pointer;
    width: 100%;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    background-color: white;
    border-top: 1px solid #cecece;
  }
  .filter-values-sort-item {
    display: grid;
    background-color: white;
  }
  .css-4t3x6l-MuiPaper-root-MuiDrawer-paper {
    display: block;
  }
  .filter {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 3px;
    border-radius: 0;
  }
}
@media only screen and (max-width: 600px) {
  .filterDrawer {
    display: block;
    width: 100%;
  }
  .allFilterComponents {
    display: block;
  }
  .sortBtnsHeader {
    display: flex;
    justify-content: left;
    align-items: center;
    font-weight: 600;
    font-size: 25px;
    border-bottom: 1px solid #e5e4e4;
    height: 50px;
    padding: 10px;
    padding-left: 20px;
    margin-bottom: 10px;
  }
  .mainFilterDivElement {
    position: fixed;
    bottom: 0;
    cursor: pointer;
    width: 100%;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    background-color: white;
    border-top: 1px solid #cecece;
  }
  .filter-values-sort-item {
    display: grid;
    background-color: white;
  }
  .css-4t3x6l-MuiPaper-root-MuiDrawer-paper {
    display: block;
  }
  .filter {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 3px;
  }
}
