#footer-section {
  position: relative;
  z-index: 1;
}

#footer-section ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
#footer-section li {
  font-size: 14px;
  width: fit-content;
}
#footer-section li span:hover {
  cursor: pointer;
  text-decoration: underline;
}
#footer-section h2 {
  font-size: 16px;
  font-weight: 600;
}
#footer-section h1 {
  font-size: 18px;
  font-weight: 600;
}
.app-slogan {
  max-width: 250px;
  font-size: 14px;
}
#footer-section a {
  color: white;
  text-decoration: none;
}
#footer-section a:hover {
  cursor: pointer;
  text-decoration: underline;
}
#footer-section p {
  padding: 0px;
  margin: 0px;
}
.footer-box {
  max-width: 1500px;
  margin: auto;
}
.footer-1st-section {
  color: white;
  background-color: #f97252;
}
.footer-1st-section-container {
  max-width: 1220px;
  margin: auto;
  padding: 10px 25px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 25px;
  justify-content: space-between;
}
.footer-1st-section-container > div {
  width: 100%;
}
.footer-2nd-section {
  color: white;
  background-color: #f97252;
}
.footer-2nd-section-container {
  max-width: 1220px;
  margin: auto;
  padding: 0px 25px 10px 25px;
  display: flex;
  gap: 25px;
  justify-content: space-between;
}
.footer-address > span {
  max-width: 250px;
}
.footer-icons {
  display: flex;
  gap: 12px;
  font-size: 20px;
}
.footer-icons a {
  margin: 0 !important;
}
.footer-icon {
  transition: transform 0.3s ease;
}
.footer-icon:hover {
  cursor: pointer;
  transform: scale(1.2);
}
.footer-a-tag {
  display: flex;
  flex-direction: column;
  font-size: 14px;
}
.footer-a-tag li {
  list-style: none;
}
.footer-a-tag a {
  margin-left: 5px;
}
.footer-2nd-section-container > div {
  width: 100%;
}
.newsletter-para {
  font-size: 14px;
}
.newsletter-input-line {
  margin-top: 5px;
  white-space: nowrap;
  display: flex;
}
.footer-newsletter-input {
  color: white;
  padding: 4px 6px;
  padding-left: 0px;
  border-right: none;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #ffffff96;
  outline: none;
}
.footer-newsletter-input::placeholder {
  font-size: 14px;
  color: white;
  opacity: 0.7;
}
.news-letter-loading {
  height: 20px;
  width: 20px;
}
.newsletter-btn {
  font-size: 14px;
  padding: 4px 6px;
  color: white;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #ffffff96;
  outline: none;
}
.footer-newsletter-input:focus {
  border-bottom: 1px solid white;
}
.footer-newsletter-input:focus + .newsletter-btn {
  border-bottom: 1px solid white;
}
.paper-plane {
  opacity: 0.7;
  transform: rotate(15deg);
  transition: all 0.2s ease;
}
.newsletter-btn:active .paper-plane {
  transform: translateX(5px);
}
.newsletter-btn:hover .paper-plane {
  opacity: 1;
}
.footer-3rd-section {
  color: white;
  background-color: #ea5e3d;
}
.footer-3rd-section-container {
  padding: 15px 25px;
  max-width: 1220px;
  margin: auto;
  display: flex;
  gap: 25px;
  font-size: 12px;
  justify-content: space-between;
}
.footer-policy-link > span:hover {
  text-decoration: underline;
  cursor: pointer;
}

/* Media Query Start From Here */
@media only screen and (max-width: 768px) {
  /* #footer-section {
    margin-top: 50px;
  } */
  .footer-2nd-section-container {
    flex-direction: column;
  }
  .footer-3rd-section-container {
    flex-direction: column;
    row-gap: 10px;
  }
}
@media only screen and (max-width: 425px) {
  .footer-1st-section-container {
    grid-template-columns: 1fr 1fr;
  }
}
@media only screen and (max-width: 280px) {
  .footer-1st-section-container {
    grid-template-columns: 1fr;
  }
}
