.skeletonBorderLine {
  color: #f5f5f5 !important;
  border-color: #f5f5f5 !important;
  background-color: #f5f5f5 !important;
}
.profileSkeletonInputBoxes {
  width: 100%;
  background-color: #f5f5f5;
  height: 30px;
  margin: 10px 0px;
}
#SkeletonUpdateProfileButton {
  background-color: #f5f5f5;
  border: none;
}
.close-profile-popup-button {
  height: 25px;
  border: none;
  background: none;
  padding: 10px;
}
#addMoreChildBtn {
  font-size: 18px;
}
.button-profile-tabindex {
  border: none;
  background: none;
}
#childDetails {
  padding: 10px 0px;
}
.hover-pointer {
  cursor: default;
  font-size: 16px;
  width: 100%;
  margin-top: 10px;
  padding: 10px 10px;
  border-radius: 5px;
  border: none;
  background-color: #ea5e3d;
  color: #fff;
  font-size: 14px;
}
.updateMobileNumberResendOTPBtn {
  height: max-content;
  margin-top: -10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: end;
}
.updateMobileNumberResendOTPBtn-txt {
  font-size: 0.75rem;
  margin: 0;
  color: blue;
}
.profilechild_button_remove {
  background-color: #ea5e3d !important;
  width: 100px;
  height: 35px;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  margin-left: 20px;
  color: #ffffff !important;
  border: 1px solid #ea5e3d;
}
.Profile_info_Delete_error_Message {
  list-style: none;
  color: red;
  font-size: 12px;
  font-weight: 550;
  text-align: left;
  height: 20px;
}
.crossbutton {
  display: flex;
  justify-content: end;
}
.Profile_Update_popupContainer {
  margin: 0;
  padding: 0;
  padding-bottom: 30px;
}
.Profile_crossbuttondelete {
  position: relative;
  display: flex;
  justify-content: end;
  right: -20px;
}
.unableToDeleteModalTextHolder {
  margin: 20px;
}
.unableToDeleteModalText {
  margin: 0px;
  font-size: 15px;
  font-weight: 400;
}
.unableToDeleteModalCloseBtn {
  background-color: #ea5e3d;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
  border-radius: 2px;
}
.ModalContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
}
.select-wrapper {
  position: relative;
  margin-top: 40px;
  margin-right: 20px;
}
.delete-requestotp-container {
  margin-right: 20px;
  margin-top: 20px;
}
.select-label {
  position: absolute;
  top: -10px;
  left: 10px;
  pointer-events: none;
  transition: top 0.2s;
  font-size: 0.2s;
  font-size: 12px;
  color: #888;
  background-color: white;
}
.Profile_DeleteAccount_container {
  margin: 0;
  height: 65px;
  padding: 0;
}
.Profile_info_Delete_Account_svg {
  position: relative;
  top: -50px;
  margin-left: 90%;
  cursor: pointer;
}
.select-Tag {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  color: #000000 !important;
}
.Delete-Box-Container {
  display: flex;
  width: 100%;
  gap: 20px;
}
.Request-Otp-Button {
  border-radius: 3px;
  color: white;
  border: 1px solid #ea5e3d;
  height: 40px;
  background-color: #ea5e3d;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
}
.ModalContent {
  position: relative;
  display: flex;
  background-color: transparent;
  width: 550px;
  /* height: 650px; */
  padding: 20px;
  border-radius: 10px;
}
.ModalContentDelete {
  position: relative;
  display: flex;
  background-color: transparent;
  width: 800px;
  padding: 20px;
  border-radius: 10px;
}
.popup-delete {
  background-color: #fff;
  border-radius: 5px;
  padding: 10px 30px;
  position: relative;
  top: 50%;
  height: max-content;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  z-index: 10;
  height: 347px;
}
.Profile-Delete-line-tag {
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #717171;
}
.Profile-Delete-heading-tag {
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #5e5e5e;
}
.DeleteText-container {
  width: 380px;
}
.Delete-Container-Box2 {
  width: 380px;
}
.updateCancleBtn {
  align-items: center;
}
.disabledBtn {
  opacity: 0.5;
}
.popup {
  background-color: #fff;
  border-radius: 5px;
  padding: 10px 15px;
  position: relative;
  top: 25%;
  height: max-content;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  text-align: center;
  z-index: 10;
}
.popup h4 {
  margin-top: 0;
}
.popup button {
  padding: 8px 16px;
  border-radius: 5px;
  border: none;
  background-color: #ccc;
  color: black;
  cursor: pointer;
}
.popup button:first-child {
  margin-right: 10px;
  background-color: #f2f2f2;
  color: #333;
}
.popup button:hover {
  background-color: #aaa;
}
.popupContainer {
  text-align: left;
  padding: 20px;

  border-radius: 10px;
}
:root {
  --size-bezel: 10px;
  --color-background: #f5f5f5;
  --color-accent: #000000;
  --size-radius: 5px;
}
.ProfileChildrenEditSaveChangesDiv {
  display: flex;
  justify-content: center;
}
.allBoxContainer {
  background-color: #f5f5f5 !important;
  /* min-height: 65vh; */
  display: flex;
  justify-content: center;
  padding-top: 10px;
}
.buyerMainProfileInformationPage {
  padding: 25px 0px;
  max-width: 1450px;
  width: 90%;
  /* margin-top: 110px; */
  font-size: 24px;
}
.buyerMainDivForNavProfileInfoSectioncontainer {
  display: flex;
  /* justify-content: space-between; */
  gap: 1%;
  font-size: 24px;
}
.buyerProfileInfoMainDivForNavSection {
  width: 25%;
}
.buyerMainDivForProfileSection {
  width: 74%;
  background-color: white;
  border-radius: 5px;
}
.buyerUserSideNavbar {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 4px;
}
.yesbutton {
  font-size: 16px;
  width: 100%;
  padding: 10px 10px;
  border-radius: 5px;
  border: none;
  background-color: #ea5e3d;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
}
.headingpopupemail {
  color: #000000;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  margin: 0;
  margin-bottom: 20px;
}
.profileContainerBox {
  margin: 25px;
}
.basicUserInfoContainer {
  display: grid;
  width: 100%;
}
.ProfileNameClass {
  display: grid;
  gap: 30px;
  width: 60%;
  grid-template-columns: repeat(2, 1fr);
}
.MaleFemaleButtons {
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  padding: 2px;
  border: 1px solid #dfdfdf;
  color: #595959;
  width: 80px;
}
.UpdateProfileButton {
  width: 109px;
  height: 30px;
  background-color: #ea5e3d;
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: center;
  border: 2px solid #ea5e3d;
  border-radius: 2px;
}
.HrLineProfileinfoPage {
  background-color: #f5f5f5;
  margin-top: 15px;
  margin-bottom: 15px;
}
.ChildrensHeaginTag {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #656565;
}
.EmailMobileNoAddContainer {
  display: grid;
  gap: 30px;
  width: 60%;
  grid-template-columns: repeat(2, 1fr);
}
.ChildAddBirthContainer {
  width: 45%;
  height: auto;
  border-radius: 5px;
  padding: 10px;
  border: 1px solid #f5f5f5;
  background-color: #f5f5f5;
}
.AddInformationButton {
  width: 65px;
  height: 24px;
  color: #3366cc;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: center;
  border-radius: 5px;
  background-color: #ffffff;
  border: 2px solid #f5f5f5;
}
.AddInfoLineDetails {
  color: #8a8a8a;
  list-style: none;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  width: 90%;
}
.ChangePasswordContainer {
  display: grid;
  width: 300px;
  border-radius: 5px;
  margin-right: 40px;
  padding: 10px;
  border: 1px solid #f5f5f5;
  background-color: #f5f5f5;
}
.ReferAndEarnContainer {
  display: grid;
  width: 300px;
  border-radius: 5px;
  margin-right: 40px;
  padding: 10px;
  border: 1px solid #f5f5f5;
  background-color: #f5f5f5;
}
.ButtonContainer {
  display: flex;
  justify-content: space-between;
}
.listStyleforChangePasswordAndDeactivateAccount {
  width: 200px;
  list-style: none;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #8a8a8a;
}
.ProfileNameClassButton {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin-top: 15px;
}
.DeactivateAccountContainer {
  display: grid;
  width: 300px;
  border-radius: 5px;
  padding: 10px;
  border: 1px solid #f5f5f5;
  background-color: #f5f5f5;
}
.ContainerButtonforaccountandDeactivate {
  display: flex;
}
.ChangePasswordline {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  list-style: none;
}
.RemoveAccountText {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  list-style: none;
}
.ChangePasswordButton {
  width: 60px;
  height: 22px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: center;
  color: #3366cc;
  background-color: #ffffff;
  border: 1px solid #f5f5f5;
}
.referandEarnButton {
  width: 80px;
  height: 22px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: center;
  color: #379d75;
  background-color: #ffffff;
  border: 1px solid #f5f5f5;
}
.DeactivateAccountButton {
  width: 75px;
  height: 22px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: center;
  color: #ea5e3d;
  background-color: #ffffff;
  border: 1px solid #f5f5f5;
}
.genderOptionsHolder {
  display: flex;
  justify-content: center;
}
.updateGenderOptionsHolder {
  display: flex;
  justify-content: center;
}
.genderOptionsBtn {
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  padding: 2px;
  border: 1px solid #f5f5f5;
  background-color: white;
  color: #595959;
  width: 80px;
  height: 40px;
  border-radius: 2px 0px 0px 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.selectedGenderOption {
  color: #ea5e3d !important;
  background-color: #fdefec !important;
}
.SaveChangesButton {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;
  cursor: pointer;
  color: #ffffff;
  padding: 5px;
  border-radius: 2px;
  height: 40px;
  width: 100px;
  margin: 0px 5px;
  border: none;
}
#SaveChangesButton-save {
  background-color: #ea5e3d;
}
#SaveChangesButton-cancel {
  background-color: #b0b0b0;
  margin-left: 10px;
}
.updateBtn {
  background-color: #ea5e3d;
}
.removeBtn {
  background-color: #f1f1f1;
  color: #797979;
}
.AddChildGenderSection {
  padding-top: 3px;
}
.AddmoreButton {
  border-radius: 2px;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;
  color: #ffffff;
  background-color: #379d75;
  padding: 5px;
  border: none;
  cursor: pointer;
  height: 30px;
  width: 109px;
}
.gendererror {
  font-size: 0.7rem;
  font-weight: bold;
  color: red;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.headingmessage {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #515151;
  margin-top: 20px;
}
.profilepageHrHeadingline {
  border: 1px solid #f5f5f5;
  background-color: #f5f5f5;
}
.DeletemessagetoShow {
  font-size: 14px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  color: #000000;
  padding: 5px;
  text-align: left;
  margin-top: 20px;
}
.Updateheadingmessage {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  margin-bottom: 25px;
  text-align: center;
}
.updateyesbutton {
  width: 100%;
  border-radius: 2px;
  padding: 0;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  color: #fff !important;
  background-color: #ea5e3d !important;
  padding: 10px;
  border: none;
}
.ProfileMobileitemContainer {
  position: relative;
}
.profilepassword-eye-icons {
  color: #ea5e3d;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: 8px;
  right: 8px;
  font-size: 1rem;
  border-radius: 4px;
  height: 30px;
  background-color: #fff;
}
.email-password-eye-icons {
  /* height: 41px !important; */
  /* top: 3px !important; */
  padding-left: 2px;
  background-color: #fff;
}
.ProfileGenderButtonContainer {
  margin: 0;
  padding: 0;
  display: flex;
  margin-top: 3px;
}
.Profile-Email-verify-otp {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  color: #5e5e5e;
  margin-bottom: 45px;
}
.EmailPhoneDeleteverifybutton {
  border-radius: 3px;
  background-color: #ea5e3d !important;
  color: white !important;
  margin: 0px !important;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  height: 40px;
  border: 1px solid #ea5e3d;
}
.letMeStayButtonphonedelete {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  list-style: none;
  color: #3366cc;
  margin-bottom: 30px;
  margin-top: 20px;
  cursor: pointer;
}
.deleteResendButtonemail {
  position: relative;
  text-align: right;
  list-style: none;
  /* top: -20px; */
  font-size: 11px;
  color: #3366cc;
  cursor: pointer;
}
.letmestaybuttonforemail {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  list-style: none;
  color: #3366cc;
  margin-bottom: 30px;
  margin-top: 20px;
  cursor: pointer;
}
.Profile-phone-verify-otp {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  color: #5e5e5e;
  margin-bottom: 45px;
}
.deleteResendButtonphone {
  position: relative;
  text-align: right;
  margin-right: 20px;
  list-style: none;
  top: -20px;
  font-size: 11px;
  color: #3366cc;
  cursor: pointer;
}
.Profile_Update_email_popup {
  background-color: #fff;
  border-radius: 5px;
  padding: 10px 20px;
  position: relative;
  top: 25%;
  height: max-content;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  text-align: center;
  z-index: 10;
}
.Profile_Joyrejoy_lable_tag {
  position: relative !important;
  width: 100%;
}
.Profile_Joyrejoy_input_tag {
  width: 100%;
  padding: 10px 10px;
  margin-top: 10px;
  border: 1px solid #dfdfdf;
  outline: none;
  font-size: 14px;
  border-radius: 4px;
  color: #595959;
}
.Profile_Joyrejoy_input_tag::placeholder {
  opacity: 0;
}
.Profile_Joyrejoy_span_tag {
  position: absolute;
  top: 0px;
  left: 10px;
  transform: translateY(20px);
  font-size: 14px;
  transition-duration: 300ms;
  background-color: #fff;
  font-weight: 400;
}
.Profile_Joyrejoy_lable_tag:focus-within > .Profile_Joyrejoy_span_tag,
.Profile_Joyrejoy_input_tag:not(:placeholder-shown)
  + .Profile_Joyrejoy_span_tag {
  color: #a6a6a6;
  transform: translateY(0px);
  font-size: 12px;
  top: 2px;
}
@media only screen and (max-width: 1250px) {
  .ContainerButtonforaccountandDeactivate {
    display: grid;
    gap: 15px;
    margin: 25px 0px;
    justify-content: normal;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1250px) {
 
  .ChildAddBirthContainer {
    margin: 0;
    width: 90%;
  }
  .ReferAndEarnContainer {
    width: 90%;
    margin: 0;
  }
  .ChangePasswordContainer {
    width: 90%;
    margin: 0;
  }
  .DeactivateAccountContainer {
    width: 90%;
    margin: 0;
  }
  .listStyleforChangePasswordAndDeactivateAccount {
    width: 90%;
  }

  .EmailMobileNoAddContainer {
    display: flex;
    padding-top: 10px;
    flex-direction: column;
    gap: 20px;
    width: 70%;
  }
  .ProfileNameClass {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 70%;
  }
  .updateGenderOptionsHolder {
    justify-content: left;
  }
  .ProfileChildrenEditSaveChangesDiv {
    justify-content: left;
  }
  .ProfileNameClassButton {
    display: flex;
    flex-direction: column;
    width: 70%;
    gap: 20px;
  }
  .SaveChangesButton {
    margin: 0;
  }
  .genderOptionsHolder {
    justify-content: left;
  }
  .gendererror {
    justify-content: left;
  }
}

@media only screen and (min-width: 601px) and (max-width: 800px) {
  .popup-delete {
    width: 580px;
  }
}
@media only screen and (max-width: 600px) {
  /* .headingpopupemail {
    margin-bottom: 25px;
  } */
  .ContainerButtonforaccountandDeactivate {
    padding: 0px 25px;
  }
  .profileContainerBox {
    margin: 0px;
  }
  .basicUserInfoContainer {
    margin: 25px;
    width: auto;
  }
  .AccountDetailsDiv {
    display: grid;
    margin: 25px;
    width: auto;
  }
  .ChildrenBirthDayDiv {
    display: grid;
    margin: 25px;
    width: auto;
  }
  .Profile_Update_email_popup {
    top: auto;
    position: absolute;
    border-radius: 0;
    width: 100%;
    bottom: 0;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    left: 0%;
    transform: translate(0%, 0%);
  }
  .buyerMainProfileInformationPage {
    width: 100%;

    margin: 0px;
    padding: 0;
  }
  .ProfileNameClass {
    gap: 10px;
    width: 100%;
    grid-template-columns: repeat(1, 1fr);
  }
  .buyerMainDivForProfileSection {
    width: 100%;
    gap: 10px;
    border-radius: 0px;
    padding: 0px;
    height: max-content;
  }
  .profilepageHrHeadingline {
    display: none;
  }
  .ChildAddBirthContainer {
    width: 100%;
  }

  .EmailMobileNoAddContainer {
    gap: 10px;
    width: 100%;
    margin-bottom: 10px;
    grid-template-columns: repeat(1, 1fr);
  }
  .ReferAndEarnContainer {
    width: 100%;
    margin: 0;
  }

  .ProfileNameClassButton {
    grid-template-columns: repeat(1, 1fr);
    gap: 7px;
    padding-bottom: 15px;
  }
  .ChangePasswordContainer {
    width: 100%;
    margin: 0;
  }
  .DeactivateAccountContainer {
    width: 100%;
    margin: 0px;
  }
  .ChildrensHeaginTag {
    margin-top: 0px;
    margin-bottom: 25px;
  }
  .popup {
    bottom: 0;
    transform: translate(0%, 0%);
    width: 100%;
    text-align: center;
    left: 0;
    border-radius: 0px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
  .AddmoreButton {
    margin-top: 15px;
  }
  .profilemobileupadtebuttoncontainer {
    display: flex;
    justify-content: end;
  }
  .ProfileChildrenEditSaveChangesDiv {
    margin-top: 15px;
  }
  .ModalContentDelete {
    position: absolute;
    width: 100%;
    bottom: 0;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    padding: 0;
  }
  .popup-delete {
    padding: 10px 15px;
    height: fit-content;
    bottom: 0;
    border-radius: 0px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    left: 0%;
    transform: translate(0%, 0%);
  }
  .Delete-Box-Container {
    display: grid;
  }
  .DeleteText-container {
    width: 100%;
  }
  .Delete-Container-Box2 {
    width: 100%;
  }
  .delete-requestotp-container {
    margin-right: 0px;
    margin-top: 35px;
    margin-bottom: 30px;
  }
  .Profile_crossbuttondelete {
    right: -10px;
  }
  .select-wrapper {
    margin-right: 0px;
    margin-top: 35px;
  }
  .profilechild_button_remove {
    width: 45%;
  }

  .allBoxContainer {
    min-height: fit-content;
    padding-top: 0px;
  }
  .buyerProfileInfoMainDivForNavSection {
    width: 100%;
    text-align: center;
    height: 200px;
  }
  .buyerUserSideNavbar {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    box-shadow: none;
    border-radius: 0px;
    height: max-content;
    padding-top: 12px;
    padding-bottom: 5px;
  }

  .headingmessage {
    font-size: 16px;
    line-height: 18px;
  }
}
