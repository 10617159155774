.filter {
  border-radius: 5px;
  padding: 10px 20px 10px 0px;
  color: black;
  overflow: hidden;
  width: 100%;
  max-height: 500px;
  transition: box-shadow 0.3s ease;
  text-align: left;
}
.filter-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
}
.filter-container {
  border-top: 1px solid #edebef;
  border-right: 1px solid #edebef;
}
.filter-header h3 {
  margin: 0;
  font-size: 13px;
  font-weight: 400;
}
.filter-values {
  margin-top: 10px;
  max-height: 300px;
  /* min-width: max-content; */
  width: 100%;
  padding-left: 10px;
  min-height: 100px;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: none;
}
@media only screen and (max-width: 600px) {
  .filter-header {
    padding: 0;
    padding-left: 8px;
  }
  .filter {
    border-radius: 0;
  }
}
