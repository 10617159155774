.disabledElement {
  opacity: 0.5 !important;
}

.lazyImage {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.footerSkeleton {
  height: 250px;
  margin-top: 5px;
}
.InnerDivForHeadingContainer {
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.swiper-wrapper {
  padding: 0px 0px 1px;
}
.Toastify__toast {
  width: max-content;
}
.Toastify__toast-body {
  width: max-content;
  font-size: 12px;
}
.cookie-content {
  position: relative;
  padding: 15px 55px 15px 25px;
  display: flex;
  gap: 25px;
  justify-content: space-between;
  align-items: center;
}
.privacy-cookies-drawer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  z-index: 999;
  transition: transform 0.3s ease-in-out;
  transform: translateY(100%);
  box-shadow: 0px 1px 10px 1px #00000040;
}
.cookie-text {
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  color: #656565;
}
.cookie-heading {
  display: block;
  margin: 0px 25px 0px 0px;
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  color: #3e3e3e;
}
.cookie-link {
  font-size: 12px;
  font-weight: 600;
  color: #3366cc;
}
.cookie-link:hover {
  cursor: pointer;
  color: #ea5e3d;
}
.cookie-btn-area {
  display: flex;
  gap: 10px;
}
.cookie-remind-me-btn {
  white-space: nowrap;
  padding: 6px 10px;
  color: #3366cc;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border-radius: 4px;
  transition: all 0.2s;
  border: none;
  outline: none;
}
.cookie-remind-me-btn:hover {
  cursor: pointer;
  background-color: #eaeaea;
}
.cookie-accept-btn {
  padding: 6px 10px;
  color: #ffffff;
  font-size: 14px;
  border-radius: 4px;
  transition: all 0.2s;
  border: none;
  outline: none;
  background-color: #ea5e3d;
}
.cookie-accept-btn:hover {
  cursor: pointer;
  background-color: #d35437;
}
.cookie-close {
  right: 0;
  top: 0;
  position: absolute;
  padding: 0px 12px;
  color: #b8b8b8;
  border-radius: 4px;
  border: none;
  outline: none;
  background-color: transparent;
  transition: all 0.2s;
}
.cookie-close:hover {
  cursor: pointer;
  color: #868686;
}
.scroll-to-top-button {
  position: fixed;
  bottom: 50px;
  right: 20px;
  background-color: #fff;
  color: #a5a5a5;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 20px;
  margin-left: 97%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;
}
.scroll-to-top-button.show-scroll {
  display: block;
}
.visible {
  background-color: #fff;
  z-index: 1;
  transform: translateY(0);
  transition: transform 0.3s ease-in-out;
  box-shadow: 0px 1px 10px 1px #00000040;
}
.show {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  z-index: 999;
  transform: translateY(0);
  transition: transform 0.3s ease-in-out;
  box-shadow: 0px 1px 10px 1px #00000040;
}
@media only screen and (max-width: 1024px) {
  .cookie-content {
    padding: 15px 25px 15px 25px;
  }
}
@media only screen and (max-width: 768px) {
  .cookie-content {
    padding: 15px 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: space-between;
    align-items: center;
  }
}
@media only screen and (max-width: 600px) {
  .scroll-to-top-button {
    bottom: 70px;
  }
  .Toastify__toast {
    width: 100%;
  }
  .Toastify__toast-body {
    max-width: 100%;
    font-size: 12px;
  }
}
