#footer {
  margin-top: 100px;
  background-color: #ffffff;
  border: none;
  border-top: 1px solid #e6e6e6;
}
faEnvelope {
  color: red;
}
.buyerFooterContactInfo {
  font-size: 16px;
}
.main-footer {
  max-width: 1500px;
  margin: auto;
  padding: 30px 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  justify-content: space-between;
  gap: 25px;
}
.main-footer ul {
  list-style: none !important;
  padding-left: 0px;
}
.main-footer li {
  margin: 5px;
  margin-left: 0px;
  white-space: nowrap;
  cursor: pointer;
  width: max-content;
}
.main-footer li:hover {
  color: #ea5e3d;
}
.main-footer h1 {
  font-size: 20px;
  font-weight: 700;
  margin: 0px 0px 5px 0px;
}
.tag-line {
  margin-top: 25px;
}
.main-footer h2 {
  font-size: 18px;
  margin-bottom: 25px;
}
.main-footer ul li a {
  text-decoration: none;
  color: #494949;
}
.main-footer ul li a:hover {
  color: #ea5e3d;
}
.contact-details a:hover {
  text-decoration: underline;
}
.footer-last-line {
  background-color: #f5f5f5;
}
.footer-copyright {
  max-width: 1500px;
  display: flex;
  justify-content: space-between;
  gap: 15px;
  margin: 0 auto;
  font-size: 12px;
  color: #000000c7;
  padding: 15px 0px;
}
.footer-copyright p {
  margin-bottom: 0;
}

.contact-details {
  margin-top: 25px;
}
.social-icons {
  margin: 25px 0;
  display: flex;
  justify-content: flex-start;
  gap: 15px;
}
.icons {
  margin-right: 5px;
  font-size: 14px;
  color: #9b9d9f;
}
.newsletter-line {
  white-space: nowrap;
}
.newsletter-input {
  border: 1px solid #e6e6e6;
  outline: none;
  background-color: white;
  padding: 10px 10px;
  border-radius: 5px 0px 0px 5px;
  border-right: none;
}
.newsletter-submit-btn {
  border: none;
  outline: none;
  color: white;
  padding: 11px 15px;
  border-radius: 0px 5px 5px 0px;
  background-color: #ea5e3d;
  transition: all 0.2s;
}
.newsletter-submit-btn:hover {
  background-color: #cc5136;
}
.errorMessage-Email {
  line-height: 5px;
  list-style: none;
  font-size: 12px;
  font-weight: 500;
  color: red;
  margin-left: 5px;
}
.Feedback_modal_container {
  position: fixed;
  z-index: 3;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}
.Feedback_modal_c2 {
  background-color: #fefefe;
  margin: 10% auto;
  padding: 20px 25px 25px 25px;
  max-width: 500px;
  height: auto;
  border-radius: 4px;
  text-align: center;
}
.cross_button_container {
  display: flex;
  justify-content: end;
}
.cross_button {
  border: none;
  background-color: transparent;
}
#Feedback_Heading {
  color: #626262;
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 24px;
}
.rate_your_experience {
  text-align: left;
  font-size: 14px;
  color: grey;
  margin-bottom: 0;
}
.footer-textarea {
  max-width: 100%;
  min-width: 100%;
  max-height: 140px;
  min-height: 140px;
}
.submit-button {
  border: none;
  outline: none;
  width: 100%;
  padding: 10px 20px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 400;
  background-color: #ea5e3d;
  color: white;
  margin-top: 25px;
  transition: all 0.2s;
}
.fotter-error {
  font-size: 12px;
  font-weight: 600;
  color: red;
  text-align: left;
  list-style: none;
}
.login-label-tag {
  overflow: hidden;
  position: relative;
  width: 100%;
}
.login_input_tag {
  width: 100%;
  padding: 10px 15px;
  margin-top: 10px;
  border: 1px solid #dfdfdf;
  outline: none;
  border-radius: 2px;
  height: 40px;
  color: #595959;
}
.login_input_tag::placeholder {
  opacity: 0;
}
.login-Span-tags {
  position: absolute;
  top: 0px;
  left: 10px;
  transform: translateY(20px);
  font-size: 14px;
  transition-duration: 300ms;
  background-color: #fff;
  cursor: initial;
  font-weight: 400;
}
.login-label-tag:focus-within > .login-Span-tags,
.login_input_tag:not(:placeholder-shown) + .login-Span-tags {
  color: #7c7c7c;
  transform: translateY(0px);
  font-size: 12px;
  top: 2px;
}
.ckeckbox-container {
  display: flex;
  margin-top: 10px;
}
.checkbox-input {
  cursor: pointer;
}
.checkbox-label {
  text-align: left;
  font-size: 14px;
  color: grey;
  font-weight: 400;
}
@media only screen and (max-width: 1500px) {
  .main-footer,
  .footer-copyright {
    max-width: 1200px;
    width: 90%;
  }
}
@media only screen and (max-width: 1024px) {
  .main-footer {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media only screen and (max-width: 768px) {
  .main-footer {
    grid-template-columns: repeat(2, 1fr);
  }
  #footer {
    margin-top: 50px;
  }
}
@media only screen and (max-width: 750px) {
  .footer-copyright p {
    text-align: center;
    width: 100%;
    text-wrap: wrap;
  }
  .footer-copyright {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 5px 0px;
  }

  .Feedback_modal_container {
    background-color: #fff;
    overflow: auto;
    position: fixed;
    top: 0;
    z-index: 9;
    border-radius: 2px;
    flex-direction: column;
    height: 100%;
    overflow-y: scroll;
    padding: 0;
    scrollbar-width: none;
    width: 100%;
    margin-bottom: 10px;
  }
  .Feedback_modal_c2 {
    background-color: #fefefe;
    border-radius: 4px;
    height: 100%;
    max-width: 100%;
    margin-bottom: 30px;
    padding: 0px 10px 25px;
    text-align: center;
    margin: 1% auto;
  }
}
@media only screen and (max-width: 450px) {
  .main-footer {
    grid-template-columns: repeat(1, 1fr);
  }
  .footer-copyright {
    max-width: 1200px;
    width: 90%;
    flex-direction: column;
  }
  .newsletter-input {
    width: 180px;
  }
}
