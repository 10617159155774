.Feedback_New_Modal_container {
  position: fixed;
  z-index: 2;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}
.Feedback_NewModel_Container_b2 {
  background-color: #fefefe;
  margin: 100px auto;
  padding: 20px;
  max-width: 560px;
  height: auto;
  border-radius: 4px;
  text-align: center;
}
.feebback_head_new_tag {
  color: #626262;
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 18px;
}
.new-feedback-question {
  color: #464646;
  text-align: left;
  font-size: 14px;
}
.Is_Required {
  color: red;
  font-size: 14px;
}
.text-area-box-c1 {
  max-width: 100%;
  max-width: 100%;
  min-height: 100px;
  max-height: 100px;
}
.lable-floating-new-feedback {
  color: #595959;
}
.feeback-Submit-Button {
  border: none;
  outline: none;
  width: 100%;
  padding: 10px 20px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 400;
  background-color: #ea5e3d;
  color: white;
  margin-top: 25px;
  transition: all 0.2s;
}
.newFeedback-errors {
  font-size: 12.5px !important;
  color: red;
  list-style: none;
  text-align: left;
}
.feedback-cross-container {
  position: relative;
  display: flex;
  top: -70px;
  right: -25px;
  justify-content: end;
}
.modal-close {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 15px;
  top: 15px;
  font-size: 18px;
  padding: 8px 10px;
  border-radius: 4px;
  opacity: 0.4;
  color: black;
  transition: all 0.2s ease;
  width: 30px;
}
.modal-close:hover {
  opacity: 0.5;
  cursor: pointer;
  background-color: #dddddd;
}
@media only screen and (max-width: 750px) {
  .Feedback_New_Modal_container {
    background-color: #fff;
    overflow: auto;
    position: fixed;
    top: 0;
    z-index: 2;
    border-radius: 2px;
    flex-direction: column;
    height: 100%;
    overflow-y: scroll;
    padding: 0;
    scrollbar-width: none;
    width: 100%;
    margin-bottom: 10px;
  }
  .Feedback_NewModel_Container_b2 {
    background-color: #fefefe;
    border-radius: 4px;
    height: 100%;
    max-width: 100%;
    padding: 0px 10px 25px;
    text-align: center;
    margin: 4% auto;
  }
  .feeback-Submit-Button {
    margin-bottom: 20px;
  }
}
