@media only screen and (max-width: 600px) {
  .mobile_modal_Login_ModalContainer {
    background-color: rgba(0, 0, 0, 0.5) !important;
    display: flex !important;
  }
  #loginMobilePageNavbar {
    display: flex !important;
    background: none !important;
    position: absolute !important;
    /* top: -45px !important; */
  }
  .mobile_modal_containerBox {
    min-height: 300px !important;
  }
  .mobile_modal_navbar__logo {
    display: none !important;
  }
  .LoginBannerImgContainer {
    width: 100% !important;
    height: max-content !important;
  }
  .mobile_modal_TermsConditionsTextHolder {
    position: absolute !important;
    bottom: 0px !important;
  }
  .mobile_modal_BuyerLoginPage-innerBox-expanded {
    /* border: 1px solid red !important; */
    height: max-content !important;
    position: absolute !important;
    bottom: 0 !important;
    background-color: #fff !important;
    padding: 0px !important;
    border-radius: 0px !important;
  }
  .mobile_modal_BuyerLoginPage-footer-LoginResetPass-Mobile {
    margin-top: 20px !important;
  }
  .mobile_modal_modelInputFieldContainer {
    padding: 0px !important;
    padding-bottom: 50px !important;
  }
  .mobile_modal_mobileViewModalTitleContainer {
    margin: 0px;
  }
  .mobile_modal_mobileViewModalTitle {
    font-weight: 500;
    margin: 0;
    padding-bottom: 20px;
  }

  .mobile_modal_password-requirements {
    font-size: 13px !important;
  }
  .mobile_modal_resetPasswordResendOTP {
    font-size: 13px;
    bottom: -10px !important;
  }
}
