* {
  padding: 0;
  margin: 0;
}
.noMarginParagraph {
  margin: 0px;
}
.mobileViewChildCategories {
  padding-left: 20px;
  margin-right: 10px;
  border-radius: 2px;
}
#topRibbonCategoryHolder {
  display: flex;
  align-items: center;
  width: 90%;
  overflow-x: auto;
  scrollbar-width: none;
  transition: scroll 0.5s ease-in-out;
}
#topRibbonCategoryHolder::-webkit-scrollbar {
  display: none;
}
.navbar-logocontainer-button,
.sell_with-us-button {
  border: none;
  background: none;
  padding: 0;
  cursor: "pointer";
  align-items: center;
}
.topRibbonCategoryName {
  width: max-content !important;
  margin: 0;
}
.category-scroll-arrow {
  font-size: 12px;
  padding: 14px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  opacity: 0.7;
  transition: all 0.2s ease;
}
.category-scroll-arrow:hover {
  cursor: pointer;
  opacity: 1;
}
.ribbon-left-arrow {
  box-shadow: 4px 0px 8px rgba(0, 0, 0, 0.1);
}
.ribbon-right-arrow {
  box-shadow: -4px 0px 8px rgba(0, 0, 0, 0.1);
}
.sidebarContainer {
  position: fixed;
  z-index: 99;
  width: 100%;
  height: 100%;
  top: 0;
  background-color: #8a868675;
}
.navbarIconCircleContainer {
  height: 50px;
  width: 50px;
}
.categorySwiper {
  display: flex !important;
  justify-content: flex-start;
  padding: 10px 0px !important;
  width: 90% !important;
}
.categorySwiper .swiper-wrapper {
  width: 100%;
}
.categorySwiper .swiper-slide {
  width: fit-content !important;
  padding: 0px 10px;
  margin: 0px !important;
}
.categorySwiper .swiper-button-prev {
  border-radius: 5%;
  background-color: #fff;
  color: #000000;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
}
.categorySwiper .swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  opacity: 0.5;
  display: none;
}
.categorySwiper .swiper-button-next {
  border-radius: 5%;
  background-color: #e6e6e6;
  color: #000000;
  right: 0;
  height: 100%;
  position: absolute;
  top: 0;
  margin: 0;
}
.categorySwiper .swiper-button-prev:after {
  font-size: 12px;
  font-weight: 600;
}
.categorySwiper .swiper-button-next:after {
  font-size: 12px;
  font-weight: 600;
}
.categorySwiper p {
  font-weight: 400;
  color: rgba(3, 2, 26, 0.6);
}
.navbarLogoContainer {
  text-transform: uppercase;
  font-size: 1.4em;
  display: flex;
  gap: 10px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 70px;
}
.searchQueryBackGroundContainer {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1;
}
.theme {
  background-color: #ea5e3d;
}
.mobileViewOnly {
  display: none;
}
.login-btn-area {
  position: relative;
  display: flex;
  justify-content: center;
  border: none;
  background: none;
}
.NavbarLoginBtn {
  width: max-content;
  padding: 3px 15px;
  font-weight: 600;
  border-radius: 2px;
  transition: all 0.3s ease-in-out;
  background-color: white;
  border: transparent;
  outline: none;
}
.login-btn-tooltip {
  color: white;
  font: 14px;
  font-weight: 600;
  padding: 10px 40px;
  position: absolute;
  top: calc(100% + 8px);
  transform: translateX(-50%);
  border-radius: 2px;
  background-color: #ff9f00;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  animation: heartbeat 4.72s ease infinite;
  z-index: 1;
}
@keyframes heartbeat {
  0% {
    transform: translate(0);
  }
  1.8% {
    transform: translate(5px);
  }
  3.6% {
    transform: translate(0);
  }
  5.3% {
    transform: translate(5px);
  }
  7.1% {
    transform: translate(0);
  }
  8.9% {
    transform: translate(5px);
  }
  10.7% {
    transform: translate(0);
  }
  100% {
    transform: translate(0);
  }
}
.login-btn-tooltip::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ff9f00 transparent;
}
.NavbarLoginBtn:hover {
  cursor: pointer;
}
.login-profile-dropdown {
  position: relative;
  padding: 20px 0px;
}
.login-profile-dropdown:hover {
  cursor: pointer;
}
.login-profile-dropdown:hover .navbar__dropdown {
  display: block;
}
.nav-user-icon:hover + .navbar__dropdown {
  display: block;
}
.navbarDropDownList {
  display: flex;
  align-items: left;
  padding-left: 20px;
  cursor: pointer;
}
.navbarIcon {
  cursor: pointer;
  margin-left: 5px;
  display: flex;
  margin-right: 5px;
}
.navbarIcon-text {
  font-size: 14px;
  width: max-content;
  max-width: 100px;
  overflow: hidden;
  display: flex;
  align-items: center;
  color: white;
  margin: 0;
  margin-left: 5px;
  cursor: pointer;
  min-width: 30px;
}
.navbar__hamburger {
  display: none;
}
.UpdatedNavbar {
  transform: scale(1);
  transform-origin: 0 0;
}
.navbar-body {
  width: 100%;
  height: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
  background-color: white;
}
.main-navbar-container {
  max-width: 1500px;
  width: 100%;
  height: 70px;
  display: flex;
  gap: 25px;
  align-items: center;
  justify-content: space-between;
}
.navbar__logo {
  font-size: 26px;
  font-weight: 600;
  color: #e3e6e6;
  width: 20%;
  height: 100% !important;
}
.navbar__logo h3 {
  font-size: 20px;
  width: max-content;
  font-weight: 700;
  margin: 0;
  color: white;
}
.navbar__logo:hover {
  cursor: pointer;
}
.navbar__icons {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: end;
}
.main-navbar {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  background-color: #ea5e3d;
}
.navbar__icon-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  right: 15px;
  top: -15px;
  width: 18px;
  height: 18px;
  color: white;
  background-color: #ea5e3d;
  border-radius: 50%;
  font-size: 12px;
  border: 1px solid white;
}
.navbar__dropdown {
  display: none;
  z-index: 9;
  position: absolute;
  text-align: left;
  right: 0;
  min-width: 200px;
  width: max-content;
  height: fit-content;
  border-radius: 3px;
  background-color: white;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.navbar__dropdown:hover {
  display: block;
}
.signup-text {
  margin: 0;
  padding: 0;
}
.signup-text span {
  color: #3366cc;
}
.navbar__dropdown ul {
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
}
.navbar__dropdown li {
  font-size: 14px;
  padding: 5px 15px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.navbar__dropdown li:hover {
  cursor: pointer;
  background-color: #f0f0f0;
}
.signup-li {
  border-bottom: 1px solid #dfdfdf;
}
.logout-li {
  border-top: 1px solid #dfdfdf;
}
.navbar__dropdown a {
  display: block;
  padding: 5px;
  color: #333;
  text-decoration: none;
}
.navbar__dropdown a:hover {
  background-color: #f5f5f5;
}
.navbar-menu {
  color: white;
  background-color: #f97252;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
}
.navbar-menu-container {
  max-width: 1500px;
  margin: auto;
  width: 100%;
  height: 40px;
}
.top-menu-bar::-webkit-scrollbar {
  width: 0;
}
.all-prdoducts-menu {
  white-space: nowrap;
}
.top-menu-bar {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  height: 100%;
}
.top-menu-bar li {
  padding: 8px 15px;
  color: white;
  font-size: 14px;
  font-weight: 400;
  border: 1px solid transparent;
}
.top-menu-bar li:hover {
  cursor: pointer;
  border: 1px solid white;
}

.listofItemsDivInSidebar {
  display: flex;
  flex-direction: column;
}
.listOfToys {
  list-style: none;
  margin-left: -10px;
  line-height: 27px;
  margin-top: 9px;
  letter-spacing: 0em;
}
.listOfToys > li {
  font-size: 14px;
  color: #555555;
  font-weight: 400;
}
.lineBetweenTwoDivs {
  height: 1px;
  color: #979797;
}
.listOfOptionForAccess {
  list-style: none;
  margin-left: -10px;
  line-height: 27px;
  margin-top: -9px;
  letter-spacing: 0em;
}
.listOfOptionForAccess > li {
  font-size: 14px;
  color: #555555;
  font-weight: 400;
}
.navbar__links {
  display: flex;
  list-style: none;
  margin: 0;
  width: 100%;
  justify-content: flex-start;
  padding: 0;
  gap: 15px;
  flex-wrap: wrap;
}
.navbar__links li {
  font-size: 14px;
  font-weight: 600;
  color: #6b6b6b;
  white-space: nowrap;
}
.navbar__links li:hover {
  cursor: pointer;
  color: black;
}
.dropdown {
  position: relative;
}
.dropdown:hover {
  display: flex;
  opacity: 1;
  gap: 20px;
}
.close-icon {
  display: none;
}
.cartIconText {
  margin-left: -15px;
  margin-right: 10px;
}

/*==================== MEDIA QUERIES STARTS====================*/
@media only screen and (max-width: 1500px) {
  .main-navbar-container,
  .navbar-menu-container {
    max-width: 1200px;
    width: 90%;
  }
}
@media only screen and (max-width: 1024px) {
  .main-navbar-container,
  .navbar-menu-container {
    width: 95%;
  }
  .display-none-under-1024 {
    display: none;
  }
  .navbarIconCircleContainer {
    height: 45px;
    width: 45px;
  }
  .loginBtnIcon {
    margin-left: -20px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 900px) {
  .categorySwiper {
    width: 85% !important;
  }
}
@media only screen and (max-width: 600px) {
  .login-btn-tooltip {
    display: none !important;
  }
  .navbarLogoContainer {
    height: 30px;
  }
  .WebViewOnly {
    display: none !important;
  }
  .navbar__dropdown {
    display: none !important;
  }
  .sidebarHeadingAndButtonDiv {
    display: flex;
    justify-content: space-between;
    background-color: #ea5e3d;
  }
  .helloText {
    padding-top: 17px;
    padding-left: 25px;
    color: #ffffff;
    font-size: 12px;
    margin: 0px;
  }
  .nameTextInMobile {
    padding-left: 23px;
    /* margin-top: -14px; */
    font-size: 18px;
    font-weight: 400;
    color: #ffffff;
  }
  .closeButtonDivForHamburger {
    padding: 5px 15px;
    font-size: 15px;
  }
  #NavbarLoginBtnTxt {
    margin-left: -5px;
  }
  .loginBtnIcon {
    margin-left: -10px;
  }
  .mobileViewOnly {
    display: block;
  }
  .navbar__icon-badge {
    width: 14px;
    height: 14px;
    right: 5px;
    top: -7px;
    font-size: 9px;
  }
  .UpdatedNavbar {
    transform: scale(1);
    transform-origin: 0 0;
  }
  .navbar-body {
    background-color: #ea5e3d;
  }
  .navbar__logo {
    position: absolute;
    left: 40px;
    width: auto;
    font-size: 17px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    height: 50px;
  }
  .navbar__logo h3 {
    font-size: 20px;
    width: max-content;
    font-weight: 700;
    margin: 0;
  }
  .NavbarLoginBtn {
    color: #ffffff;
    background-color: #ea5e3d;
    width: max-content;
    padding: 3px 0px;
    font-weight: 600;
    margin: 0;
    cursor: pointer;
  }
  .navbar__links {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
    padding: 0;
    color: white;
  }
  .navbar__links > li {
    font-size: 14px;
    margin-top: 20px;
    font-weight: bold;
    color: black;
    margin-left: 10%;
  }
  .navbar__links a {
    color: #000000;
    text-decoration: none;
  }
  .navbar__links > li:hover {
    color: #f5f5f5;
    cursor: pointer;
  }
  .sellwithus {
    font-size: 14px;
    font-weight: bold;
    color: black;
    margin-top: 20px;
    margin-left: 10%;
  }
  .navbar-menu {
    width: 100%;
    height: 5px;
    display: none;
  }
  .navbar-menu-container {
    display: none;
  }
  .navbarDropDownList {
    padding: 0;
  }
  .navbar__hamburger {
    display: block;
    border: none;
    font-size: 18px;
    color: #ffffff;
    background-color: #ea5e3d;
  }
  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 75%;
    height: 100%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background-color: #ffffff;
    z-index: 999;
  }
  .sidebar {
    display: none;
  }
  .open .sidebar {
    display: block;
  }
  .navbar-with-pink-strip {
    display: none;
  }
  .searchbarContainer {
    /* display: none; */

    width: 100%;
    position: absolute;
    padding: 0px 5px;
    left: 0;
    top: 0;
    margin-top: 40px !important;
    background: #ea5e3d;
  }
  .main-navbar {
    height: 40px;
  }

  .box-container {
    position: relative;
    width: 100%;
    margin: auto;
  }
  .closeIconForHamburger {
    border: none;
    background-color: #ea5e3d;
    color: #ebdcdc !important;
    font-size: 18px;
    font-weight: lighter;
    -webkit-text-fill-color: #ebdcdc; /* For older iOS versions */
    -webkit-fill-color: #ebdcdc; /* For newer iOS versions */
  }
  .Header {
    transform: scale(1);
    transform-origin: 0 0;
  }
  .navbar-body {
    background-color: white;
    min-width: 280px;
  }
  .main-navbar-container {
    height: auto;
    padding: 10px;
    width: 100%;
  }
  .navbar__logo {
    width: auto;
    color: #ffffff;
  }
  .navbar__icons {
    width: 130px;
    position: absolute;
    right: 10px;
    display: flex;
    justify-content: flex-end;
  }
  .navbar__links {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
    padding: 0;
    color: white;
  }
  .navbar__links > li {
    font-size: 14px;
    margin-top: 20px;
    font-weight: bold;
    color: black;
    margin-left: 7px;
  }
  .navbar__links a {
    color: #ffffff;
    text-decoration: none;
  }
  .navbar__links > li:hover {
    color: #f5f5f5;
    cursor: pointer;
  }
  .sellwithus {
    font-size: 14px;
    font-weight: bold;
    color: black;
    margin-top: 20px;
    margin-left: 7px;
  }
  .navbar-menu {
    width: 100%;
    height: 5px;
    display: none;
  }
  .navbar-menu-container {
    display: none;
  }
  .wishlisticon {
    height: 15px;
    padding-bottom: 4px;
    color: black;
    -webkit-text-fill-color: black;
    -webkit-fill-color: black;
  }
  .carticon {
    height: 15px;
    padding-bottom: 3px;
    color: black;
    -webkit-text-fill-color: black;
    -webkit-fill-color: black;
  }
  .navbar__dropdown ul {
    list-style: none;
    position: relative;
    margin: 0;
    padding: 0;
    margin-top: 5px;
  }
  .navbar__dropdown li {
    font-size: 14px;
    font-weight: 500;
  }
  .navbar__dropdown a {
    display: block;
    padding: 5px;
    color: #333;
    text-decoration: none;
  }
  .navbar__dropdown a:hover {
    background-color: #f5f5f5;
  }
  .navbar__menu.open {
    display: block;
  }
  .sidebar {
    position: fixed;
    top: 20;
    left: 0;
    width: 75%;
    overflow-y: scroll;
    height: 100%;
    box-shadow: #00000059 0px 5px 15px;
    background-color: #fff;
  }
  .sidebar {
    display: none;
  }
  .open .sidebar {
    display: block;
  }
  .navbar-with-pink-strip {
    display: none;
  }
  .crossbuttonNavbar {
    display: flex;
    position: absolute;
    top: 7px;
    right: 10px;
  }
}
