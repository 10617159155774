.fetchingMoreOrdersDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #c4c4c4;
  height: 50px;
  margin: 5px;
}
.allTaxIncludedTxt {
  font-size: 10px;
  color: #b7abab;
  margin: 0px;
}
.leftAlignedTxt {
  display: flex;
  justify-content: end;
}
.listContainer {
  max-height: 600px;
  min-width: 320px;
  overflow-y: auto;
}
.listContainer::-webkit-scrollbar {
  width: 6px;
}
.listContainer::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.listContainer::-webkit-scrollbar-thumb {
  background: #e5e5e5;
  border-radius: 3px;
}
.listContainer::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.listContainer {
  scrollbar-width: thin;
  scrollbar-color: #e5e5e5 #f1f1f1;
}
.listContainer {
  scrollbar-track-color: #f1f1f1;
}
.listContainer {
  scrollbar-face-color: #e5e5e5;
}
.listContainer:hover {
  scrollbar-face-color: #555;
}
.pageListHeaderContainer {
  border-bottom: 1px solid #f5f5f5;
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 40px;
}
.OrderPageProductTitle {
  color: #515151;
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  word-wrap: break-word;
}
.OrderPageProductCategoryName {
  margin: 0;
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  color: #585858;
}
.PageListTitle {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #656565;
}
.removeTxtMargin {
  margin: 0;
}
.orderPageMobileViewOrderStatus {
  display: none;
}
.orderDetailsStatusTrackerHoler {
  padding: 0% 2.5%;
  padding-top: 10px;
  padding-bottom: 10px;
}
.orderPageBuyerOrderIdDiv {
  padding: 0 10px;
  width: 20%;
}
.orderPageBuyerOrderSummaryDiv {
  width: 20%;
  padding: 0 10px;
}
.orderPageBuyerPaymentSummaryDiv {
  width: 20%;
  padding: 0px 10px;
}
.orderDetailsContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.showHideOrderDetails {
  padding: 0px 10px;
}
.orderDetailsHolder {
  display: flex;
  width: 90%;
}
.orderPageOuterDiv {
  width: max-content;
  position: absolute;
  right: 20px;
  top: 5px;
}
.skeleton-loading {
  display: flex;
  position: relative;
  margin: auto;
  width: 100%;
  height: 100%;
  padding-top: 12px;
  margin-bottom: 10px;
  background-color: #ffffff;
}
.orderPageStatusContiner {
  display: flex;
  justify-content: center;
  align-items: center;
}
.css-1187icl {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.closePaymentSuccessModal {
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  background-color: #ea5e3d;
  color: white;
  height: 35px;
  font-size: 16px;
  font-weight: 400;
  margin: 15px 0px;
  border-radius: 2px;
  cursor: default;
}
.paymentSuccessModal-Heading-Popup {
  font-size: 32px;
  font-weight: 500;
  line-height: 45px;
  letter-spacing: 0em;
  text-align: center;
  color: #009359;
}
.paymentSuccessModal-List-tag {
  font-size: 32px;
  font-weight: 400;
  line-height: 40px;
  margin: 0;
  letter-spacing: 0em;
  text-align: center;
  list-style: none;
  color: #ea5e3d;
}
.paymentSuccessModal {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 700px;
  height: 416px;
  border-radius: 4px;
  border: 1px solid #c7c7c7;
  text-align: center;
  margin-top: 150px;
  margin-bottom: 100px;
  padding: 0% 5%;
}
.ordersPageMakePayment {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  font-size: 12px;
  height: 30px;
  width: 100px;
}
.ordersPageMakePaymentEnabled {
  background-color: #ea5e3d;
  cursor: pointer;
  color: white;
}
.ordersPageMakePaymentDisabled {
  background-color: #afafaf;
  opacity: 0.3;
  color: #000000;
  cursor: not-allowed;
}
.ordersPageMakePaymentHidden {
  display: none;
}
.ShopNowButtonOrder {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  width: 200px;
  height: 35px;
  border-radius: 2px;
  border: 2px;
  color: white;
  background-color: #ea5e3d;
}
.ShopNowButtonOrder:hover {
  background-color: #ff4c29;
  transform: scale(1.05);
}
.NoItemsOrders {
  text-align: center;
  padding: 20px;
  background-color: white;
}
.makePaymentModalPopupDetails {
  position: absolute;
  text-align: center;
  top: 380px;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  border-radius: 10px;
  width: 320px;
  height: 330px;
  z-index: 1;
  padding: 30px 30px;
}
.removeTextMargin {
  margin: 0;
  font-size: 14px;
  color: #545454;
  font-weight: 400;
}
.joyrejoy-coin-info{
  color: #8D8F92;
}
.joyrejoy-coin-info:hover{
  cursor: pointer;
  color: #545454;
}
.makePaymentModalPopupDetails-PayBtn {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 250px;
  width: 90%;
  align-items: center;
  background-color: #ea5e3d;
  color: #ffffff;
  border-radius: 2px;
  font-size: 14px;
  height: 35px;
  cursor: pointer;
}
.ordersListImageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
}
.makePaymentModalPopupDetails-container {
  display: flex;
  justify-content: space-between;
  margin: 10px 0px;
}
#JoyReJoyCoinsDiscountCoins {
  color: #ea5e3d;
}
.makePaymentModalPopupDetails-Title {
  font-size: 16px;
  font-weight: 500;
  color: #565656;
  margin: 0;
}
.buyerMainDivForOrderSection {
  display: flex;
  gap: 1%;
  font-size: 24px;

  height: 100%;
}
.AllOrderList {
  width: 74%;
  background-color: white;
  border-radius: 2px;
}
.buyerMainDivForHeadingOrderSectionallelements {
  display: flex;
  position: relative;
  height: max-content;
  padding-top: 15px;
  justify-content: space-between;
  background-color: #ffffff;
}
.loadingPara {
  text-align: center;
  padding-top: 40px;
}
.loadingTextDiv {
  background-color: #ffffff;
  font-size: 16px;
  font-weight: 500;
}
.orderPageImageAndNameDiv {
  display: flex;
  justify-content: flex-start;
  width: 50%;
}
.orderNameAndCategoryDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 70%;
}
.orderNumberandDeliveryDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;
}
.orderPageProductName {
  font-size: 11px;
  padding: 10px;
  font-weight: 550;
  color: #858383;
  width: 100%;
}
.orderCategoryNameParagaraph {
  font-size: 10px;
  padding: 10px;
  margin-top: -35px;
  color: #a0a0a0;
}
.buyerMainDivForOrderSectionPTag {
  font-size: 17px;
  padding: 11px;
  font-weight: 500;
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.buyerMainDivForOrderNumber {
  font-size: 11px;
  color: #b8b8b8;
}
.buyerMainDivForDeliveryDate {
  font-size: 11px;
  color: #b8b8b8;
}
.orderPageOrderNo {
  font-weight: 500;
  color: #585858;
  font-size: 11px;
}
.commonDiv {
  display: flex;
  gap: 10%;
  width: 50%;
  padding-bottom: 10px;
}
.commonDivMobile {
  display: none;
}
.orderPageFontAwsomeIcon {
  height: 13px;
  padding: 0px;
  color: #a6a6a6;
}
.buyerMainDivForOrderSectionImage {
  width: 80px;
  height: 100px;
  object-fit: contain;
}
.toggleButtonToShowMore {
  color: #343434;
  border: none;
  font-size: 12px;
  background-color: #ffffff;
  height: max-content;
  width: max-content;
  align-items: center;
}
.hr-container {
  display: flex;
  align-items: center;
  width: 92%;
  margin-left: 4%;
}
.hr-line {
  flex-grow: 1;
  height: 1px;
  border: none;
  margin: 0;
  padding: 0;
  border-bottom: dashed 1px #000000;
}
.orderPageBuyerAddressDiv {
  display: flex;
  flex-direction: column;
  width: 30%;
  padding: 0 10px;
}
.DeliveryAddressPara {
  font-size: 11px;
  color: #6d6d6d;
  margin: 0;
}
.orderPageDeliveryAddress {
  font-size: 12px;
  color: #6d6d6d;
  font-weight: 500;
  margin: 0;
  width: max-content;
  max-width: 100%;
  text-wrap: wrap;
}
.orderPageShowMoreDiv {
  height: 100%;
  display: flex;
  background-color: #ffffff;
}
.ordersSepratingLine {
  color: #8f8f8f;
  margin: 5px;
}
.OriginalAmount {
  font-size: 15px;
  margin: 0px;
  width: max-content;
}
.amountSymbol {
  font-size: 12px;
  color: #acacac;
}
.commonDivForMobile {
  display: none;
}
.toggleButtonToShowMoreForMobile {
  display: none;
}
.buyerMainOrderPage {
  font-size: 24px;
  max-width: 1450px;
  padding: 25px 0px;
  width: 90%;
}
.buyerMainDivForNavSectionOrderPage {
  width: 25%;
}
@media only screen and (min-width: 1300px) and (max-width: 1500px) {
  .skeleton-loading {
    height: 113px;
  }
  .commonDiv {
    margin-left: 10px;
  }
  .toggleButtonToShowMore {
    margin-right: 5px;
    border: none;
  }
  .orderPageFontAwsomeIcon {
    height: 12px;
    margin-left: 5px;
  }
  .orderPageBuyerAddressDiv {
    padding: 10px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1300px) {
  .buyerMainDivForOrderSectionImage {
    padding: 10px;
  }
  .orderPageProductName {
    font-size: 10px;
  }
  .toggleButtonToShowMore {
    margin-right: 0px;
    margin-right: 5px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1200px) {
  .buyerMainDivForHeadingOrderSectionallelements {
    height: max-content;
    padding: 0px;
    justify-content: center;
  }
  .orderDetailsHolder {
    flex-direction: column;
    padding-top: 10px;
  }
  .orderPageImageAndNameDiv {
    margin-top: 10px;
    width: 100%;
    gap: 10px;
  }  
  .ordersListImageContainer {
    justify-content: flex-start;
    width: 13%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1050px) {
  .commonDiv {
    width: 100%;
    gap: 0;
  }
  .commonDivMobile {
    display: none;
  }
  .ordersListImageContainer {
    width: 20%;
  }
  .orderNameAndCategoryDiv {
    width: 80%;
  }
  .buyerMainDivForOrderSectionPTag {
    align-items: center;
  }
  .orderNumberandDeliveryDiv {
    width: 80%;
    padding: 5px;
  }
  .buyerMainDivForOrderSectionImage {
    width: 80px;
    height: 100px;
  }
  .orderDetailsContainer {
    flex-direction: column;
    justify-content: space-around;
    width: 50%;
    padding-left: 10px;
  }
  .orderDetailsStatusTrackerHoler {
    display: flex;
  }
  .orderPageStatusContiner {
    width: 50%;
  }
  .DeliveryAddressParaDiv {
    margin-left: 10px;
    margin-top: 10px;
  }
  .orderPageDeliveryAddressDiv {
    margin-left: 10px;
  }
  .orderPageDeliveryAddress {
    max-width: 90%;
  }
}
@media only screen and (min-width: 800px) and (max-width: 1024px) {
  .orderDetailsContainer {
    width: 40% !important;
  }
  .orderPageStatusContiner {
    width: 60% !important;
  }
}
@media only screen and (min-width: 600px) and (max-width: 800px) {
  .orderDetailsContainer {
    width: 35% !important;
  }
  .orderPageStatusContiner {
    width: 65% !important;
  }
}
@media only screen and (max-width: 1050px) {
  .orderPageBuyerOrderIdDiv {
    width: 100%;
  }
  .orderPageBuyerAddressDiv {
    width: 100%;
  }
  .orderPageBuyerOrderSummaryDiv {
    width: 100%;
  }
  .orderPageBuyerPaymentSummaryDiv {
    width: 100%;
  }
}
@media only screen and (max-width: 600px) {
  .listContainer {
    max-height: fit-content;
    overflow: auto;
  }
  .stepsHolder {
    padding-left: 20px;
  }
  .statusUI {
    width: auto;
  }
  .pageListHeaderContainer {
    width: 100%;
    padding-left: 20px;
  }
  .orderPageOuterDiv {
    right: 0px;
    top: 0px;
  }
  .orderDetailsContainer {
    flex-direction: column;
  }
  .orderPageProductName {
    display: none;
  }
  .orderPageMobileViewOrderStatus {
    display: block;
  }
  .orderPageStatusContiner {
    width: 100%;
  }
  .orderPageShowMoreDiv {
    width: max-content;
  }
  .AllOrderList {
    min-height: 50vh;
    width: 100%;
  }
  .buyerMainDivForHeadingOrderSectionallelements {
    padding-left: 15px;
    padding-bottom: 10px;
  }
  .loadingTextDiv {
    height: max-content;
    margin-bottom: 30px;
  }
  .buyerMainDivForOrderSection {
    flex-direction: column;
    margin: 0;
    padding: 0;
    width: 100%;
  }
  .buyerMainDivForNavSectionOrderPage {
    display: none;
  }
  .buyerMainOrderPage {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
  }
  .commonDiv {
    display: none;
  }
  .commonDivMobile {
    display: block;
    margin: 0% 2.5%;
    padding-left: 25px;
    padding-bottom: 10px;
  }
  .buyerMainDivForOrderSectionPTag {
    display: none;
  }
  .ordersRow {
    margin-top: 10px;
  }
  .toggleButtonToShowMore {
    display: none;
  }
  .orderDetailsHolder {
    padding: 0;
  }
  .orderPageImageAndNameDiv {
    width: 100%;
  }
  .commonDivForMobile {
    display: flex;
    flex-direction: column;
  }
  .toggleButtonToShowMoreForMobile {
    display: flex;
    background-color: #ffffff;
    border: none;
    font-size: 12px;
    margin-top: 10px;
    padding-right: 15px;
  }
  .orderPageFontAwsomeIcon {
    height: 16px;
  }
  .buyerMainDivForDeliver {
    font-size: 12px;
    color: #7b7171;
    margin-left: 10px;
  }
  .buyerMainDivForOrderNumber1 {
    font-size: 12px;
    color: #7b7171;
  }
  .makePaymentModalPopupDetails {
    top: 100%;
    left: 50%;
    width: 100%;
    height: 600px;
    padding: 15px 15px;
  }
  .orderNameAndCategoryDiv {
    padding: 0;
    padding-left: 5px;
  }
  .orderPageOrderNo1 {
    font-weight: 500;
    color: #585858;
    font-size: 12px;
  }
  .DeliveryAddressParaDiv {
    margin-left: 13px;
    margin-top: 10px;
  }
  .DeliveryAddressPara {
    font-size: 12px;
  }
  .orderPageDeliveryAddressDiv {
    margin-left: 12px;
  }
  .orderPageDeliveryAddress {
    height: 100%;
  }
  .paymentSuccessModal {
    width: 90%;
    height: 250px;
    margin-left: 5%;
  }
  .paymentSuccessModal-Heading-Popup {
    font-size: 20px;
    line-height: 25px;
  }
  .paymentSuccessModal-List-tag {
    font-size: 20px;
    line-height: 25px;
  }
  .closePaymentSuccessModal {
    font-size: 12px;
  }
}
