.searchbarBackgroundDiv {
  background-color: transparent;
  position: absolute;
  z-index: 9;
  height: 100vh;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.search-icon-container {
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
}
.showMoreSearchResults {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 5px;
  background-color: white;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
.showMoreSearchResults p {
  margin: 0;
  cursor: pointer;
  color: #ea5e3d;
  font-size: 14px;
  font-weight: 500;
}
.showMoreSearchResults p:hover {
  text-decoration: underline;
}
.search-input-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 800px;
  width: 100%;
  height: 45px;
  margin: auto;
  position: relative;
  border-radius: 3px;
  background: white;
  z-index: 99;
}
.searchDropDown {
  margin-top: 10px;
  background: white;
  height: fit-content;
  position: relative;
  max-height: 350px;
  margin: auto;
  width: 100%;
  max-width: 800px;
  margin-top: 5px;
  border-radius: 3px;
  z-index: 99;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}
.searchDropDown::-webkit-scrollbar {
  width: 8px;
}
.searchDropDown::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.searchDropDown::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}
.searchDropDown::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.searchbarInputContainer {
  width: 100%;
  height: 100%;
  display: flex;
}
.dropdownItem {
  padding: 5px 0px;
  width: 100%;
  display: flex;
  gap: 10px;
  border-bottom: 1px solid #eee;
}
.dropdownItem:hover {
  cursor: pointer;
  background-color: #f0f0f0;
}
.imgContainer {
  padding: 5px;
  width: 50px;
  height: 50px;
}
.nameContainer {
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.highlight {
  background-color: yellow;
}
.sr-prod-name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  margin: 0;
}
.sr-prod-desc {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}
.dropdown-list {
  padding: 0;
  margin: 0;
}
.dropdown-list li {
  list-style: none;
}
.dropdown-list-NoProducts {
  background-color: white;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 50px;
  margin: auto;
  margin-top: 5px;
  padding: 5px;
  font-size: 14px;
  width: 100%;
  max-width: 800px;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}
.emptyList {
  text-align: center;
}
.searchbarContainer {
  width: 100%;
  margin: auto;
  height: 50px;
  justify-content: center;
}
.search-input {
  width: 100%;
  border-radius: 4px;
  padding: 0px 10px;
  font-size: 16px;
  outline: none;
  border: none;
}
.search-action {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  width: 50px;
  height: 100%;
  border-radius: 0px 4px 4px 0px;
}
.search-button,
.clear-button {
  padding: 10px 15px;
  outline: none;
  border: none;
  background: transparent;
}
.search-icon,
.clear-icon {
  color: #989898 !important;
  font-weight: 500;
  font-size: 18px;
}
.box-container {
  position: relative;
}
.search-action:hover {
  background-color: #eee;
}

.search_recent_items_list {
  padding: 5px;
  padding-left: 10px;
  border-bottom: 1px solid #eee;
}
.search_recent_items_list:hover {
  cursor: pointer;
  background-color: #f0f0f0;
}
@media only screen and (max-width: 1400px) {
  .search-input-container,
  .searchDropDown,
  .dropdown-list-NoProducts {
    max-width: 500px;
  }
}
@media only screen and (max-width: 1200px) {
  .search-input-container,
  .searchDropDown,
  .dropdown-list-NoProducts {
    max-width: 400px;
  }
}
@media only screen and (max-width: 1100px) {
  .search-input-container,
  .searchDropDown,
  .dropdown-list-NoProducts {
    max-width: 300px;
  }
}
@media only screen and (max-width: 600px) {
  .search-button {
    position: relative;

    right: 0;
  }
  .search-input-container {
    max-width: 100%;
    margin-top: 25px;
    z-index: 1;
    margin-top: 0;
  }

  .searchDropDown {
    max-height: 200px;
    margin-top: 5px;
    max-width: 100%;
    overflow-y: scroll;
    border-radius: 3px;
    max-height: max-content;
  }
  .dropdownItem {
    width: 100%;
    margin: auto;
    height: 50px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  }
  .imgContainer {
    width: 45px;
    height: 45px;
  }
  .dropdown-list-NoProducts {
    max-width: 100%;
    margin-top: 5px;
  }
}
