.blogSkeletonCard {
  padding: 5px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.blogSkeletonCard h3 {
  height: 20px;
  margin: 5px 0px;
}
.blog-details-content-skeleton h3 {
  height: 40px;
  margin: 10px 0px;
}
.blogSkeletonCard p {
  height: 16px;
  margin: 5px 0px;
}
.blog-details-content-skeleton p {
  height: 32px;
  margin: 10px 0px;
}
.blog-details-content-skeleton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.blog-details-description-skeleton {
  width: 100% !important;
  aspect-ratio: 2;
  margin: 10px 0px;
}
.blog-details-thumbnail-skeleton {
  width: 70% !important;
  aspect-ratio: 2;
}
.blogSkeleton-read-more {
  width: 50%;
  height: 12px;
}

.breadcrumb-area {
  margin: 50px 0px;
}
.breadcrumb-area h1 {
  font-size: 28px;
  font-weight: 600;
  color: #ea5e3d;
  text-align: center;
}
.breadcrumb {
  display: flex;
  justify-content: center;
}
.breadcrumb-item {
  color: #ea5e3d;
}
.breadcrumb-item:hover {
  cursor: pointer;
  text-decoration: underline;
}
.breadcrumb-item.active {
  color: #757575;
  cursor: initial;
  text-decoration: none !important;
}
.blog-container {
  margin-top: 130px;
}
.blog-list {
  max-width: 1500px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  gap: 45px;
  padding: 10px 0px;
}
.blog-details-container {
  margin: auto;
  max-width: 1024px;
  margin-top: 130px;
}
.blog-details-title {
  margin: 25px 0px 10px 0px;
  font-size: 28px;
}
.blog-details-author-line {
  display: flex;
  gap: 15px;
  margin-bottom: 25px;
  color: #757575;
}
.blog-details-author-line > span {
  display: flex;
  align-items: center;
  gap: 8px;
}
.author-icon,
.time-icon {
  font-size: 14px;
}
.blog-details-description {
  margin: 50px 0px 100px 0px;
}
.blog-details-navigation {
  margin: 50px 0px;
}
.navigation-links {
  padding: 10px 0px;
  display: flex;
  gap: 25px;
  justify-content: space-between;
}
.navigation-links div {
  width: 100%;
}
.navigation-links > div > p:first-child {
  margin: 0px;
  color: #757575;
}
.navigation-title {
  display: inline-block;
  margin-bottom: 5px;
  font-size: 22px;
  line-height: 30px;
  font-weight: 600;
}
.navigation-title:hover {
  cursor: pointer;
  color: #ea5e3d;
}
.navigation-links div:last-child {
  text-align: end;
}
.blog-card {
  text-align: left;
  overflow: hidden;
}
.image-wrapper {
  max-width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}
.image-wrapper img {
  width: 100%;
  height: auto;
  transition: transform 0.2s ease;
}
.image-wrapper img:hover {
  cursor: pointer;
  transform: scale(1.02);
}
.blog-card h3 {
  font-size: 22px;
  font-weight: 600;
  margin: 25px 0px;
}
.blog-title {
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 28px;
}
.blog-title:hover {
  cursor: pointer;
  color: #ea5e3d;
}
.blog-description {
  font-size: 16px;
  color: #757575;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.blog-read-more {
  margin-top: 10px;
  display: inline-block;
  color: #ea5e3d;
}
.blog-read-more:hover {
  cursor: pointer;
  color: #ea5e3d;
}
.read-more-arrow {
  font-size: 14px;
  margin: 0px 8px;
  transition: all 0.2s ease-in-out;
}
.blog-read-more:hover .read-more-arrow {
  transform: translateX(5px);
}

.blog-details-thumbnail {
  width: 100%;
  border-radius: 10px;
}
.related-blogs-title {
  font-size: 28px;
  font-weight: 600;
  color: #ea5e3d;
}
.related-blogs {
  padding: 15px 0px 50px 0px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  gap: 45px;
}
.blog-list-btn {
  margin: 25px 0px 50px 0px;
  text-align: center;
}

/* Media query start form here */
@media only screen and (max-width: 1024px) {
  .breadcrumb-area {
    margin: 25px 0px;
  }
  .blog-list {
    grid-template-columns: repeat(3, 1fr);
    gap: 35px;
  }
  .blog-details-description {
    margin: 50px 0px 50px 0px;
  }
  .related-blogs {
    grid-template-columns: repeat(2, 1fr);
    gap: 35px;
  }
}
@media only screen and (max-width: 768px) {
  .breadcrumb-area {
    margin: 25px 0px;
  }
  .blog-details-description {
    margin: 25px 0px 25px 0px;
  }
}
@media only screen and (max-width: 600px) {
  .blog-list {
    grid-template-columns: repeat(2, 1fr);
    gap: 25px;
  }
  .blog-details-container {
    margin-top: 110px;
  }
  .breadcrumb-area {
    margin: 10px 0px;
  }
  .navigation-links {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
  }
  .blog-list-btn {
    margin: 0px;
    margin-bottom: 50px;
  }
}
@media only screen and (max-width: 425px) {
  .blog-list {
    grid-template-columns: repeat(1, 1fr);
    gap: 25px;
  }
  .blog-details-author-line {
    flex-wrap: wrap;
    row-gap: 0px;
  }
  .related-blogs {
    grid-template-columns: repeat(1, 1fr);
    gap: 25px;
  }
}
