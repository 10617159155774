.buyerUserSideNavbarAnchor {
  display: flex;
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  color: black;
  background-color: white;
  text-align: left;
  padding: 10px;
  padding-left: 18px;
  border-right: 3px solid transparent;
  margin: 0;
  align-items: center;
  cursor: pointer;
  width: 100%;
}
#newAddressName {
  z-index: 999;
}
.buyerUserSideNavbarAnchor.active {
  color: #ea5e3d;
  background-color: #fdefec;
}
.mediasidenavhrline {
  margin-top: 7px;
  margin-bottom: 7px;
}
.BuyerASddresspageInfoLable {
  display: block;
  margin: 0.2rem 0rem;
  font-weight: 450;
  font-size: 14px;
  color: #999 !important;
  padding: 3px;
  font-size: 11px !important;
  position: absolute;
  top: -15px;
  background-color: white;
  left: 14px;
  transition: all 0.25s ease;
  pointer-events: none;
}
.addresspagebuttonDivAddCancel {
  display: flex;
  justify-content: end;
}
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}
.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.iconsForMobile {
  margin-top: -4px;
  margin-left: 4px;
}

.profileImage {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
.profileHelloName {
  color: #575757;
  margin-bottom: 0px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.profileNameofusers {
  color: #000000;
  /* width: 200px; */

  width: 100%;
  word-break: break-word;
  margin-bottom: 0px;
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  max-width: 100%;
  overflow: hidden;
}
.buyerUserTopProfileSideNavbarsection {
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: white;
  padding: 15px;
  border-radius: 4px;
  margin-bottom: 1px;
}
.namedetailsDiv {
  padding: 12px;
  padding-top: 7px;
}
.myorderline {
  border: none;
  margin-left: 12px;
  background-color: transparent;
  font-size: 18px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #555555;
  margin-left: 10px;
}
.myorderlineLogout {
  border: none;
  margin-left: 15px;
  background-color: transparent;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #7c7c7c;
}
.myorderline.active {
  color: #ea5e3d;
}
.ShowforDesktopView {
  display: block;
}
.ShowforMobileView {
  display: none;
}
.mobileDivContainerNavi {
  display: none;
}
#secondcartstyle {
  display: flex;
  justify-content: space-between;
}
.MobileNavigateLinkBox {
  padding: 15px;
  padding-left: 3px;
  display: flex;
  padding-top: 10px;
  align-items: center;
}
.FrequentlyVisitLink {
  font-size: 10px;
  font-weight: 500;
  line-height: 10px;
  letter-spacing: 0em;
  text-align: left;
  color: #555555;
}
.TrackOrderLink {
  font-size: 10px;
  font-weight: 400;
  line-height: 10px;
  letter-spacing: 0em;
  text-align: left;
  color: #989898;
  margin-left: 20px;
}
.helpCenterLink {
  font-size: 10px;
  font-weight: 400;
  line-height: 10px;
  letter-spacing: 0em;
  text-align: left;
  color: #989898;
  margin-left: 20px;
}
.fottersidenav {
  display: none;
}
.buyerMainAddressPage {
  font-size: 24px;
}
.subheadinginaddaddresspage {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  z-index: 1;
  position: relative;
  color: #5e5e5e;
}
.lableformakemakeDefault {
  display: flex;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #707070;
}
.buyerMainDivForProfileNamess {
  height: 50px;
}
.buyerHeadingMainAddressPage {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
  background-color: rgba(0, 0, 0, 0.2);
}

.buyerMainDivForAddressMainflexbox {
  display: grid;
  width: 98%;
  padding: 10px 0px;
  grid-template-columns: repeat(2, 1fr);
  gap: 2%;
  margin: 0;
}

.buyerMainDivForOrderSection ::-webkit-scrollbar {
  width: 8px;
  height: 5vh;
}
.buyerMainDivForOrderSection::-webkit-scrollbar-thumb {
  background-color: #4dabb0;
  border-radius: 5px;
}
.buyerMainDivForOrderSection::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* set the color of the track */
  color: #4dabb0;
}
.buyerMainDivForAddressMainTextareaflexbox {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 4%;
  margin-top: 5px;
  width: 100%;
  position: relative;
}

.BuyerAddressPageTextareaForaddress {
  width: 97.5%;
  color: #808080;
  min-height: 100px;
  max-height: 100px;
  margin: 0;
  font-size: 12px;
  padding: 12px;
  border-radius: 2px;
  border: 1px solid #dddddd;
  outline: 0;
}
.BuyerAddressPageTextareaForaddress:focus {
  background-color: #fff;
  border-color: #dddddd;
}
.addressmarkdefault {
  margin-left: 10px;
  text-align: center;
}
.InputCheckboxaddressPage {
  width: 17px;
  height: 17px;
  border: 1px solid #888888;
  border-radius: 3px;
  border: 1px;
}
.is-floating-label {
  /* position: relative;
  margin-bottom: 5px; */

  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  gap: 0;
}
.buyerAddressPageRadioSelection {
  line-height: 20px;
}
.DefaultCheckBoxDiv {
  display: flex;
  text-align: center;
  margin-top: 10px;
}
.BuyerAddressPageMainSubmitButton {
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  height: 49px;
  width: 100%;
  color: #ffffff;
  border-radius: 5px;
  background-color: #ea5e3d;
  border: 1px solid #ea5e3d;
}

.butttonforselectaddressType {
  border: 1px solid #949494;
  background-color: white;
  width: 50px;
  height: 20px;
  border-radius: 18.5px;
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: center;
  color: #949494;
}
.butttonforselectaddressType2 {
  border: 1px solid #949494;
  background-color: white;
  width: 50px;
  height: 20px;
  border-radius: 18.5px;
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: center;
  color: #949494;
  margin-left: 15px;
}
.selectedbutton1 {
  border: 1px solid #7ab7e5;
  background-color: #f6fafd;
  width: 50px;
  height: 20px;
  border-radius: 18.5px;
  margin-left: 15px;
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: center;
  color: #7ab7e5;
}
.selectedbutton {
  border: 1px solid #7ab7e5;
  background-color: #f6fafd;
  width: 50px;
  height: 20px;
  border-radius: 18.5px;
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: center;
  color: #7ab7e5;
}
.errorMessageDivAddress {
  display: flex;
  justify-content: space-between;
  height: 22px;
  margin-bottom: 5px;
}
.errorinputboxAddress {
  width: 100%;
  background-color: #fff;
  font-weight: 700;
  position: relative;
  font-size: 0.7rem;
  color: red;
  /* top: -4px; */
  height: 15px;
  margin: 0;
  padding: 0;
  border: none;
  -webkit-text-fill-color: red; /* For older iOS versions */
  -webkit-fill-color: red; /* For newer iOS versions */
}

.addresspagebuttonDivAddCancelUpdate {
  display: flex;
  justify-content: end;
}

.addaddressheadingcontainer {
  display: flex;
  justify-content: space-between;
}
.cancleaddressaddbutton {
  display: block;
  -webkit-padding-end: 20px;
  padding-right: 20px;
  border: none;
  margin-top: -9px;
  background-color: transparent;
}
.buyerMainDivForAddressDetailsSection {
  background-color: #fff;
  border-radius: 2px;
  height: max-content;
  max-height: 95%;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #f5f5f5 transparent;
  padding-bottom: 20px;
  width: 580px;
  padding: 10px;
  z-index: 999;
}
.addaddressHrLine {
  margin: 0;
  padding: 0;
  background: #d4d4d4;
}
.addNewAddressHeading {
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  color: #5e5e5e;
  text-align: left;
  -webkit-padding-end: 20px;
  -webkit-padding-start: 20px;
  padding-left: 20px;
  padding-right: 20px;
}
.popupContainer {
  margin: 0;
  padding: 0;
  padding-left: 10px;
  padding-bottom: 10px;
}
.buyerMainDivForNewAddressFormSectionDetails {
  padding: 10px;
}
.buyerMainDivForProfileLastName {
  margin-left: 20px;
}
.inputboxforerrormessageaddress {
  font-size: 0.75rem;
  width: 100%;
  background-color: #fff;
  font-weight: 600;
  position: relative;
  font-weight: bold;
  font-size: 0.7rem;
  color: red;
  top: -5px;
  margin-top: 40px;
  padding: 0;
  border: none;
  margin-left: 5px;
}
.mobileUIDiv {
  display: none;
}

@media only screen and (min-width: 600px) and (max-width: 1050px) {
  .myorderline {
    font-size: 12px;
    line-height: 14px;
  }
  .myorderlineLogout {
    font-size: 12px;
    line-height: 14px;
  }
  .profileHelloName {
    font-size: 12px;
    line-height: 14px;
  }
  .profileNameofusers {
    font-size: 14px;
    line-height: 16px;
  }
  .profileImage {
    width: 30px;
    height: 30px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 900px) {
  .buyerUserTopProfileSideNavbarsection {
    flex-direction: column;
  }
  .userProfileImageContainer {
    display: flex;
    justify-content: center;
  }
  
}
@media only screen and (max-width: 600px) {
  .fottersidenav {
    display: block;
  }
  .mobileDivContainerNavi {
    display: block;
  }
  .mainMobileContainer {
    background-color: #e3e6e6;
    height: fit-content;
  }
  .ShowforDesktopView {
    display: none;
  }
  .ShowforMobileView {
    padding-top: 90px;
    display: block;
    background-color: #e3e6e6;
  }
  .buyerUserTopProfileSideNavbarsection {
    border-radius: 0px;
  }
  .arrowSVG {
    right: 20px;
    float: right;
  }
  .MobileNavigateLinkBox {
    padding: 15px 20px;
    display: none;
  }
  .FrequentlyVisitLink {
    margin: 0;
  }
  .TrackOrderLink {
    margin: 0;
    margin-left: 20px;
  }
  .helpCenterLink {
    margin: 0;
  }
  .buyerUserSideNavbarAnchorMobile {
    display: flex;
    justify-content: space-between;
    text-decoration: none;
    font-size: 18px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    color: black;
    background-color: white;
    text-align: left;
    padding: 10px;
    padding-left: 15px;
    border-right: 3px solid transparent;
    margin: 0;
    align-items: center;
    cursor: pointer;
    width: 100%;
  }

  .buyerUserSideNavbarAnchorMobile.active {
    color: #ea5e3d;
    background-color: #fdefec;
    border-right: 3px solid;
  }
  .buyerUserSideNavbarAnchor {
    margin-bottom: 6px;
  }

  .buyerHeadingMainAddressPage {
    height: 100%;
  }

  .addNewAddressHeading {
    padding-left: 30px;
    margin-top: 10px;
  }
  .cancleaddressaddbuttonMobile {
    position: absolute;
    z-index: 9;
    top: 0;
    right: 10px;
    border: none;
    background-color: transparent;
  }
  .mobileUIDiv {
    background-color: #ea5e3d;
    display: block;
    text-align: center;
    margin: 0;
    padding: 8px 0px;
    padding-right: 8px;
    position: relative;
  }
  .MobileUIHeading {
    font-size: 20px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    color: white;
  }
  .buyerMainAddressPage {
    margin: 0px;
    width: 100%;
    margin-top: 100px;
  }
  .buyerMainDivForProfileNamePincode {
    height: 70px;
  }
  .is-floating-label {
    margin-top: 10px;
    margin-bottom: 0px;
  }
  .BuyerAddressPageTextareaForaddress {
    width: 96%;
  }

  .buyerMainDivForNewAddressFormSectionDetails {
    padding: 20px;
    height: max-content;
    padding-bottom: 100px;
  }
  .buyerMainDivForAddressMainflexbox {
    width: 95%;
    grid-template-columns: repeat(1, 1fr);
  }

  .buyerMainDivForProfileLastName {
    margin-left: 0px;
  }
  .buyerMainDivForNavProfileInfoSection {
    display: grid;
    width: 100%;
  }
  .buyerProfileInfoMainDivForNavSection {
    width: 100%;
    text-align: center;
    height: 200px;
    margin: 0px;
  }
  .inputboxforerrormessageaddress {
    top: 0px;
    -webkit-text-fill-color: #ff3300; /* For older iOS versions */
    -webkit-fill-color: #ff3300; /* For newer iOS versions */
  }

  .buyerMainDivForAddressSection {
    width: 100%;
    gap: 2%;
    border-radius: 5px;
    padding: 20px;
    height: max-content;
  }

  .buyerMainDivForAddressMainTextareaflexbox {
    margin: 0;
  }
  .buyerMainDivForAddressDetailsSection {
    border-radius: 5px;
    box-shadow: 0 8px 24px #959da533;
    height: 100vh;
    overflow: scroll;
    padding: 0;
    width: 100%;
  }
  .cancleaddressaddbutton {
    display: none;
  }
  .BuyerAddressPageMainSubmitButton {
    font-size: 14px;
    line-height: 17px;
    border: none;
    border-radius: 5px;
    width: 100%;
    margin-bottom: 30px;
  }
  .BuyerASddresspageInfoLable {
    display: inline-block;
    font-size: 0.85rem;
    color: #313131;
    font-weight: 400;
    margin: 0;
  }
  .buyerAddressPageRadioSelection {
    display: flex;
    margin-left: 0px;
  }
}
