.modal {
  position: fixed;
  z-index: 3;
  left: 0;
  top: 0px;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.25) !important;
}
.modal-buttons {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 15px;
}
.modal-content-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90vh;
}
.modal-content {
  background-color: #fefefe;
  margin: 200px auto;
  padding: 20px 25px 25px 25px;
  max-width: 550px;
  height: auto;
  border-radius: 4px;
  text-align: center;
}
.modal-header {
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 0;
}
.modal-header-title {
  padding: 0;
  margin: 0;
  color: #626262;
  font-size: 18px;
  font-weight: 500;
}
.img-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  color: #626262;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
}
.modal-body {
  text-align: left;
  min-height: 60px;
  margin: 10px 0px;
  padding: 0;
}
.modal-body p {
  font-size: 14px;
  color: #383838;
}
.modal-body li {
  font-size: 14px;
}
#deleteModal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}
.yes-button {
  width: 100%;
  color: white;
  background-color: #ea5e3d;
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 450;
}
.yes-button:hover {
  background-color: #ea5e3d;
  color: white;
}
.close {
  color: #aaa;
  float: right;
  font-size: 20px;
  padding: 5px;
  transition: all 0.2s;
}
.modal-left-arrow {
  color: #aaa;
  font-size: 20px;
  padding: 0 0 0px 0px;
  transition: all 0.2s;
}
.close:hover,
.modal-left-arrow:hover {
  color: #575757;
  text-decoration: none;
  cursor: pointer;
}
.modal-close-icon {
  padding: 6px 8px;
  font-size: 20px;
  color: #aaa;
  background-color: white;
  border-radius: 2px;
  border: 1px solid transparent;
  transition: all 0.2s;
}
.modal-close-icon:hover {
  cursor: pointer;
  background-color: #ededed;
}
.modal-close-icon:focus {
  border: 1px solid #74aef6;
  outline: 1px solid #74aef6;
}
.modal-info {
  font-size: 14px;
  color: gray;
}
.text-align-left {
  text-align: left;
}
.text-align-right {
  text-align: right;
}
.modal-new-email-input {
  position: relative;
}
.modal-email-edit,
.modal-phone-edit {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  color: #ea5e3d;
  font-weight: 450;
  height: 43px;
  width: 43px;
  top: 0;
  right: 0;
  margin-top: 1px;
  margin-right: 1px;
  border-radius: 0px 3px 3px 0px;
  border-left: 1px solid #ced4da;
  transition: all 0.2s;
}
.modal-email-edit:hover,
.modal-phone-edit:hover {
  cursor: pointer;
  background-color: #f0f0f0;
}
.margin-right-10 {
  margin-right: 10px;
}
.modal-oto-sent-info {
  font-size: 14px;
  padding: 6px 10px;
  border-radius: 4px;
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
  margin: 10px 0;
}
.modal-close {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 15px;
  top: 15px;
  font-size: 18px;
  padding: 8px 10px;
  border-radius: 4px;
  opacity: 0.4;
  color: black;
  transition: all 0.2s ease;
  width: 30px;
}
.modal-close:hover {
  opacity: 0.5;
  cursor: pointer;
  background-color: #dddddd;
}
.float-right {
  float: right;
}
@media screen and (max-width: 600px) {
  .modal {
    background-color: rgba(0, 0, 0, 0.05) !important;
  }
  .img-modal-content {
    max-height: fit-content;
  }
  .modal-content {
    position: absolute;
    bottom: 0px;
    padding: 10px 15px;
    width: 100%;
    max-width: 100%;
    margin: 0;
    border-radius: 10px 10px 0 0;
  }
  .modal-button button {
    padding: 10px;
    width: 100%;
  }
}
