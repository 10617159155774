.statusTrackerContainer {
  width: 100%;
}
#stepNameMobileView {
  display: none;
}
.statusTrackerContainer {
  display: flex;
  margin-bottom: 20px;
  margin-top: 10px;
  flex-direction: row;
  height: 60px;
  align-items: center;
  justify-content: flex-start;
}
.stepContainer {
  padding: 5px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90%;
}
.stepTxt {
  margin: 0;
  font-size: 12px;
  font-weight: 500;
  /* text-align: left; */

  /* width: 120px; */
  text-align: center;
  padding: 0px 10px;
}
.stepTimeStampTxt {
  margin: 0;
  font-size: 10px;
  font-weight: 400;
  padding: 0px 10px;
}
.stepTxtHolder {
  display: flex;
  justify-content: center;
  min-width: max-content;
  height: -webkit-fill-available;
  align-items: self-end;
}
.stepTxtHolderForTimeStamp {
  display: flex;
  justify-content: center;
  min-width: max-content;
  height: -webkit-fill-available;
}
.stepsHolder {
  width: 100%;
  height: 40%;
}
.orderStatusLeft {
  width: 48%;
}
.orderStatusRight {
  width: 48%;
}

@media only screen and (max-width: 1500px) {
  .stepTxt {
    text-align: left;
    width: auto;
  }
  .statusTrackerContainer {
    height: max-content;
    flex-direction: column;
  }
  #stepNameWebView {
    display: none;
  }
  #stepNameMobileView {
    display: block;
    /* width: max-content; */
    line-height: normal;
  }
  .orderStatusLeft {
    height: 22px;
    width: 2px;
  }
  .orderStatusRight {
    height: 22px;
    width: 2px;
  }
  .stepTxtHolder {
    display: block;
    width: 50%;
    padding: 5px;
  }
  .stepTxtHolderForTimeStamp {
    display: block;
    width: 50%;
    /* padding: 5px; */
    height: max-content;
  }
  .statusUI {
    width: 20%;
    flex-direction: column;
  }

  .stepContainer {
    width: 100%;
    margin: auto;
    padding: 0;
  }
  .stepsHolder {
    display: flex;
    justify-content: left;
    align-items: center;
  }
}
@media only screen and (max-width: 600px) {
  .stepTxt {
    width: 100%;
  }
  .stepTxtHolderForTimeStamp {
    width: 70%;
    padding-left: 30px;
  }
}
