.WalletSkeletonContainer {
  height: 100%;
}
.mywallet_Image_divSkeleton {
  background-color: #f5f5f5;
  width: 30%;
  height: auto;
}
.MyWalletSkeleton_Tabel_div_Container {
  height: 100%;
  width: 70%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.myWalletSkeletonTableHeading {
  height: 20px;
  width: 40%;
  background-color: #f5f5f5;
}
.myWalletSkeletonTable {
  background-color: #f5f5f5;
  height: 130px;
}
.myWalletSkeleton_EarnedCoin_Main {
  display: flex;
  gap: 20px;
  flex-direction: column;
}
.myWalletSkeletonWalletBalanceText {
  height: 20px;
  background-color: #f5f5f5;
}
.myWalletSkeletonWalletBalance {
  height: 20px;
  background-color: #f5f5f5;
}
.mywallet_Main_Container {
  font-size: 24px;
  padding: 25px 0;
  max-width: 1450px;
  /* margin-top: 110px; */
  width: 90%;
}
.myWallet_Flexbox_Container {
  display: flex;
  gap: 1%;
}
.mywallet_Box {
  display: flex;
  padding: 30px;
}
.mywallet_tr_main {
  padding-bottom: 5px;
  border-bottom: 1px solid #e3e3e3;
}
.MyWallet_Tabel_div_Container {
  width: 100% !important;
  margin-left: 5%;
  height: max-content;
}
.Wallet_Heading_transaction {
  font-size: 16px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #000;
}
.MyWallet_Tabel_div {
  width: 100% !important;
  border: 1px solid #e3e3e3;
  max-height: 300px;
  overflow-y: scroll;
  border-radius: 5px;
  padding: 0px 5px;
  scrollbar-width: thin;
  scrollbar-color: rgb(221, 221, 221) rgb(241, 241, 241);
  scrollbar-width: thin;
  scrollbar-color: rgb(221, 221, 221) rgb(241, 241, 241);
}
.MyWallet_Tabel_div::-webkit-scrollbar {
  width: 5px;
}
.MyWallet_Tabel_div::-webkit-scrollbar-thumb {
  background-color: darkgray;
}
.MyWallet_Tabel_div::-webkit-scrollbar-track {
  background-color: lightgray;
}
.MyWallet_Tabel_div::-moz-scrollbar {
  width: 5px;
}
.MyWallet_Tabel_div::-moz-scrollbar-thumb {
  background-color: darkgray;
}
.MyWallet_Tabel_div::-moz-scrollbar-track {
  background-color: lightgray;
}
.mywallet_Navigation_Container {
  width: 25%;
}
.mywallet_table_div {
  width: 100%;
}
.mywallet_Products_Container {
  width: 74%;
  border-radius: 5px;
  background-color: white;
}
.myWallet_EarnedCoin_Main {
  padding: 30px;
}
.mywallet_EarnedCoins_Container {
  width: 100%;
  padding: 0px;
  text-align: center;
  justify-content: center;
  border: 1px solid #e3e3e3;
}
.Mywallet_Earned_Paragraph {
  font-size: 32px;
  font-weight: 500;
  line-height: 39px;
  letter-spacing: 0em;
  color: #656565;
}
.myWallet_Line_coins {
  font-size: 36px;
  font-weight: 500;
  line-height: 44px;
  letter-spacing: 0em;
  color: #379d75;
  list-style: none;
}
.MyWallet_Earned_Coins {
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0em;
  color: #ffa500;
}
tr td {
  padding: 7px;
  text-align: center;
  font-size: 14px;
}
.mywalletcolorToCoinsAdd {
  color: #379d75;
}
.mywalletcolorToCoinsAsubst {
  color: #ea5e3d;
}
.myWallet_Heading_Tag {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  height: 45px;
}
/* @media only screen and (min-width: 700px) and (max-width: 1110px) {
  .mywallet_Products_Container {
    width: 100%;
  }
} */
@media only screen and (min-width: 600px) and (max-width: 1100px) {
  .mywallet_Box {
    display: block;
    padding: 15px 30px;
  }
  .myWallet_EarnedCoin_Main {
    padding: 15px 30px;
  }
  .mywallet_Image_div {
    display: flex;
    justify-content: center;
  }
  .MyWallet_Tabel_div_Container {
    margin: 0;
  }
  .mywallet_Image_divSkeleton {
    height: 120px;
    margin: auto;
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 600px) {
  .mywallet_Navigation_Container {
    display: none;
  }
  .mywallet_Products_Container {
    width: 100%;
    border-radius: 0px;
    height: max-content;
  }
  .mywallet_Box {
    display: grid;
    gap: 10px;
    padding: 5px;
  }
  .mywallet_Main_Container {
    width: 100%;
    padding: 0px;
    /* margin: 110px 0px 0px 0px; */
  }
  .MyWallet_Tabel_div_Container {
    width: 100% !important;
    margin-left: 0%;
    height: max-content;
    padding: 5px;
  }
  .MyWallet_Tabel_div {
    margin-left: 0;
    height: max-content;
  }
  .mywallet_Image_div {
    display: flex;
    justify-content: center;
  }
  .myWallet_EarnedCoin_Main {
    padding: 5px;
    border-radius: 5px;
  }
  .Mywallet_Earned_Paragraph {
    font-size: 16px;
    margin-bottom: 0px;
  }
  .myWallet_Line_coins {
    font-size: 25px;
  }
  .MyWallet_Earned_Coins {
    font-size: 20px;
  }
  .mywallet_Image_Container {
    width: 100%;
  }
  tr td {
    padding: 4px;
    text-align: center;
    font-size: 12px;
  }
  .mywallet_Image_divSkeleton {
    height: 120px;
    margin: auto;
  }
}
