.pageParentDiv {
  margin-top: 110px;
  min-height: 65vh;
}
.pageParentDivColoredBackground {
  background-color: #f5f5f5 !important;
}
.search-input-container-vendorShop {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.vendorShopNameBanner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  width: 90%;
  margin: auto;
  font-size: 26px;
  background: #ffe8e5;
  color: #ea5e3d;
  font-weight: 600;
}
#venderProductListContainer {
  margin-top: 20px !important;
}
.VenderShopName {
  font-family: Quicksand !important;
  color: black;
  font-weight: 500;
}
.selectedSort {
  border-bottom: 1px solid #b1b1b1;
  padding-bottom: 5px;
  width: 135px;
  display: flex;
  justify-content: space-between;
}
.sortByOptions {
  text-align: center;
}
.sortByOptionsHolder {
  padding: 2px 10px;
  color: #000000;
}
.sortByOptionsHolder:hover {
  background-color: #f5f5f5;
}
.sortOptionsContainer {
  position: absolute;
  right: 0;
  top: 35px;
  border-radius: 5px;
  z-index: 2;
  width: fit-content;
  background-color: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.loading-more {
  display: flex;
  justify-content: center;
  font-size: 14px;
  font-weight: 400;
  margin: 20px;
  animation: pulse 1s infinite alternate;
}
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}
.css-wf16b5 {
  width: 100% !important;
}
.filterCheckboxLabel {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 1rem;

  width: max-content;

  text-wrap: nowrap;
  overflow: hidden;
}
.filterCheckboxBox {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 18px;
  width: 18px;
  border: 2px solid #ffa500;
  border-radius: 2px;
}
.filterCheckboxBox.checked {
  background-color: #ffa500; /* Background color when checked */
}
.filter-product-age {
  margin-bottom: 10px;
}
.filter-para-tag {
  margin: 0;
  font-weight: 500;
}
.sort-by-container-d1 {
  display: flex;
}
.sort-by-c1-d1-c1 {
  padding-right: 10px;
  width: max-content;
}
.infinity-scrol-c1 {
  scrollbar-width: none;
}
.Brand_Filter_head {
  height: 30px;
}
.filterHolder {
  position: relative;
  width: 100%;
  color: white;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #ffffff;
}
.filterHolder:last-child {
  border-bottom: 1px solid #edebef;
}
.enableBrandSearchFilter {
  cursor: pointer;
}
.sk-product-list {
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 25px;
  width: 100%;
  gap: 20px;
  max-width: 1250px;
}
.sk-product-row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 15px;
}
.sk-product-card {
  padding: 10px;
  height: fit-content;
  width: 100%;
  border: 1px solid #dddbdd;
  border-radius: 4px;
}
.sk-prod-img {
  background-color: #dddbdd;
  width: 100%;
  aspect-ratio: 1;
  margin: auto;
}
.sk-prod-desc {
  padding: 20px 0px;
}
.sk-prod-selling-price {
  margin-top: 5px;
  height: 20px;
  width: 30%;
  background-color: #dddbdd;
}
.sk-prod-original-price {
  height: 20px;
  width: 60%;
  background-color: #dddbdd;
  margin-top: 15px;
}
.sk-prod-name {
  height: 20px;
  width: 100%;
  background-color: #dddbdd;
  margin-top: 15px;
}
.sk-prod-buttons {
  height: 35px;
  width: 100%;
  background-color: #dddbdd;
}
.loadingTextOnListPage {
  text-align: center;
  margin-top: 20%;
}
#webUIBottomFilterContainer {
  border-bottom: 1px solid #c3c3c3;
}
.showMoreFilterDiv {
  width: 100%;
  height: max-content;
  margin-top: 10px;
}
#sortByOptionsInProductList {
  width: max-content;
  top: 0;
  left: 0;
  font-size: 16px;
  position: relative;
  color: #595959;
}
.OriginalPriceSpanTagProductList {
  position: relative;
}
.filterSearchbar {
  background-color: #f5f5f6;
  border-radius: 20px;
}
.filterSearchbarIputContainer {
  width: 85%;
  padding-left: 10px;
}
.mobileUIPriceOffContainer {
  display: flex;
  flex-direction: row;
  width: 90%;
  justify-content: flex-start;
  align-items: center;
}
.mobileUIPriceOffText {
  margin: 0;
  font-size: 12px;
  color: #379d75;
  font-weight: 500;
}
.OriginalPriceSpanTagProductList::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 45%;
  width: 100%;
  border-bottom: 1.5px solid #969696;
}
#bottomFilterContainer {
  border-bottom: 1px solid #c3c3c3;
}
.showMoreFiltertext {
  padding-left: 30px;
  color: #ea5e3d;
  font-weight: 400;
  margin: 0;
  cursor: pointer;
}
.FilterContainerHolder {
  width: 20%;
  padding-right: 15px;
  position: relative;
}
.FilterSearchbarContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.crossbuttonCategoryFilter {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15%;
  cursor: pointer;
}
.crossbuttonCategoryFilter :focus {
  outline: none;
}
.crossbuttonBrandsFilter:focus {
  outline: none;
}
.filterBrandsName {
  border: none;
  width: 100%;
  padding: 5px 0px;
  background-color: transparent;
}
.filterBrandsName:focus {
  outline: none;
}
.filterCategoryName {
  border: none;
  width: 100%;
  padding: 5px 0px;
  background-color: transparent;
}
.filterCategoryName:focus {
  outline: none;
}
.select-label {
  width: max-content;
}
.sticky {
  position: sticky !important;
}
.filterChipHolder {
  width: 75%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.filterChip {
  width: max-content;
  height: 25px;
  border-radius: 12.5px;
  border: 1px solid #afafaf;
  padding: 0px;
  margin: 5px;
  display: flex;
  align-items: center;
}
.fliterChipText {
  margin: 0;
  font-size: 14px;
  color: #595959;
}
.fliterChipTextContainer {
  padding: 0 10px;
  width: max-content;
}
.fliterChipRemoveBtnContainer {
  padding-right: 10px;
  height: 100%;
  padding-left: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.select-dropdown-container {
  display: flex;
  position: relative;
  right: 0;
  top: 0;
  /* border: 1px solid #b1b1b1; */
  width: max-content;
  padding: 5px 10px;
  height: 35px;
  color: #595959;
  font-weight: 400;
  cursor: pointer;
}
.select-dropdown {
  padding: 0px;
  padding-left: 5px;
  padding-right: 10px;
  color: #595959;
  border: none;
  cursor: pointer;
}
.loader-cursor {
  cursor: wait;
  height: 800px;
  margin-top: 90px;
}
.homepageContainer {
  padding-top: 10px;
  min-height: 65vh;
  position: relative;
}
.homepageContainer ::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}
.homepageContainer::-webkit-scrollbar-thumb {
  background-color: #ea5e3d;
  border-radius: 5px;
}
.homepageContainer::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  color: #ea5e3d;
}
.ProductListContainer {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  display: flex;
  min-height: 600px;
  padding-bottom: 15px;
}
.banner-containerVendonShop {
  width: 100% !important;
  max-width: 1250px !important;
}
.FilterContainer {
  width: 100%;
  position: sticky;
}

#clearFilterBtnContainer {
  text-align: center;
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: space-between;
}
.filter-container-sort {
  position: relative;
  display: flex;
  justify-content: space-between;
  background-color: white;
  height: max-content;
  padding-bottom: 10px;
  bottom: 0;
  width: 100%;
  min-height: 45px;
}
.filterHolder::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}
.filterHolder::-webkit-scrollbar-thumb {
  background-color: #ea5e3d;
  border-radius: 5px;
}
.filterHolder::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  color: #ea5e3d;
}
.NoProductsshowMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.NoProductsAvailableImgContainer {
  height: 500px;
}
.listNameNoPro {
  list-style: none;
  font-weight: 600;
}
.product-container {
  width: 80%;
  margin: 0px auto;
}
.product-list {
  width: fit-content;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  gap: 15px;
}
.filter-values-sort {
  margin-top: 10px;
  max-height: 350px;
  overflow-y: hidden;
  overflow-x: hidden;
  position: absolute;
  transition: height 1s ease;
  background-color: #fff;
  z-index: 8;
  padding: 15px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.formControlClass {
  margin: 0 !important;
}
.clearFilterBtn {
  margin: 0;
  color: #ea5e3d;
  font-weight: 500;
  float: left;
  font-size: 14px;
  border: none;
  background-color: white;
  width: 100px;
  padding: 2px;
}
.HeadlinesForMobileView {
  display: none;
}
.FilterContainerMobile {
  display: none;
}
.allsortname {
  pointer-events: none;
}
.home-Page-skeleton-view {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: "0% 5%";
}
.fa-filter-icon {
  color: "#d6d6d6";
}
.filter-values {
  transition: "max-height 1s ease-in";
}
#product-list-end {
  margin-top: 50px;
  position: absolute;
  width: 100%;
  bottom: 0;
}
.fa-x-mark-icons {
  color: rgb(157 153 153);
  width: 18px;
  height: 18px;
}
.radio-primary {
  color: #989898 !important; /* Default color */
}

.radio-primary.checked {
  color: #ea5e3d !important; /* Color when checked */
}

.fa-check-icons-product {
  color: #ffffff !important;
  height: 15px !important;
  width: 15px !important;
}
.input-checkobox-op {
  opacity: 0 !important;
}
.clearFilterBtnContainer-c1.fade-in {
  opacity: 1;
}
.clearFilterBtnContainer-c1.fade-out {
  opacity: 0;
}
.image-container-list {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
/* Media Query Start from Here */
@media only screen and (max-width: 1500px) {
  .ProductListContainer {
    max-width: 1200px;
    min-width: auto;
    width: 90%;
  }
  .banner-containerVendonShop {
    max-width: 1200px !important;
    min-width: auto !important;
    width: 90% !important;
  }
}
@media only screen and (min-width: 1050px) and (max-width: 1350px) {
  .product-container {
    padding-left: 10px;
    width: 80%;
  }
  .FilterContainerHolder {
    width: 20%;
    min-width: 210px;
  }
}
@media only screen and (max-width: 1050px) {
  .homepageContainer {
    padding-bottom: 100px;
  }
  #drawerSelectedBtn {
    font-weight: 600;
  }
  .mobileUISortBtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px;
    padding-left: 20px;
  }
  .filter-container-sort {
    display: none;
  }
  .FilterContainerMobile {
    background-color: #fff;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 48px;
    padding: 2px 0;
    position: fixed;
    z-index: 999;
    left: 0;
    right: 0;
    transition: all 0.4s cubic-bezier(0.65, 0.05, 0.36, 1);
    bottom: 0;
    border: 1px solid yellowgreen;
  }
  .FilterContainerHolder {
    display: none;
  }
  .product-container {
    padding: 0px;
    width: 100%;
  }
  .selectedFilterMobileUI {
    width: 100%;
    background-color: #fdefec;
  }
  .Applyfilterdiv {
    background-color: #ea5e3d;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    width: 95%;
    cursor: pointer;
  }
  .FilterContainerMobile-bottom-element {
    width: 99%;
    height: 50px;
    background-color: #ffffff;
    padding: 10px;
    position: relative;
    border: 1px solid palegreen;
  }
  .mobileUIFilterHeaderTxt {
    margin: 0;
    font-weight: 500;
  }
  .filterHeaderContainer {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #f5f5f5;
    position: relative;
    justify-content: space-between;
    padding: 10px;
    height: 5%;
    align-items: center;
  }
  .filterContainerMobileUI {
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;
  }
  .filterNameHolderMobileUI {
    width: 35%;
    height: 100%;
    border-right: 1px solid #f5f5f5;
  }
  .filterOptionsHolderMobileUI {
    height: 100%;
    width: 65%;
    position: absolute;
    align-items: center;
    right: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
  }
  #clearAllFilterMobileUI {
    color: #ea5e3d;
    font-size: 13px;
    font-weight: 500;
    cursor: pointer;
  }
  .crossbuttonSort {
    display: flex;
    position: absolute;
    top: 8px;
    right: 15px;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
  }
  .formControlClass {
    margin: 0 !important;
    display: flex;
    flex-direction: column;
  }
  .buttondiv {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: 15%;
    min-height: 50px;
    bottom: 0;
    width: 100%;
    padding: 10px 0px;
  }
  .selectedFilterContainerMobileUI {
    background-color: #ea5e3d;
    overflow: hidden;
  }
  .filter-valuesMediaQuery {
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    transition: "max-height 1s ease-in";
  }
  .FilterSearchbarContainer {
    width: 100%;
    border-radius: 5px;
    padding: 0 2%;
    margin: 8px 0px;
    border: 1px solid #f5f5f5;
  }
  .FilterSearchbarContainerHolder {
    justify-content: center;
    align-items: center;
    display: flex;
    width: 95%;
  }
  .filterSearchbarInputContainer {
    width: 90%;
  }
}
@media only screen and (max-width: 768px) {
  .product-list {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .sk-product-row {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1050px) {
  .ProductListContainer {
    width: 95%;
  }
  .banner-containerVendonShop {
    width: 95% !important;
  }
  .crossbuttonCategoryFilter {
    width: 10%;
    justify-content: flex-end;
  }
  .crossbuttonBrandsFilter {
    width: 10%;
    display: flex;
    justify-content: flex-end;
    cursor: "pointer";
  }
  .FilterContainerMediaQuery {
    border-bottom: 1px solid #f5f5f5;
    height: 80%;
  }
  .filter-valuesMediaQuery {
    padding: 0px 20px;
  }
}
@media only screen and (max-width: 600px) {
  .sk-product-list {
    padding: 0;
  }
  .filterBrandsName {
    font-size: 14px;
    padding: 5px;
  }
  .filterCheckboxLabel {
    font-size: 14px;
    width: max-content !important;
  }
  .pageParentDiv {
    margin-top: 90px;
    min-height: 50vh;
  }
  .sk-product-row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .sk-prod-img {
    height: auto;
    width: 85%;
  }
  .sk-product-card {
    /* height: 345px; */
  }
  /* .mobileUISortBtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px;
    padding-left: 20px;
  } */
  .mobileUISortBtnIcon {
    width: 5%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .mobileUISortBtnText {
    width: 70%;
    height: 100%;
    display: flex;
    justify-content: left;
    padding-left: 10px;
    align-items: center;
    font-size: 16px;
  }
  .mobileUISortBtnCheckbox {
    width: 15%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .sort-buttons {
    display: grid;
    margin-bottom: 25px;
  }
  .homepageContainer {
    display: grid;
    width: 100%;
    overflow: hidden;
    margin-bottom: 0;
    padding: 0px;
  }
  .sortBtnsHeaderTxt {
    margin: 0;
    color: #4b4b4b;
    font-size: 20px;
  }
  .buttondiv {
    background-color: white;
    border-top: 1px solid #f5f5f5;
  }
  .FilterContainer {
    display: none;
  }
  .FilterContainerMediaQuery {
    width: 100%;
    display: grid;
    position: relative;
    background-color: unset;
    height: 100%;
  }
  #clearFilterBtnContainer {
    margin-top: 0px;
    padding: 30px 10px;
    background-color: white;
  }
  .filter-container {
    width: 100%;
  }
  .filter-valuesMediaQuery {
    width: 90%;
    overflow-x: hidden;
    background-color: unset;
    margin-top: 3px;
    position: relative;
    background: unset;
    left: 10px;
    max-height: calc(100vh - 90px) !important;
    overflow: scroll !important;
    top: 0;
  }
  .ProductListContainer {
    width: 100%;
    margin: 0px;
    display: block;
    position: relative;
    overflow: hidden;
  }
  .banner-containerVendonShop {
    width: 95% !important;
  }
  .product-list {
    width: 98%;
    grid-template-columns: 1fr 1fr;
    gap: 5px;
    margin: 5px auto;
    padding-bottom: 55px;
  }
  .sk-product-row {
    width: 98%;
    grid-template-columns: 1fr 1fr;
    gap: 0px;
    margin: 5px auto;
  }
  .HeadlinesForMobileView {
    display: inline;
    width: 100%;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    color: #939090;
    box-shadow: 0.5px 0.5px 5px 0.5px #939090;
  }
  .filterContainerMobileUI {
    height: 83%;
  }
  .FilterSearchbarContainer {
    padding: 0;
    margin: 5px;
  }
}
