#loginMobilePageNavbar {
  display: none;
}

.tabIndexBtn{
  background-color: transparent !important;
  border: none !important;
}
.openPolicyDocsForLoginModal {
  color: #3366cc;
  cursor: pointer;
}
.openPolicyDocsForLoginModal:hover {
  text-decoration: underline;
}
.inputTagLabel {
  color: #a3a3a3;
  font-size: 15px;
}
.resetPasswordResendOTP {
  display: flex;
  position: absolute;
  right: 0;
  /* bottom: -5px; */
  font-size: 12px;
  text-decoration: none;
  color: #3366cc;
}
.loginModalForgotPasswordHolder {
  padding: 1px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.showReferralCode-txt {
  margin: 0;
  font-size: 12px;
  color: #3366cc;
  text-decoration: none;
}
.showReferralCode-txt:hover {
  cursor: pointer;
  text-decoration: underline;
}
.clearFilterBtnContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: -45px;
}
.TermsConditionsTextHolder {
  position: absolute;
  bottom: 5px;
  width: 100%;
}
.Login_ModalContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}
.Login_ModalContent {
  position: relative;
  display: flex;
  background-color: transparent;
  width: auto;
  height: auto;
  padding: 20px;
  border-radius: 10px;
}
#loginSignupContinueBtn {
  margin: 0;
}
#BuyerLoginResendOTPBtn {
  color: #3366cc;
  float: right;
  font-size: 14px;
  padding: 0;
  /* margin-top: -15px; */
}
.Terms_Conditions_Text {
  margin: 0;
  color: #535353;
  font-size: 12px;
  padding: 15px 0px;
  text-align: center;
}
.modelImageContainer {
  width: 450px;
  height: auto;
  min-height: 500px;
  /* padding: 15px; */
  background-color: #fdefec;
  border-radius: 5px;
}
.modelImageContainerbox {
  padding: 20px 20px 20px 40px;
  margin: 25px 0px 50px 0px;
  background-color: #fdefec;
  display: none;
}
.modelInputFieldContainer {
  width: 350px;
  padding: 15px 15px 15px 0px;
  padding-bottom: 0;
  position: relative;
}
.modelImageContainerbox-txt {
  margin: 0px;
}
.modelImageContainer-heading {
  font-size: 18px;
  font-weight: 600;
  color: #525252;
}
.modelImageContainer-description {
  font-size: 14px;
  font-weight: 400;
  color: #575757;
  width: 250px;
  height: 45px;
  margin-top: 15px;
}
.modelImageContainer-image {
  height: 123px;
  width: 165px;
  margin: auto;
  margin-top: 60px;
  margin-bottom: 40px;
}
.modelImageContainer-imageContainer-Img {
  width: 200px;
  height: 100%;
}
.modelImageContainer-imageContainer {
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.required {
  color: red;
}
.resendOTPBtn {
  font-size: 14px;
  margin: 0;
}
.showReferralCode-Div {
  margin-top: 15px;
}
.forgot-password-requirements {
  color: #3366cc;
  font-size: 12px;
  text-decoration: none;
  /* margin-top: -8px; */
  padding-bottom: 10px;
  position: relative;
  display: flex;
  justify-content: end;
  width: fit-content;
}
.forgot-password-requirements:hover {
  cursor: pointer;
}
.forgot-password-requirements:hover .forgot-tooltip {
  display: block;
}
.forgot-tooltip {
  display: none;
  font-size: 12px;
  position: absolute;
  top: 100%;
  margin-top: -5px;
  left: 0;
  width: 200px;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 1;
}
.forgot-tooltip ul {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: left;
}
.forgot-tooltip li {
  margin-bottom: 5px;
}
.password-requirements {
  color: #3366cc;
  font-size: 12px;
  text-decoration: none;
  margin-top: -8px;
  padding-bottom: 10px;
  position: relative;
  float: right;
}
.password-requirements:hover {
  cursor: pointer;
}
.password-requirements:hover .signup-tooltip {
  display: block;
}
.signup-tooltip {
  display: none;
  font-size: 13px;
  position: absolute;
  top: -110px;
  margin-top: -5px;
  right: 0;
  width: 200px;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 1;
}
.signup-tooltip ul {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: left;
}
.signup-tooltip li {
  margin-bottom: 5px;
}
.BuyerLoginPage-footer {
  margin-top: 20px;
}
.BuyerLoginPage-innerBox {
  width: 500px;
  height: max-content;
  background-color: #fff;
  border-radius: 5px;
  margin-top: 20px;
  padding: 15px;
  display: flex;
}
.BuyerLoginPage-innerBox-expanded {
  width: 100%;
  height: max-content;
  background-color: #fff;
  border-radius: 2px;
  /* margin-top: 20px; */
  display: flex;
}
.password-eye-icons {
  height: 41px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: 3px;
  right: 0px;
  font-size: 1rem;
  border-radius: 4px;
}
.password-eye-icons-password {
  height: 40px;
  width: 45px;
  color: #ea5e3d;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: 3px;
  right: 55px;
}
.login-cross-icons {
  border: none;
  background: none;
}
.containerBox {
  padding: 60px 5px 60px 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.CloseLoginModal {
  display: flex;
  position: absolute;
  right: 10px;
  top: 10px;
  padding: 5px 10px;
}
.CloseLoginModal:hover {
  cursor: pointer;
}
.CloseLoginModal:hover .cross-icon {
  color: #8a8a8a;
}
.cross-icon {
  color: #9e9e9eb7;
}
.cross-icon:hover {
  color: #8a8a8ab7;
  cursor: pointer;
}
@media only screen and (min-width: 600px) and (max-width: 1100px) {
  #BuyerLoginResendOTPBtn {
    margin: 0;
  }
}
@media only screen and (min-width: 600px) and (max-width: 900px) {
  .Login_ModalContent {
    width: 100%;
  }
  .modelImageContainer {
    width: 50%;
  }
  .modelInputFieldContainer {
    width: 50%;
  }
  .modelImageContainer-description {
    width: 100%;
  }
}
@media only screen and (max-width: 600px) {
  .modelImageContainer-image {
    margin: 0px;
    margin-top: 50px;
    margin-bottom: 0;
    padding: 0;
    width: 100%;
    height: 200px;
  }
  .modelImageContainerbox {
    display: block;

    margin: 0px;
    padding: 0px;
    height: 350px;
  }
  .BuyerLoginPage-footer-LoginResetPass-Mobile {
    margin-top: 0px;
  }

  .Login_ModalContainer {
    background-color: white;
  }
  .Login_ModalContent {
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 0;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;
    border-radius: 2px;
  }
  .Login_ModalContent::-webkit-scrollbar {
    display: none;
  }
  .loginMobilePageNavbar {
    width: 100%;
    background: #ea5e3d;
    display: flex;
    align-items: center;
    min-height: 45px;
    padding-bottom: 5px;
  }
  .containerBox {
    padding: 20px;
  }
  .BuyerLoginPage-innerBox {
    width: 100%;
    margin-top: 100px;
    margin-left: 2%;
    padding: 0;
  }
  .password-eye-icons {
    width: 45px;
    color: #ea5e3d;
    border-radius: 0px 5px 5px 0px;
    top: 2px;
    right: 0px;
  }
  .CloseLoginModal {
    display: none;
  }
  #BuyerLoginResendOTPBtn {
    font-size: 12px;
  }
  .BuyerLoginPage-innerBox-expanded {
    height: 100%;
    position: relative;
    border-radius: 5px;
    margin: 0;
    display: flex;
    flex-direction: column;
    padding: 25px 0px;
  }
  .TermsConditionsTextHolder {
    position: relative;
  }
  .TermsConditionsTextHolderMobileUI {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    position: relative;
  }
  .modelInputFieldContainer {
    width: 100%;
    padding: 10px;
  }
  .modelImageContainer {
    display: none;
  }
  .crossbuttonNavbar {
    display: flex;
    position: absolute;
    top: 5px;
    right: 5px;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    color: white !important;
  }
  .modelImageContainer-description {
    width: 90%;
  }
}

@media only screen and (max-height: 500px) {
  .Login_ModalContainer {
    align-items: flex-start;
    overflow: auto;
  }
  .containerBox {
    padding: 20px !important;
  }
  .modelImageContainerbox {
    padding: 0px !important;
  }
  .CloseLoginModal {
    top: 0px !important;
    right: 0px !important;
  }
}
