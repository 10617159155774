.orderStatusSecondaryTextWeb {
  display: block;
}
.orderStatusSecondaryTextMobile {
  display: none;
}
.orderStatusRadioBtnOuter {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.orderStatusRadioBtnInner {
  height: 6px;
  width: 6px;
  border-radius: 50%;
  /* background-color: #ffffff; */
}
.orderStatusContainer {
  margin-bottom: 10px;
}
.orderStatusPrimaryContainer {
  display: flex;
  align-items: center;
}
.orderStatusPrimaryText {
  margin: 0;
  font-size: 15px;
  line-height: 17px;
  font-weight: 500;
  color: #585858;
}
.orderStatusSecondaryText {
  margin: 0;
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  color: #585858;
}
.orderStatusRadioBtnContainer {
  display: block;
  margin: 5px;
  margin-left: 0;
}

@media only screen and (max-width: 600px) {
  .orderStatusRadioBtnContainer {
    display: none;
  }

  .orderStatusSecondaryTextWeb {
    display: none;
  }
  .orderStatusSecondaryTextMobile {
    display: block;
  }
}
