.serviceUnavailableDivContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 65vh;
  margin: 0px 0;
  justify-content: center;
  margin-top: 120px;
}
.serviceUnavailableP {
  margin: 5px 0px;
  color: rgba(84, 84, 84, 0.65);
  font-weight: 400;
  font-size: 25px;
  line-height: 30px;
}
.serviceUnavailablePageContainer {
  border-radius: 2px;
  width: 80%;
  padding: 25px 10px;
  padding-top: 50px;
  margin: 25px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 500px;
}
.serviceUnavailableNavigateToHomeBtn {
  width: 300px;
  display: flex;
  height: 35px;
  cursor: pointer;
  justify-content: center;
  border-radius: 2px;
  padding: 5px 10px;
  margin-top: 40px;
  font-size: 14px;
  font-weight: 400;
  background-color: #ea5e3d;
  color: white;
  border: none;
}

.serviceUnavailablePageImageContainer {
  width: 480px;
  height: 400px;
  margin: 50px 0px;
}
.imgForServiceUnavailablePage {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.ErrorMsgForServiceUnavailablePage {
  color: #ea5e3d;
  font-size: 40px;
  margin: 0;
  margin-bottom: 50px;
}

.serviceUnavailablePageDescriptionTxt-second {
  margin-top: 30px;
}

@media only screen and (max-width: 600px) {
  .serviceUnavailablePageContainer {
    min-height: 400px;
    margin: 0;
    padding: 0;
  }
  .serviceUnavailableNavigateToHomeBtn {
    width: 200px;
    height: 30px;
  }
  .ErrorMsgForServiceUnavailablePage {
    font-size: 25px;
    margin-bottom: 25px;
  }
  .serviceUnavailableP {
    font-size: 16px;
    width: 200px;
    text-align: center;
  }
  .serviceUnavailablePageImageContainer {
    width: 140px;
    height: 120px;
    margin: 25px 0px;
  }
}
