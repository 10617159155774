.collections {
  min-height: 5px;
}
.loadingCategory_swiper .swiper-button-next,
.loadingCategory_swiper .swiper-button-prev,
.loadingCategory_swiper .swiper-pagination {
  display: none;
  /* transition: opacity 0.01s ease; */
}

/*  */
.recentlyViewedContainer,
.similarProductsContainer {
  padding: 20px 0px;
}
.bannerSkeleton {
  height: 200px !important;
}
.collectionSkeleton {
  height: 350px !important;
}
.categorySkeleton {
  height: 275px !important;
}
.collectionSkeletonMobile {
  display: none !important;
}
.categorySliderImages {
  height: auto;
}
.slick-slide {
  padding: 2px !important;
}
.mobile-banner {
  display: none !important;
}
.category_swiper .swiper-pagination-bullet {
  background-color: #ff9900;
}
.banner-container {
  max-width: 1500px;
  height: fit-content;
  margin: 0 auto;
  border-radius: 5px;
  padding-top: 20px;
}
.slider img {
  height: auto;
  max-width: 1500px;
  width: 100%;
  border-radius: 5px;
}
.slider img:hover {
  cursor: pointer;
}
.slick-list {
  position: relative;
}
.slider .slick-dots {
  width: fit-content;
  margin: 0 auto;
  height: 15px;
  bottom: 30px;
  position: relative;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  transform: translateX(-50%);
  background: #ffffff52 0% 0% no-repeat padding-box;
  border-radius: 20px;
}
.slider .slick-dots li {
  font-size: 6px;
  margin: 0px;
  padding: 0px;
}
.slider .slick-dots li button:before {
  line-height: 0px;
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  color: black;
  z-index: 1;
  font-size: 10px;
}
.slider .slick-dots li.slick-active button:before {
  opacity: 1;
  color: #ffffff;
}
.story-slider .slick-dots li {
  bottom: -15px;
}
.story-slider .slick-dots li button:before {
  height: 10px;
  width: 10px;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  color: black;
  z-index: 1;
  font-size: 10px;
  border-radius: 10px;
  background-color: #565656;
}
.story-slider .slick-dots li.slick-active button:before {
  height: 10px;
  width: 20px;
  border-radius: 10px;
  background-color: #ea5e3d;
}
.slick-next,
.slick-prev {
  display: flex;
  font-size: 0;
  line-height: 0;
  top: 50%;
  width: 0px !important;
  height: 0px !important;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: 0;
  background: 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  margin-top: -10px;
}
.slick-prev {
  left: 18px !important;
}
.slick-next {
  right: 18px !important;
}
.slick-next:before,
.slick-prev:before {
  font-size: 40px;
  line-height: 1;
  opacity: 0.75;
  color: rgba(0, 0, 0, 0.39);
}
.body-container {
  max-width: 1500px;
  margin: 0 auto;
  height: fit-content;
  /* padding-bottom: 20px; */
}
.category-collection {
  padding: 10px 0px;
}
.category-collection img {
  width: 100%;
}
.category-collection .slick-track > div {
  padding: 0px 10px;
}
.category-collection .slick-track > div:hover {
  cursor: pointer;
}
.category-collection h1 {
  font-size: 16px;
  text-align: center;
  margin-top: 10px;
  width: 100%;
}
.rolling-svg-icon {
  margin: 0;
  width: 22px;
  height: 22px;
}
.prod-image {
  display: flex;
  justify-content: start;
  align-items: center;
  overflow: hidden;
  max-height: 250px;
  width: 100%;
  max-width: 250px;
  aspect-ratio: 1/1;
  border-radius: 4px 4px 0px 0px;
}
.prod-image img {
  transition: transform 0.2s ease;
}
.prod-image img:hover {
  cursor: pointer;
  transform: scale(1.02);
}
.prod-condition-ribbon {
  position: absolute;
  padding: 0px 4px;
  left: 0;
  top: 15px;
  display: inline-block;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  z-index: 1;
  line-height: 16px;
  color: white;
}
.prod-condition-ribbon::after {
  content: "";
  position: absolute;
  top: 0;
  left: 100%;
  width: 0;
  height: 0;
  border-color: var(--border-color);
  border-style: solid;
  border-width: 8px;
}
.prod-desc {
  padding: 0px 5px;
  margin: 15px 0px;
}
.prod-selling-price {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  font-size: 16px;
  font-weight: 500;
}
.prod-selling-price .offer-discount {
  font-size: 14px;
  color: #379d75;
  margin-left: 10px;
}
.prod-estimated-price {
  font-size: 12px;
  font-weight: 400;
  margin: 0;
  color: #868686;
}
.prod-estimated-price span {
  white-space: nowrap;
  text-decoration: line-through;
}
.prod-title {
  max-width: 240px;
  font-size: 14px;
  font-weight: 500;
  margin: 10px 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.collection-heading-row {
  display: flex;
  gap: 25px;
  align-items: center;
}
.collection-heading {
  margin: 30px 0px;
  white-space: nowrap;
  font-size: 20px !important;
  font-weight: 700;
  text-align: left !important;
}
.square-collection-heading {
  margin: 0px 0px 30px 0px;
  white-space: nowrap;
  font-size: 20px;
  font-weight: 700;
}
.collection-hr {
  border-top: 1px solid #e6e6e6;
  width: 100%;
}
.brands-conditions-square-row {
  overflow: hidden;
  margin: 25px 0px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px;
}
.brands-conditions-square-row > div {
  border-radius: 5px;
}
.brand-collections,
.conditions-collections,
.age-collections {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}
.conditions-collections div .age-collections div .brand-collections div {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-size: 22px;
}
.brand-collections img,
.conditions-collections img,
.age-collections img {
  width: 100%;
  height: 100%;
  aspect-ratio: 1 / 1;
  border-radius: 4px;
}
.category_swiper .swiper-wrapper {
  margin-bottom: 25px;
  width: fit-content;
}
.my_swiper .swiper-button-next,
.my_swiper .swiper-button-prev {
  background: rgba(0, 0, 0, 0.39);
  border-radius: 50%;
  height: 35px;
  width: 35px;
  margin-top: -60px;
  color: white;
}
.category_swiper .swiper-button-next,
.category_swiper .swiper-button-prev {
  background: rgba(0, 0, 0, 0.39);
  border-radius: 50%;
  height: 35px;
  width: 35px;
  margin-top: -50px;
  color: white;
}
.story-swiper .swiper-button-next,
.story-swiper .swiper-button-prev {
  margin-top: -30px;
}
.category_swiper .swiper-button-next {
  right: 0px;
}
.category_swiper .swiper-button-prev {
  left: 0px;
}
.my_swiper .swiper-button-next {
  right: 0px;
}
.my_swiper .swiper-button-prev {
  left: 0px;
}
.my_swiper .swiper-button-next:after,
.my_swiper .swiper-button-prev:after,
.category_swiper .swiper-button-next:after,
.category_swiper .swiper-button-prev:after {
  font-size: 20px;
  font-weight: 900;
}
.my_swiper .swiper-button-prev.swiper-button-disabled,
.my_swiper .swiper-button-next.swiper-button-disabled,
.category_swiper .swiper-button-prev.swiper-button-disabled,
.category_swiper .swiper-button-next.swiper-button-disabled {
  opacity: 0;
}
.seller-story-container {
  max-width: 1500px;
  margin: 80px auto;
  padding: 45px;
  height: fit-content;
  background-color: #f0f6fb;
  border-radius: 10px;
}
.story-heading {
  text-align: center;
}
.seller-story {
  margin: 0px 15px;
  padding: 35px;
  border-radius: 4px;
  background-color: white;
}
.seller-story-container > div {
  width: 100%;
}
.all-story-btn {
  padding: 8px 12px;
  background-color: white;
  border-radius: 4px;
  transition: all 0.2s;
  font-size: 16px;
  font-weight: 600;
  color: #696969;
  outline: none;
  border: 1px solid #ea5e3d;
}
.all-story-btn:hover {
  border: 1px solid #ea5e3d;
  background-color: #ea5e3d;
  color: white;
  border-radius: 4px;
}
.all-blogs-btn {
  padding: 8px 12px;
  background-color: white;
  border-radius: 4px;
  transition: all 0.2s;
  font-size: 16px;
  font-weight: 600;
  color: #696969;
  border: 1px solid #ea5e3d;
}
.all-blogs-btn:hover {
  border: 1px solid #ea5e3d;
  background-color: #ea5e3d;
  color: white;
  border-radius: 4px;
}
.seller-story-line {
  margin-bottom: 15px;
}
.story-slider {
  margin-top: 45px;
}
.seller-name {
  font-size: 16px;
  font-weight: 600;
}
.seller-shop-name {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 600;
}
.seller-story img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}
.seller-story-image {
  display: flex;
  gap: 15px;
  align-items: center;
}
.blog-and-news-container {
  max-width: 1500px;
  margin: 50px auto 0px;
  text-align: center;
}
.blogs-line {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  gap: 45px;
  margin: 55px 0px;
}
.top-brands .collection-hr,
.shop-by-conditions .collection-hr,
.shop-by-age .collection-hr {
  display: none;
}
.mobile-square-collection {
  display: none;
}
.square-swiper .swiper {
  width: 100%;
  height: 100%;
}
.square-swiper .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.square-swiper .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}
.square-swiper .swiper-slide {
  width: 70%;
}

/*==================== MEDIA QUERIES STARTS====================*/
@media only screen and (max-width: 1500px) {
  .banner-container,
  .body-container,
  .blog-and-news-container {
    max-width: 1200px;
    width: 90%;
  }
  .seller-story-container {
    max-width: 1200px;
  }
}
@media only screen and (max-width: 1024px) {
  .blogs-line {
    gap: 25px;
  }
  .banner-container,
  .body-container,
  .blog-and-news-container {
    width: 95%;
  }
  .brands-conditions-square-row {
    margin-top: 25px;
    gap: 15px;
  }
  .brand-collections,
  .conditions-collections,
  .age-collections {
    gap: 15px;
  }
}
@media only screen and (max-width: 768px) {
  .seller-story-container {
    padding: 45px 15px;
  }
  .top-brands .collection-hr,
  .shop-by-conditions .collection-hr,
  .shop-by-age .collection-hr {
    display: block;
  }
  .square-collection-heading {
    margin: 15px 0px;
    white-space: nowrap;
    font-size: 20px;
    font-weight: 700;
  }
  .brands-conditions-square-row {
    margin: 0px;
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
  }
  .brand-collections,
  .conditions-collections,
  .age-collections {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
  }
  .blogs-line {
    grid-template-columns: repeat(2, 1fr);
    gap: 25px;
    margin: 25px 0px;
  }

  .testimonialContainer {
    margin: 50px auto;
  }
  .all-story-btn {
    display: none;
  }
  .banner-left-arrow,
  .banner-right-arrow,
  .my_swiper .swiper-button-next,
  .my_swiper .swiper-button-prev,
  .category_swiper .swiper-button-next,
  .category_swiper .swiper-button-prev {
    display: none !important;
  }
  .slider .slick-dots {
    height: 15px;
    width: fit-content;
    margin-left: 50%;
  }
  .slider .slick-dots li button:before {
    font-size: 8px;
  }
  .category-collection h1 {
    font-size: 14px;
  }
  .single-category {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .collection-heading {
    font-size: 15px !important;
  }
}
@media only screen and (max-width: 600px) {
  .story-slider {
    margin-top: 30px;
  }
  .web-banner {
    display: none !important;
  }

  .collectionSkeleton {
    display: none !important;
  }
  .collectionSkeletonMobile {
    display: block !important;
    height: 50px !important;
    margin: 1px auto;
  }
  .mobile-banner {
    display: block !important;
  }
  .body-container,
  .blog-and-news-container {
    margin: 10px auto;
  }
  .body-container {
    padding: 0px;
  }
  .banner-container {
    margin: 0px auto;
    padding-top: 10px;
  }
  .testimonialContainer {
    margin: 25px auto;
  }
  .collection-heading-row {
    padding: 10px 0px 10px !important;
  }
  .brands-conditions-square-row > div {
    padding: 0px;
  }
  .square-collection-heading {
    margin: 10px 0px;
    font-size: 15px;
  }
  .category-heading {
    margin: 0;
    font-size: 15px;
    padding: 10px 0px;
  }
  .collection-heading {
    margin: 10px 0px;
    font-size: 15px;
  }
  .prod-selling-price .offer-discount {
    margin-left: 5px;
  }
  .prod-card .prod-image {
    border-radius: 0px;
  }
  .prod-title {
    margin: 5px 0px;
  }
}
@media only screen and (max-width: 425px) {
  .prod-condition-ribbon {
    padding: 0px 2px;
    font-size: 11px;
  }
  .seller-story {
    margin: 0px;
    padding: 20px;
  }
  .collection-heading-row {
    padding: 0px;
  }
  .brands-conditions-square-row {
    gap: 0px;
  }

  .mobile-square-collection {
    display: block;
  }
  .blogs-line {
    grid-template-columns: repeat(1, 1fr);
    gap: 25px;
  }
  .brand-collections,
  .conditions-collections,
  .age-collections {
    gap: 10px;
  }
}
@media only screen and (max-width: 375px) {
  .prod-condition-ribbon {
    font-size: 10px;
  }
  .seller-story-container {
    border-radius: 4px;
    width: 100%;
  }
}
