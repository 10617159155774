.skeleton-loading-forHome {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 100%;
  margin-top: 128px;
  gap: 50px;
}
.skeleton-HomePageColumn1 {
  width: 100%;
  height: 215px;
  border-radius: 5px;
  background-color: #dddbdd !important;
}
.skeleton-HomePageColumn2 {
  height: 200px;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: space-between;
  margin-top: 50px;
}
.skeleton-HomePageColumn2ForMobile {
  display: none;
}
.skeleton-circleRow {
  height: 180px;
  /* width: 180px; */
  border-radius: 5px;
  background-color: #dddbdd !important;
}
.collectionImageContainer {
  border-radius: 5px;
}
.skeleton-HomePageColumn3 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 25px;
  margin-top: 50px;
}
.skeleton-HomePageColumn3-InnerContainer {
  height: 400px;
  width: 100%;
}
.skeleton-HomePageColumn3-InnerContainer-GridImplimentation {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 20px;
}
.skeleton-HomePageColumn3-InnerBox {
  height: 167px;
  width: 100%;
  border-radius: 5px;
  background-color: #dddbdd !important;
}
/*==================== MEDIA QUERIES STARTS====================*/

@media only screen and (max-width: 600px) {
  .skeleton-HomePageColumn1 {
    height: 190px;
  }
  .skeleton-HomePageColumn2 {
    display: none;
  }
  .skeleton-HomePageColumn2ForMobile {
    height: 200px;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-top: 50px;
  }
  .skeleton-circleRow {
    height: 134px;
    margin-top: -10px;
    width: 180px;
  }
  .skeleton-HomePageColumn3 {
    flex-direction: column;
    margin-top: -50px;
  }
}
