.Account-Container {
  align-items: center;
  width: 100%;
  justify-content: center;
  display: flex;
}
.Account-Delete-Container {
  background-color: white;
  padding: 10px;
  width: 700px;
  height: 416px;
  border-radius: 4px;
  border: 1px solid #c7c7c7;
  text-align: center;
  margin-top: 200px;
  margin-bottom: 100px;
}
.Account-Delete-SVG-box {
  margin-top: 150px;
}
.Account-Delete-Heading-Popup {
  font-size: 32px;
  font-weight: 500;
  line-height: 39px;
  letter-spacing: 0em;
  text-align: center;
  color: #009359;
}
.Account-Delete-List-tag {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  list-style: none;
  color: #777777;
}
@media only screen and (max-width: 750px) {
  .Account-Delete-Container {
    width: 90%;
    height: 250px;
    margin-top: 150px;
  }
  .Account-Delete-SVG-box {
    margin-top: 50px;
  }
  .Account-Delete-Heading-Popup {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
  }
  .Account-Delete-List-tag {
    font-size: 12px;
    line-height: 15px;
  }
}
