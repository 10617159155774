.BuyerLoginPage-footer .BuyerLoginPage-error {
  font-size: 0.75rem;
  font-weight: 500;
  margin-top: 15px;
  margin-bottom: 10px;
  color: red;
  line-height: 0.85rem;
}
.forgotpassword {
  list-style: none;
  color: #0d0866;
  text-decoration: underline;
  text-align: end;
  margin-top: -11px;
  font-size: 12px;
}
.tabFocus {
  border: none;
  border-radius: 5px;
  font-size: 16px;
  width: 100%;
  height: 40px;
  cursor: pointer;
  color: #fff;
  background-color: #ea5e3d;
}
.BuyerLoginPage-footer button:disabled {
  background-color: gray !important;
}
.BuyerLoginPage-footer p span a {
  font-weight: bold;
  color: #0d0866;
  letter-spacing: 1px;
  text-decoration: none;
}
.BuyerLogin-SignUpBtn1 {
  font-size: 0.75rem;
  margin: 0;
  padding: 0;
}
.BuyerLogin-SignUpBtn1:hover {
  cursor: pointer;
  text-decoration: underline;
}
.BuyerLogin-signup-resetPassword {
  font-size: 15px;
}
.BuyerLogin-signup-resetPassword span {
  display: inline-block;
}

#BuyerLogin-signup-resetPassword-resetBtn {
  float: right;
  font-size: 14px;
  padding: 0;
}
.fontassowmicon {
  margin-left: 110px;
  height: 20px;
  outline: none;
}
.password-line {
  position: relative;
  margin-top: 10px;
}

@media only screen and (min-width: 601px) and (max-width: 1100px) {
  .password-line {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .BuyerLogin-SignUpBtn1 {
    font-size: 13px;
    color: #0d0866;
    text-align: end;
  }
}
@media only screen and (max-width: 600px) {
  .BuyerLogin-SignUpBtn1 {
    font-size: 13px;
    color: #3366cc;
  }

  .BuyerLoginPage-innerBox {
    width: 250px;
    height: 200px;
    background-color: #fff;
    padding: 15px;
    border-radius: 5px;
    box-shadow: #63636333 0px 2px 8px 0px;
    margin-top: 60px;
  }
  .BuyerLoginPage-footer button {
    outline: none;
    border: none;
    border-radius: 2px;
    font-size: 14px;
    line-height: 16px;
    height: 35px;
    cursor: pointer;
    color: #fff;
    background-color: #ea5e3d;
  }
  #BuyerLogin-signup-resetPassword-resetBtn {
    font-size: 12px;
  }
}
