.productCardRupeeSymbol {
  font-size: 12px;
  color: #ababab;
  display: flex;
  align-items: baseline;
}
.prod-card-container {
  position: relative;
  margin: auto;
}
.prod-card {
  position: relative;
  height: fit-content;
  width: fit-content;
  box-shadow: none;
  cursor: pointer;
  border: 1px solid #e6e6e6;
  background-color: white;
  border-radius: 4px;
}
.card-hover {
  display: none;
}
.cta-button {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.add-to-cart-button {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 500;
  height: 35px;
  color: white;
  background-color: #ea5e3d;
  border: 1px solid transparent;
  outline: none;
  border-radius: 0px 0px 4px 0px;
  transition: all 0.2s ease;
}
.add-to-cart-button:hover {
  cursor: pointer;
  background-color: #c95136;
  border: 1px solid #c95136;
}
.add-to-cart-button:focus {
  border: 1px solid #c95136;
  outline: 1px solid #c95136;
}
.wishlist-button {
  font-weight: 500;
  height: 35px;
  width: 30%;
  cursor: pointer;
  /* outline: none; */
  border: none;
  border-top: 1px solid#e6e6e6;
  border-bottom: 1px solid transparent;
  background-color: white;
  border-radius: 0px 0px 0px 4px;
}
.wishlist-button:hover .empty-heart {
  transform: scale(1.08);
  transition: transform 0.1s ease;
}
@media only screen and (max-width: 768px) {
  .card-hover {
    display: block;
  }
  .prod-card {
    height: fit-content;
  }
}
@media only screen and (max-width: 600px) {
  .cta-button {
    padding: 0px 5px 5px 5px;
    gap: 5px;
  }
  .wishlist-button {
    border: 1px solid #ea5e3d;
    width: 30%;
    border-radius: 4px;
  }
  .add-to-cart-button {
    width: 69%;
    border-radius: 4px;
  }
}
