.ql-toolbar{
    display: none;
    border: none;
}
.ql-container{
    border: none;
}
.ql-container.ql-snow {
    border: none;
}
.ql-editor{
    padding-left: 0;
    font-size: 14px;
}
/* #ProductDescription{
    max-width: 500px;
} */