.address-list {
  list-style: none;
  padding: 0;
}
.address {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}
.address-info {
  flex-grow: 1;
}
.addresstypediv {
  width: 100%;
  margin-top: 15px;
}
.actionDivAddressList {
  width: 10%;
}
.delete-confirm,
.delete-cancel {
  margin-top: 10px;
}
.delete-button-edit {
  border: none;
  background: none;
  font-size: 24px;
  color: #fff;
  border: none;
  cursor: pointer;
}

.delete-button-edit:hover {
  color: #007bff;
}

.delete-button {
  border: none;
  background: none;
  font-size: 24px;
  color: #ccc;
  cursor: pointer;
  margin-left: 10px;
}
.delete-button:hover {
  color: #007bff;
}

.buyerMainDivForAddressSection {
  width: 74%;
  background-color: white;
}
.addressSkeletonAddAddressBtn {
  height: 35px;
  border-radius: 2px;
  background-color: #f5f5f5;
  width: 100px;
}
.addressListSkeleton {
  height: 100px;
  display: flex;
  border-bottom: 1px solid #f5f5f5;
}
.addressListSkeleton-AddressDetails {
  padding: 10px 20px;
  display: flex;
  width: 90%;
  flex-direction: column;
  gap: 10px;
}
.addressListSkeleton-AddressDetailsEdit {
  display: flex;
  flex-direction: column;
  gap: 2px;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 5%;
}
.addressListSkeleton-AddressDetailsEdit div {
  background-color: #f5f5f5;
  height: 5px;
  border-radius: 50%;
  width: 5px;
}
.addressListSkeleton-AddressDetails-Type {
  border-radius: 5px;
  background-color: #f5f5f5;
  height: 20px;
  padding: 10px;
  width: 30%;
}
.addressListSkeleton-AddressDetails-primary {
  border-radius: 5px;
  height: 20px;
  background-color: #f5f5f5;
  width: 50%;
}
.addressListSkeleton-AddressDetails-secondary {
  border-radius: 5px;
  height: 15px;
  width: 50%;
  background-color: #f5f5f5;
}
.addressSkeletonListContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

@media only screen and (max-width: 600px) {
  .buyerMainDivForAddressDetailsSection {
    height: 100%;
    max-height: 100%;
  }
  .actionDivAddressList {
    width: 100%;
  }
  .buyerMainDivForAddressSection {
    width: 100%;
  }
  .address {
    flex-direction: column;
  }
  .address-info {
    margin-bottom: 10px;
  }
  .delete-button {
    margin-left: auto;
  }
  .BuyerAddressListContainerSkeleton {
    margin-top: 20px;
  }
  .addressListSkeleton {
    flex-direction: column;
    height: 135px;
  }
  .addressListSkeleton-AddressDetailsEdit {
    height: 30px;
    padding: 5px 20px;
    align-items: flex-start;
    width: 100%;
  }
  .addressListSkeleton-AddressDetailsEdit div {
    height: 30px;
    width: 50px;
    border-radius: 0;
  }
  .addressListSkeleton-AddressDetailsEdit-a {
    display: block;
    height: 20px;
    border-radius: 2px;
    width: 20%;
    background-color: #ccc;
  }
  .addressListSkeleton-AddressDetailsEdit-b {
    display: none;
  }
  .addressListSkeleton-AddressDetailsEdit-c {
    display: none;
  }
}
