.skeleton-loading-forWishlist {
  display: flex;
  flex-direction: row;
  margin: auto;
  width: 100%;
  justify-content: space-between;
  padding-top: 10px;
  padding-left: 21px;
  padding-right: 21px;
  margin-bottom: 10px;
  background-color: #ffffff;
}
#confirmRemoveFromWishlistModal {
  border: 1px solid #ea5e3d;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 500;
  height: 35px;
  letter-spacing: 0;
  line-height: 17px;
  background-color: #fff;
  color: #ea5e3d;
  width: 100px;
}
.wishlist-pera-tag {
  margin-top: 15px;
}
.skeleton-Wishlistrow1 {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.skeleton-Wishlistrow11 {
  background-color: #f5f5f5;
  height: 80px;
  width: 70px;
}
.skeleton-Wishlistrow12 {
  display: flex;
  flex-direction: column;
}
.skeleton-WishlistColumn1 {
  height: 13px;
  width: 200px;
  background-color: #f5f5f5;
}
.skeleton-WishlistColumn2 {
  width: 150px;
  height: 13px;
  background-color: #f5f5f5;
  margin-top: 5px;
}
.skeleton-WishlistColumn3 {
  width: 100px;
  height: 13px;
  background-color: #f5f5f5;
  margin-top: 18px;
}
.skeleton-WishlistColumn4 {
  width: 140px;
  height: 13px;
  background-color: #f5f5f5;
  margin-top: 5px;
}
.skeleton-Wishlistrow2 {
  display: flex;
  flex-direction: column;
}
.skeleton-WishlistColumn21 {
  height: 20px;
  width: 44px;
  background-color: #f5f5f5;
  margin-left: 38%;
}
.skeleton-WishlistColumn22 {
  height: 26px;
  width: 85px;
  background-color: #f5f5f5;
  margin-top: 30px;
}
.skeleton-hr-forWishlist {
  color: #e1e2e1;
}
.wishListRemoveItemMobileDiv {
  display: none;
}
.Wishlist_buyerMainDivForNavSection {
  width: 25%;
}
.Wishlist_soldOutdiv {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  margin-top: 7px;
}
.Wishlist_ShopNowButtonWishlist {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  width: 200px;
  height: 35px;
  border-radius: 2px;
  border: 2px;
  color: white;
  background-color: #ea5e3d;
}
.myWallet_Flexbox_Container {
  display: flex;
}
.ShopNowButtonWishlist {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  width: 200px;
  height: 35px;
  border-radius: 2px;
  border: 2px;
  color: white;
  background-color: #ea5e3d;
}
#Wishlist-checkout-button-Container-removeFormWishlist {
  display: flex;
  justify-content: end;
}
.wishlist-image-details {
  display: flex;
}
.Wishlist_offpricewishlist {
  color: #379d75;
  font-size: 10.5px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: -0.05em;
  text-align: left;
}
.Wishlist_soldOutLabel {
  background-color: #fff;
  color: rgb(219, 15, 15);
  font-weight: 500;
  border-radius: 4px;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 9px;
  border: none;
}
.Wishlist_buyerMainWishlistPage {
  font-size: 24px;
  padding: 25px 0px;
  max-width: 1450px;
  width: 90%;
  /* margin-top: 110px; */
}
.Wishlist_buyerMainDivForNavWishlistSection {
  display: flex;
  gap: 1%;
  font-size: 24px;
}
.Wishlist-cart-container-1 {
  margin: 0;
  padding: 0;
}
.Wishlist_price-list-Tag {
  list-style: none;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
  padding-top: 16px;
}
.Wishlist-row {
  margin: 0;
  padding: 0;
}
.wishlistimagediv {
  display: flex;
  padding: 20px;
  padding-left: 0px;
  padding-right: 0px;
}
.Wishlist_categoryNameWishlist {
  list-style: none;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #9a9a9a;
  min-height: 20px;
}
.Wishlist_estoriginalpricewishlistlinetag {
  list-style: none;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: left;
  color: #868686;
}
.Wishlist_originalpriceline {
  text-decoration: line-through;
}
.wishlishHrline {
  margin: 0px;
  background-color: #f5f5f5;
}
.Wishlist-container-having-productdetails {
  display: flex;
  justify-content: space-between;
  text-align: center;
  -webkit-padding-end: 20px;
  -webkit-padding-start: 20px;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 3px;
  padding-top: 3px;
}
.wishlistdetailsdiv {
  padding-left: 20px;
}

.Wishlist-image-containerdiv {
  width: 70px !important;
  height: 84px;
  font-size: 10px;
}
.Wishlist-image-container {
  width: 70px;
  height: 84px;
  font-size: 12px;
}

.Wishlist-cart-container {
  width: 100%;
  height: auto;
}
.Wishlist-brand-name-description {
  list-style: none;
  font-size: 14px;
  font-weight: 450;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #515151;
}
.Wishlist_allwishComponents {
  overflow-y: auto;
  width: 100%;
  overflow-x: hidden;
}

.Wishlist-addTocart-removefromcart-btn-container {
  display: grid;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
}
.Wishlist-addTocart-removefromcart-btn-container div {
  align-items: center;
  padding: 5px;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
}
.WishListMainDivForWishlistSection {
  width: 74%;
  border-radius: 5px;
  background-color: white;
}
.Wishlist_addtocardLogo {
  position: relative;
  padding: 5px;
  height: 35px;
  width: 110px;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: center;
  border-radius: 2px;
  background-color: transparent;
  color: #fff;
  border: 1px solid #ea5e3d;
  cursor: pointer;
  background-color: #ea5e3d;
}
.Wishlist_addtocardLogodisable {
  position: relative;
  padding: 5px;
  height: 35px;
  width: 110px;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: center;
  background-color: transparent;
  border-radius: 2px;
  color: #fff;
  border: 1px solid #ea5e3d;
  cursor: not-allowed !important;
  background-color: #ea5e3d;
}
.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
  position: fixed;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0.1, 0.1, 0.1, 0.07) !important;
  -webkit-tap-highlight-color: transparent;
  z-index: -1;
}
.Wishlist_NoItemsWarning {
  font-size: 16px;
  text-align: center;
  padding: 20px;
  /* margin-top: 50px;
  margin-bottom: 90px; */
}
.wishlistEmptyline {
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: center;
  list-style: none;
  margin-bottom: 25px;
}
.wishlistHeadingElement {
  color: #000000;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 30px;
}

.Wishlist_additionallineforWishlistMobile {
  display: none;
}
.Wishlist_recentViewContainer {
  margin-left: 150px;
  margin-right: 150px;
}
.wishlist_recentview_container {
  margin-bottom: 10px;
  padding: 15px;
  background-color: white;
}
.wishlist_button_remove {
  background-color: #ea5e3d !important;
  width: 100px;
  height: 35px;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  margin-left: 20px;
  color: #ffffff !important;
  border: 1px solid #ea5e3d;
}
@media only screen and (min-width: 600px) and (max-width: 1650px) {
  .Wishlist_recentViewContainer {
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 0px;
  }
}
@media only screen and (min-width: 1110px) and (max-width: 1650px) {
  .Wishlist-cart-container {
    margin-top: 1%;
  }
  .Wishlist-cart-container-1 {
    width: 100%;
    height: auto;
    margin: auto;
  }
  .Wishlist-lower-border-line {
    margin: 0%;
    width: auto;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1110px) {
  .Wishlist_addtocardLogodisable {
    width: 100%;
    font-size: 12px;
  }
  .Wishlist_addtocardLogo {
    width: 100%;
    font-size: 12px;
  }
  .wishlistdetailsdiv {
    width: 70%;
    padding-left: 10px;
  }

  .wishlistimagediv {
    width: 80%;
  }
  .Wishlist-addTocart-removefromcart-btn-container {
    width: 20%;
  }
  .Wishlist-cart-container-1 {
    width: 100%;
    height: auto;
    margin: auto;
  }
  .Wishlist-cart-container {
    width: 100%;
    margin-top: 1%;
    height: auto;
  }
  .Wishlist-brand-name-description {
    text-wrap: wrap;
  }
  .Wishlist-description {
    font-size: 16px;
  }
  .Wishlist-lower-bound-hr {
    width: 70%;
    margin: auto;
  }
  .Wishlist-image-containerdiv {
    font-size: 12px;
  }
  .Wishlist-image-container {
    height: 86px;
  }
  .Wishlist-container-having-productdetails {
    padding: 1vh;
  }
}

@media only screen and (max-width: 600px) {
  #confirmRemoveFromWishlistModal {
    width: 45%;
  }
  .skeleton-loading-forWishlist {
    flex-direction: column;
  }
  .skeleton-Wishlistrow2 {
    justify-content: flex-end;
  }
  .skeleton-WishlistColumn1 {
    width: 100%;
  }
  .skeleton-WishlistColumn21 {
    position: relative;
    bottom: 78px;
    margin-left: 91%;
  }
  .skeleton-WishlistColumn22 {
    margin-top: 0px;
    margin-left: 79%;
  }
  .skeleton-hr-forWishlist {
    height: 1px;
    width: 100%;
  }
  .WishListMainDivForWishlistSection {
    border-radius: 0px;
  }
  .wishlist_button_remove {
    width: 45%;
  }
  .Wishlist_recentViewContainer {
    margin-left: 10px;
    margin-right: 10px;
    background-color: white;
  }
  .Wishlist-container-having-productdetails {
    flex-direction: column;
    text-align: center;
    padding-top: 10px;
  }
  .Wishlist-checkout-button-Container {
    width: 50%;
    display: flex;
    justify-content: center;
    height: 100%;
    padding: 1px 0;
  }
  .Wishlist_categoryNameWishlist {
    display: none;
  }
  #Wishlist-checkout-button-Container-AddToCart {
    border: 1px solid #e1e3e1;
    background-color: #ea5e3d;
    color: white;
    font-weight: 500;
    border-radius: 2px;
    width: 120px;
  }
  #Wishlist-checkout-button-Container-removeFormWishlist {
    display: none;
  }
  .Wishlist-addTocart-removefromcart-btn-container {
    display: flex;
    width: 100%;
    justify-content: end;
    height: 40px;
    margin-bottom: 10px;
  }

  .Wishlist_buyerMainWishlistPage {
    margin: 0px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* margin-top: 100px; */
  }

  .Wishlist_buyerMainDivForNavWishlistSection {
    display: inline;
    width: 100%;
  }
  .Wishlist_buyerMainDivForNavSection {
    display: none;
  }
  .WishListMainDivForWishlistSection {
    width: 100%;
    height: max-content;
    flex-direction: column;
    display: flex;
    min-height: 100px;
    align-items: center;
    justify-content: center;
  }

  .wishListRemoveItemMobileDiv {
    display: flex;
    justify-content: end;
    padding-left: 20px;
  }

  .Wishlist_additionallineforWishlistMobile {
    height: 1px !important;
    background-color: #f5f5f5 !important;
    display: block;
  }
  .Wishlist_price-list-Tag {
    font-weight: 500;
    padding-left: 0px;
  }
  .Wishlist-brand-name-description-div {
    font-size: 14px;
    font-weight: 600;
  }

  .Wishlist-image-containerdiv {
    height: 86px;
    top: 0;
  }
  .Wishlist-image-container {
    width: 70px !important;
    height: 86px;
    font-size: 10px;
  }
  .Wishlist_addtocardLogo {
    border: none !important;
    font-weight: 500;
    font-size: 12px;
  }

  .Wishlist_addtocardLogo:hover::after {
    opacity: 1;
  }
  .wishlistimagediv {
    justify-content: space-between;
  }
  .Wishlist_addtocardLogodisable {
    font-size: 12px;
  }
  .removeFromWishListMobile {
    display: flex;
    justify-content: flex-start;
    height: 30px;
    align-items: center;
  }
}
@media only screen and (max-width: 320px) {
  .wishlist-image-details {
    flex-direction: column;
    gap: 20px;
  }
  .wishlistdetailsdiv {
    padding-left: 0px;
  }
}
