.childCategoriesDropDown {
  position: absolute;
  z-index: 2;
  padding-top: 9px;
}
.childCategoriesList {
  border: 1px solid #e6e6e6;
  border-radius: 2px;
  width: max-content;
  background-color: #fff;
  display: flex;
  flex-direction: column;
}
.childCategoryName {
  width: max-content;
  padding: 5px 10px;
  width: 100%;
  cursor: pointer;
  color: #666;
  font-size: 14px;
  font-weight: 500;
  border-bottom: 1px solid #e6e6e6;
}
.childCategoryName:hover{
  color: #f97252;
  background-color: #e5e5e5;
}